import { State } from '@common/store/reducer';
import { generateNormalizer } from '@common/lib/pagination/store';
import { ProductDataInterface, ATTRIBUTES, SimplePayload } from '../product/types';
import { productModel } from '../product/model';
import { productSchema } from '../product/schema';

function selectBasketCookie(state: State) {
  return state.basket.cookie;
}

function selectBasketProducts(state: State) {
  const normalizer = generateNormalizer<ProductDataInterface>(productSchema);

  return normalizer.denormalizeList(Object.keys(state.basket.cookie), state.model);
}

function selectIsBasketLoaded(state: State) {
  return state.basket.isLoaded;
}

function selectIsBasketLoading(state: State) {
  return state.basket.isLoading;
}

function selectBasketTotalQuantity(state: State) {
  return Object.keys(state.basket.cookie).reduce((totalQuantity, productId) => {
    return totalQuantity + state.basket.cookie[productId].quantity;
  }, 0);
}

function selectBasketTotalPrice(state: State) {
  const products = selectBasketProducts(state);

  return products
    .map((_) => productModel(_))
    .reduce((totalPrice, product) => {
      return totalPrice + product.getPriceAttr(state.basket.cookie[product.getId()].quantity);
    }, 0);
}

function selectBasketShowTotalPrice(state: State) {
  const products = selectBasketProducts(state);

  return products.every((_) => productModel(_).showPrice());
}

export const basketSelectors = {
  selectBasketCookie,
  selectBasketProducts,
  selectIsBasketLoaded,
  selectIsBasketLoading,
  selectBasketTotalQuantity,
  selectBasketTotalPrice,
  selectBasketShowTotalPrice,
};
