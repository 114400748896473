import Cookies from 'universal-cookie';

import { AuthCookie } from './cookie.types';
import { decode, encode } from '@common/lib/helpers/strings';
import env from '../helpers/env';

const COOKIES = {
  AUTH: 'karimoff_auth',
  USER: 'karimoff_user',
  BASKET: 'karimoff_basket',
};

const COOKIE_MAX_AGE = 2147483647;

let cookies: Cookies;

export function initCookies() {
  cookies = new Cookies();
}

export function setCookies(newCookies: Cookies) {
  cookies = newCookies;
}

export function setAuthCookie(payload: AuthCookie) {
  cookies.set(COOKIES.AUTH, encode(payload), { path: '/', maxAge: env.getJwtMaxAge(), sameSite: 'lax' });
}

export function setUserCookie(payload: string) {
  cookies.set(COOKIES.USER, payload, { path: '/', maxAge: env.getJwtMaxAge(), sameSite: 'lax' });
}

export function setBasketCookie(payload: string) {
  cookies.set(COOKIES.BASKET, payload, { path: '/', maxAge: COOKIE_MAX_AGE, sameSite: 'lax' });
}

export function getBasket() {
  const basket = cookies.get(COOKIES.BASKET);

  return basket;
}

export function getAuthToken(): AuthCookie {
  const authHashData = cookies.get(COOKIES.AUTH);

  if (authHashData) {
    const auth = decode(authHashData);

    return auth;
  }

  return {
    id: '',
    token: '',
  };
}

export function getAuthUser() {
  const userHashData = cookies.get(COOKIES.USER);

  if (userHashData) {
    const useData = decode(userHashData);

    return useData;
  }
}

export function removeAuthCookies() {
  cookies.remove(COOKIES.AUTH);
  cookies.remove(COOKIES.USER);
}
