import * as React from 'react';

import { UserMenuWrapper, UserMenuTitle, UserMenuClose } from './UserMenu.styled';
import { ComponentProps } from './UserMenu.types';
import { Icon } from '@common/components';

export const UserMenu: React.FC<ComponentProps> = ({ handleClose }) => {
  return (
    <UserMenuWrapper>
      <UserMenuTitle>Корзина</UserMenuTitle>
      <UserMenuClose onClick={handleClose}>
        <Icon type={'close'} />
      </UserMenuClose>
    </UserMenuWrapper>
  );
};
