import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  CallbackWrapper,
  CallbackContent,
  CallbackContentTitle,
  CallbackContentDescription,
  CallbackForm,
  CallbackFormField,
  CallbackFormButton,
} from './Callback.styled';
import { CallbackFormProps } from './Callback.types';

import { FormInputPhone } from '@common/components/FormInput/FormInput';
import notificationHelper from '@common/lib/helpers/notification';
import controllers from '@common/controllers';

export const Callback: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Formik<CallbackFormProps>
      initialValues={{ phoneNumber: '' }}
      validationSchema={Yup.object().shape({
        phoneNumber: Yup.string(),
      })}
      onSubmit={async (values, { resetForm }) => {
        await controllers.callback.create(values);

        notificationHelper.pushNotification(t('callback:notification'));

        resetForm();
      }}
    >
      {({ handleSubmit }) => (
        <CallbackWrapper>
          <CallbackContent>
            <CallbackContentTitle>{t('callback:title')}</CallbackContentTitle>
            <CallbackContentDescription>{t('callback:description')}</CallbackContentDescription>
          </CallbackContent>
          <CallbackForm>
            <CallbackFormField>
              <Field component={FormInputPhone} name='phoneNumber' placeholder={t('callback:inputPhone')} />
              <CallbackFormButton onClick={() => handleSubmit()}>{t('callback:cta')}</CallbackFormButton>
            </CallbackFormField>
          </CallbackForm>
        </CallbackWrapper>
      )}
    </Formik>
  );
};
