import * as React from 'react';
import styled, { media } from '@common/theme/styled-components';
const InputMask = require('react-input-mask');

export const CallbackWrapper = styled.section`
  padding: 30px 0;

  ${media.desktop`
    display: flex;
    align-items: center;
  `}
`;

export const CallbackContent = styled.div`
  ${media.desktop`
    flex: 1 1;
  `}
`;

export const CallbackContentTitle = styled.div`
  line-height: 24px;

  color: ${(props) => props.theme.whiteColor};
  font-size: 24px;
`;

export const CallbackContentDescription = styled.div`
  margin-top: 15px;

  color: ${(props) => props.theme.whiteColorHover};
  font-size: 12px;

  ${media.desktop`
    margin-top: 5px;
  `}
`;

export const CallbackForm = styled.div`
  margin-top: 15px;

  ${media.desktop`
    flex: 1 1;

    margin-top: 0;
  `}
`;

export const CallbackFormField = styled.div`
  display: flex;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const CallbackFormButton = styled.button`
  line-height: 40px;
  padding: 0 15px;
  border: 0;
  border-radius: 0 ${(props) => props.theme.borderRadiuses.small} ${(props) => props.theme.borderRadiuses.small} 0;

  background: ${(props) => props.theme.brandColor};

  color: ${(props) => props.theme.brandAltColor};
  text-transform: uppercase;

  transition: ${(props) => props.theme.defaultTransition};

  ${media.desktop`
    &:hover {
      color: ${(props) => props.theme.whiteColor};

      cursor: pointer;
    }
  `}
`;
