import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { ComponentProps } from './Loader.types';
import { LoaderSpin, LoaderError } from './Loader.styled';
import requestHelper from '@common/lib/request/request.helpers';

const LoaderComponent: React.FC<ComponentProps> = ({
  isLoading,
  isLoaded,
  error,
  errorNetwork,
  render,
  renderError,
}) => {
  const history = useHistory();

  const isRedirect = React.useMemo(() => {
    return requestHelper.isForbidden(errorNetwork);
  }, [errorNetwork]);

  React.useEffect(() => {
    if (isRedirect) {
      history.push('/not-found');
    }
  }, [isRedirect]);

  const Content = !isRedirect && isLoaded && render();
  const Error = error && <LoaderError>{renderError ? renderError() : error}</LoaderError>;

  return isLoading ? (
    <LoaderSpin />
  ) : (
    <React.Fragment>
      {Content}
      {Error}
    </React.Fragment>
  );
};

export default LoaderComponent;
