import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getHours } from 'date-fns/fp';

import {
  Checkout,
  CheckoutBox,
  CheckoutBoxTitle,
  CheckoutBoxBody,
  CheckoutHelp,
  CheckoutProducts,
  CheckoutProductsTable,
  CheckoutProductsTBody,
  CheckoutProductsTHead,
  CheckoutProductsHeaderCell,
  CheckoutProductsRow,
  CheckoutProductsCell,
  CheckoutTotalBox,
  CheckoutActions,
} from './Checkout.styled';
import { checkoutSeo } from './Checkout.seo';
import {
  Page,
  FormInput,
  FormInputPhone,
  FormField,
  FormDate,
  FormTextArea,
  FormRadioGroup,
  FormTime,
  Loader,
  Button,
} from '@common/components';
import TotalBox from './TotalBox/TotalBox';
import { basketSelectors } from '@common/store/basket/selectors';
import { basketActions } from '@common/store/basket/actions';
import { basketSagas } from '@common/store/basket/sagas';
import { productModel } from '@common/store/product/model';
import { CheckoutFormProps } from './Checkout.types';
import { SHIPPING_TYPES, PAYMENT_TYPES } from '@common/store/setting/types';
import { ButtonTypes } from '@common/components/Button/Button.types';
import formHelper from '@common/lib/helpers/form';
import validator from '@common/lib/validator/validator';
import controllers from '@common/controllers';
import notificationHelper, { NOTIFICATION_TYPES } from '@common/lib/helpers/notification';
import config from '@common/config';
import dateHelper from '@common/lib/helpers/date';

const CheckoutComponent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const basketProducts = useSelector(basketSelectors.selectBasketProducts);
  const isBasketLoaded = useSelector(basketSelectors.selectIsBasketLoaded);
  const isBasketLoading = useSelector(basketSelectors.selectIsBasketLoading);
  const basketCookie = useSelector(basketSelectors.selectBasketCookie);

  const isShippingTodayAvailable = React.useMemo(() => {
    const currentHour = getHours(new Date());

    return (
      parseFloat(config.settings.shippingCourierFromH) - parseFloat(config.settings.shippingCourierToH) > currentHour
    );
  }, []);

  const formProducts = React.useMemo(() => {
    return basketProducts
      .map((_) => productModel(_))
      .map((basketProduct) => {
        return {
          id: basketProduct.getId(),
          quantity: basketCookie[basketProduct.getId()].quantity,
        };
      });
  }, [basketProducts, basketCookie]);

  React.useEffect(() => {
    if (!isBasketLoaded) {
      dispatch(basketActions.loadBasketRequest());
    }
  }, []);

  return (
    <Page
      title={t('checkout:pageTitle')}
      seo={checkoutSeo(t)}
      breadcrumbs={[
        {
          link: '/checkout',
          title: t('checkout:pageTitle'),
        },
      ]}
    >
      <Formik<CheckoutFormProps>
        initialValues={{
          clientFirstName: '',
          clientPhoneNumber: '',
          clientEmail: '',
          clientAddress: '',
          shippingDay: null,
          shippingTime: {
            from: null,
            to: null,
          },
          comment: '',
          details: '',
          shippingType: SHIPPING_TYPES.COURIER,
          paymentType: PAYMENT_TYPES.CASH,
        }}
        validationSchema={() => {
          return validator.object().shape({
            clientPhoneNumber: validator.string().required(),
            clientEmail: validator.string().email(),
          });
        }}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={async (values, { resetForm }) => {
          const parsedValues = {
            ...values,
            ...formHelper.parseCheckboxValue(values.details),
            products: formProducts,
            shippingFrom: dateHelper.datePlusTime(values.shippingDay, values.shippingTime.from),
            shippingTo: dateHelper.datePlusTime(values.shippingDay, values.shippingTime.to),
          };

          delete parsedValues.details;
          delete parsedValues.shippingDay;
          delete parsedValues.shippingTime;

          if (values.shippingType === SHIPPING_TYPES.SELF) {
            delete parsedValues.clientAddress;
            delete parsedValues.shippingFrom;
            delete parsedValues.shippingTo;
          }

          try {
            await controllers.order.make(parsedValues);
          } catch (e) {
            notificationHelper.pushNotification('Что-то пошло не так, попробуйте еще раз.', NOTIFICATION_TYPES.ERROR);
            return;
          }

          notificationHelper.pushNotification('Спасибо за заказ! Мы скоро Вам перезвоним.');

          resetForm();

          dispatch(basketActions.clearBasket());

          history.push('/');
        }}
      >
        {({ handleSubmit, values }) => (
          <Checkout>
            <CheckoutBox>
              <CheckoutBoxTitle>{'О покупателе'}</CheckoutBoxTitle>
              <CheckoutBoxBody>
                <FormField label={'Имя'}>
                  <Field component={FormInput} name='clientFirstName' placeholder={'Введите имя'} />
                </FormField>
                <FormField label={'Номер телефона'} isReqired={true}>
                  <Field component={FormInputPhone} name='clientPhoneNumber' placeholder={'Введите номер телефона*'} />
                </FormField>
                <FormField label={'Email'}>
                  <Field component={FormInput} name='clientEmail' placeholder={'Введите email'} />
                </FormField>
                <FormField label={'Комментарий'}>
                  <Field component={FormTextArea} name='comment' placeholder={'Ваши пожелания и дополнения к заказу'} />
                </FormField>
              </CheckoutBoxBody>
            </CheckoutBox>
            <CheckoutBox>
              <CheckoutBoxTitle>{'Способ доставки'}</CheckoutBoxTitle>
              <CheckoutBoxBody>
                <Field
                  component={FormRadioGroup}
                  name='shippingType'
                  buttons={[
                    {
                      label: 'Доставка курьером',
                      name: SHIPPING_TYPES.COURIER,
                      highlight: 'бесплатно',
                      description: 'Доставка по Лепелю в 2-х часовой интервал',
                    },
                    {
                      label: 'Самовывоз',
                      name: SHIPPING_TYPES.SELF,
                      description: `Адрес самовывоза: ${config.settings.shopAddress}`,
                    },
                  ]}
                />
              </CheckoutBoxBody>
              <React.Fragment>
                <CheckoutBoxTitle>{'О доставке'}</CheckoutBoxTitle>
                <CheckoutBoxBody>
                  {values.shippingType === SHIPPING_TYPES.COURIER && (
                    <FormField label={'Адрес'}>
                      <Field component={FormInput} name='clientAddress' placeholder={'Введите адрес'} />
                    </FormField>
                  )}
                  <FormField label={'День'}>
                    <Field
                      component={FormDate}
                      name='shippingDay'
                      placeholder={'Выберите день'}
                      isOnlyPassed={true}
                      isToday={isShippingTodayAvailable}
                    />
                  </FormField>
                  <FormField label={'Время'}>
                    <Field
                      component={FormTime}
                      name='shippingTime'
                      date={values.shippingDay}
                      minH={parseFloat(config.settings.shippingCourierFromH)}
                      maxH={parseFloat(config.settings.shippingCourierToH)}
                      offsetH={parseFloat(
                        values.shippingType === SHIPPING_TYPES.COURIER
                          ? config.settings.shippingCourierOffestH
                          : config.settings.shippingSelfOffestH
                      )}
                    />
                  </FormField>
                </CheckoutBoxBody>
              </React.Fragment>
            </CheckoutBox>
            <CheckoutBox>
              <CheckoutBoxTitle>{'Способ оплаты'}</CheckoutBoxTitle>
              <CheckoutBoxBody>
                <Field
                  component={FormRadioGroup}
                  name='paymentType'
                  buttons={[
                    {
                      label: 'Расчет наличными при получении заказа',
                      name: PAYMENT_TYPES.CASH,
                      description: 'Оплата производится наличными деньгами, в момент получения заказа.',
                    },
                    // {
                    //   label: 'Онлайн оплата',
                    //   name: PAYMENT_TYPES.ONLINE,
                    //   description: 'После оформления заказа наш менеджер вышлет Вам ссылку на онлайн оплату.',
                    // },
                  ]}
                />
              </CheckoutBoxBody>
            </CheckoutBox>
            <CheckoutBox>
              <CheckoutBoxTitle>{'Заказ'}</CheckoutBoxTitle>
              <CheckoutBoxBody>
                <Loader
                  isLoaded={isBasketLoaded}
                  isLoading={isBasketLoading}
                  error={''}
                  render={() => (
                    <React.Fragment>
                      <CheckoutProducts>
                        <CheckoutProductsTable>
                          <CheckoutProductsTHead>
                            <CheckoutProductsRow>
                              <CheckoutProductsHeaderCell>{'Наименование'}</CheckoutProductsHeaderCell>
                              <CheckoutProductsHeaderCell>{'Цена'}</CheckoutProductsHeaderCell>
                              <CheckoutProductsHeaderCell>{'Кол-во'}</CheckoutProductsHeaderCell>
                              <CheckoutProductsHeaderCell>{'Стоимость'}</CheckoutProductsHeaderCell>
                            </CheckoutProductsRow>
                          </CheckoutProductsTHead>
                          <CheckoutProductsTBody>
                            {basketProducts
                              .map((_) => productModel(_))
                              .map((basketProduct) => (
                                <CheckoutProductsRow key={basketProduct.getId()}>
                                  <CheckoutProductsCell>{basketProduct.getTitle()}</CheckoutProductsCell>
                                  <CheckoutProductsCell>{basketProduct.getPrice()}</CheckoutProductsCell>
                                  <CheckoutProductsCell>
                                    {basketCookie[basketProduct.getId()].quantity}
                                  </CheckoutProductsCell>
                                  <CheckoutProductsCell>
                                    {basketProduct.getPrice(basketCookie[basketProduct.getId()].quantity)}
                                  </CheckoutProductsCell>
                                </CheckoutProductsRow>
                              ))}
                          </CheckoutProductsTBody>
                        </CheckoutProductsTable>
                      </CheckoutProducts>
                      <CheckoutTotalBox>
                        <TotalBox />
                      </CheckoutTotalBox>
                      <CheckoutActions>
                        <Button
                          icon={{ type: 'arrowLong', variant: 'left' }}
                          buttonType={ButtonTypes.BORDERLESS}
                          to='/basket'
                        >
                          {'Вернуться в корзину'}
                        </Button>
                        <Button
                          actionIcon={{ type: 'arrowLong', variant: 'right' }}
                          buttonType={ButtonTypes.PRIMARY}
                          onClick={() => handleSubmit()}
                        >
                          {'Оформить заказ'}
                        </Button>
                      </CheckoutActions>
                    </React.Fragment>
                  )}
                />
              </CheckoutBoxBody>
            </CheckoutBox>
          </Checkout>
        )}
      </Formik>
    </Page>
  );
};

const preload = () => {
  return [basketSagas.loadBasket];
};

export default {
  component: CheckoutComponent,
  preload,
};
