import * as React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { TotalBox, Total, TotalLabel, TotalValue, GrandTotal } from './TotalBox.styled';
import { CheckoutFormProps } from '../Checkout.types';
import stringHelper from '@common/lib/helpers/strings';
import { basketSelectors } from '@common/store/basket/selectors';
import { SHIPPING_TYPES } from '@common/store/setting/types';
import priceHelper from '@common/lib/helpers/price';

const TotalBoxComponent: React.FC = () => {
  const { values } = useFormikContext<CheckoutFormProps>();

  const basketTotalPrice = useSelector(basketSelectors.selectBasketTotalPrice);
  const basketShowTotalPrice = useSelector(basketSelectors.selectBasketShowTotalPrice);

  const discountTotal = React.useMemo(() => {
    if (values.shippingType === SHIPPING_TYPES.SELF) {
      return priceHelper.getDeliverySelfDiscount(basketTotalPrice);
    }

    return 0;
  }, [values.shippingType, basketTotalPrice]);

  const deliveryTotal = React.useMemo(() => {
    if (values.shippingType === SHIPPING_TYPES.COURIER) {
      return priceHelper.getDeliveryCourier();
    }

    return 0;
  }, [values.shippingType, basketTotalPrice]);

  const grandTotal = React.useMemo(() => {
    return basketTotalPrice - discountTotal + deliveryTotal;
  }, [basketTotalPrice, discountTotal, deliveryTotal]);

  return (
    <TotalBox>
      <Total>
        <TotalLabel>{'Товаров на сумму'}</TotalLabel>
        <TotalValue>{basketShowTotalPrice ? stringHelper.toPrice(basketTotalPrice) : 'Сумма по запросу'}</TotalValue>
      </Total>
      <Total>
        <TotalLabel>{'Скидка'}</TotalLabel>
        <TotalValue>{stringHelper.toPrice(discountTotal)}</TotalValue>
      </Total>
      <Total>
        <TotalLabel>{'Доставка'}</TotalLabel>
        <TotalValue>{stringHelper.toPrice(deliveryTotal)}</TotalValue>
      </Total>
      <GrandTotal>
        <TotalLabel>{'Итого'}</TotalLabel>
        <TotalValue>{basketShowTotalPrice ? stringHelper.toPrice(grandTotal) : 'Сумма по запросу'}</TotalValue>
      </GrandTotal>
    </TotalBox>
  );
};

export default TotalBoxComponent;
