import styled, { media, icon } from '@common/theme/styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1024;

  padding: 15px;

  display: flex;

  background: rgba(0, 0, 0, 0.75);
`;

export const Modal = styled.div`
  position: relative;

  width: 100%;

  margin: auto;
  padding: ${(props) => props.theme.smallPadding};
  border-radius: 15px;

  background: ${(props) => props.theme.whiteColor};

  ${media.tablet`
    width: 450px;
  `}
`;

export const ModalTitle = styled.h3`
  text-align: center;
`;

export const ModalBody = styled.div`
  max-height: 70vh;
  overflow-y: auto;

  padding-top: ${(props) => props.theme.smallPadding};
`;

export const ModalClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(50%, -50%, 0);

  padding: 8px;
  border: 0;
  border-radius: 100%;

  background: ${(props) => props.theme.brandAltColor};
  transition: ${(props) => props.theme.defaultTransition};

  ${(props) => icon({ color: props.theme.whiteColor, size: props.theme.xsmallFontSize })}

  &:hover {
    background: ${(props) => props.theme.brandRedColor};
  }
`;
