import * as React from 'react';

import { Icon } from '../../components';

import {
  Pagination,
  PaginationLabel,
  PaginationControlsList,
  PaginationControl,
  PaginationControlText,
} from './Pagination.styled';
import { ComponentProps } from './Pagination.types';
import { generatePagination } from '../../lib/pagination/structures';

const PaginationComponent: React.FC<ComponentProps> = ({ pagination, handleChangeSearchParams }) => {
  const actualPagination = React.useMemo(() => generatePagination(pagination), [pagination]);

  const handleSetPage = React.useCallback(
    (page: number) => {
      handleChangeSearchParams({ page: page.toString() });
    },
    [handleChangeSearchParams]
  );

  return (
    !!actualPagination.length && (
      <Pagination>
        <PaginationLabel>{`Показано ${pagination.from} - ${pagination.to} из ${pagination.total} шт.`}</PaginationLabel>
        <PaginationControlsList>
          {pagination.page > 1 && (
            <PaginationControl onClick={() => handleSetPage(pagination.page - 1)}>
              <Icon type='arrow' variant='left' />
              <PaginationControlText>{'Назад'}</PaginationControlText>
            </PaginationControl>
          )}
          {actualPagination.map(({ number, isActive }) => (
            <PaginationControl key={number} onClick={() => handleSetPage(number)} isActive={isActive}>
              {number}
            </PaginationControl>
          ))}
          {pagination.page < pagination.lastPage && (
            <PaginationControl onClick={() => handleSetPage(pagination.page + 1)}>
              <PaginationControlText>{'Вперед'}</PaginationControlText>
              <Icon type='arrow' variant='right' />
            </PaginationControl>
          )}
        </PaginationControlsList>
      </Pagination>
    )
  );
};

export default PaginationComponent;
