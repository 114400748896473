import { Link } from 'react-router-dom';

import styled, { css, media, icon } from '@common/theme/styled-components';
import { ButtonTheme } from './Button.types';

const button = css`
  display: flex;
  align-items: center;

  border: 1px solid;
  border-radius: 30px;

  font-size: ${(props) => props.theme.xsmallFontSize};
  font-weight: bold;
  text-transform: uppercase;
  transition: ${(props) => props.theme.defaultTransition};

  ${media.tablet`
    font-size: ${(props) => props.theme.smallFontSize};
  `}

  &:hover {
    cursor: pointer;
  }

  svg {
    &:first-child {
      margin-right: ${(props) => props.theme.smallPadding};
    }

    &:last-child {
      margin-left: ${(props) => props.theme.smallPadding};
    }
  }
`;

export const Button = styled('button')<{ buttonTheme: ButtonTheme }>`
  ${button}

  padding: ${(props) => props.buttonTheme.verticalPadding} ${(props) => props.buttonTheme.horizontalPadding};
  border-color: ${(props) => props.buttonTheme.borderColor};

  background: ${(props) => props.buttonTheme.background};
  color: ${(props) => props.buttonTheme.color};

  ${(props) => icon({ color: props.buttonTheme.iconColor })}

  &:hover {
    border-color: ${(props) => props.buttonTheme.borderColorHover};
    background: ${(props) => props.buttonTheme.backgroundHover};
    color: ${(props) => props.buttonTheme.colorHover};
  }
`;

export const ButtonLink = styled(Link)<{ buttonTheme: ButtonTheme }>`
  ${button}

  padding: ${(props) => props.buttonTheme.verticalPadding} ${(props) => props.buttonTheme.horizontalPadding};
  border-color: ${(props) => props.buttonTheme.borderColor};

  background: ${(props) => props.buttonTheme.background};
  color: ${(props) => props.buttonTheme.color};

  text-decoration: none;

  ${(props) => icon({ color: props.buttonTheme.iconColor })}

  &:hover {
    border-color: ${(props) => props.buttonTheme.borderColorHover};
    background: ${(props) => props.buttonTheme.backgroundHover};
    color: ${(props) => props.buttonTheme.colorHover};
  }
`;

export const ButtonText = styled.span`
  line-height: 1.15;
`;
