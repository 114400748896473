import { Link } from 'react-router-dom';

import styled, { media } from '@common/theme/styled-components';

export const NavigationWrapper = styled.nav``;

export const NavigationItem = styled(Link)`
  ${media.desktop`
    display: inline-block;

    padding: 0 15px;
    border-left: 1px solid ${(props) => props.theme.brandBgColor};
    line-height: ${(props) => props.theme.desktopMenu.height};

    color: ${(props) => props.theme.whiteColor};
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;

    transition: ${(props) => props.theme.defaultTransition};

    &:hover {
      background: ${(props) => props.theme.brandAltHoverColor};
    }

    &:first-child {
      border-left: 0;
    }
  `}
`;
