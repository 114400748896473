import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderLink } from './HeaderLink/HeaderLink';
import { Languages } from './Languages/Languages';
import SearchBox from './SearchBox/SearchBox';

import { Icon, Container } from '@common/components';
import stringHelper, { wrapLogo } from '@common/lib/helpers/strings';

import {
  HeaderWrapper,
  HeaderTop,
  HeaderTopInner,
  HeaderTopLeft,
  HeaderTopMiddle,
  HeaderTopLogo,
  HeaderTopRight,
  HeaderBody,
  HeaderBodyInner,
  HeaderLogo,
  HeaderSearchBox,
  HeaderContacts,
  HeaderMenuToggle,
  ContactPhone,
} from './Header.styled';
import { uiActions } from '@common/store/ui/actions';
import { basketSelectors } from '@common/store/basket/selectors';
import config from '@common/config';

export const Header: React.FC = () => {
  const dispatch = useDispatch();

  const basketTotalQuantity = useSelector(basketSelectors.selectBasketTotalQuantity);

  const handleClickSidebar = React.useCallback(() => {
    dispatch(uiActions.toggleSidebar());
  }, [uiActions.toggleSidebar]);

  return (
    <HeaderWrapper>
      <HeaderTop>
        <Container>
          <HeaderTopInner>
            <HeaderTopLeft>
              <HeaderMenuToggle onClick={handleClickSidebar}>
                <Icon type={'menu'} />
              </HeaderMenuToggle>
              <Languages />
            </HeaderTopLeft>
            <HeaderTopMiddle>
              <HeaderTopLogo to={'/'} dangerouslySetInnerHTML={{ __html: wrapLogo(config.settings.name) }} />
            </HeaderTopMiddle>
            <HeaderTopRight>
              <HeaderLink link={'/basket'} label={`Корзина`} icon={'basket'} quantity={basketTotalQuantity} />
            </HeaderTopRight>
          </HeaderTopInner>
        </Container>
      </HeaderTop>
      <HeaderBody>
        <Container>
          <HeaderBodyInner>
            <HeaderLogo to={'/'}>
              <Icon type={'logo'} />
            </HeaderLogo>
            <HeaderSearchBox>
              <SearchBox />
            </HeaderSearchBox>
            <HeaderContacts>
              <ContactPhone href={`tel:${config.settings.phone}`} title={stringHelper.toPhone(config.settings.phone)}>
                <Icon type={'phone'} variant={'thin'} />
                {stringHelper.toPhone(config.settings.phone)}
              </ContactPhone>
            </HeaderContacts>
          </HeaderBodyInner>
        </Container>
      </HeaderBody>
    </HeaderWrapper>
  );
};
