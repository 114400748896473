import axios, { AxiosResponse, AxiosError } from 'axios';

import { RequestType, RequestOptions } from './request.types';
import { setAuthCookie, getAuthToken, setUserCookie } from '../cookie/cookie';
import config from '../../config';

export function request(rawOptions: RequestOptions, requestType: RequestType = RequestType.REQUEST_TYPE_DEFAULT) {
  const options = processOptions(rawOptions);

  const onSuccess = (response: AxiosResponse) => {
    processAuthorization(response);

    return response.data;
  };

  const onError = (error: AxiosError) => {
    throw error.response || new Error(error.message);
  };

  const client = axios.create({
    baseURL: makeBaseUrl(requestType),
  });

  return client({
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': options.multipart ? 'multipart/form-data' : 'application/json',
      ...options.headers,
    },
  })
    .then(onSuccess)
    .catch(onError);
}

export function adminRequest(rawOptions: RequestOptions) {
  return request(
    {
      ...rawOptions,
      headers: {
        ...rawOptions.headers,
        Authorization: getAuthToken().token,
      },
    },
    RequestType.REQUEST_TYPE_ADMIN
  );
}

function makeBaseUrl(requestType: RequestType) {
  switch (requestType) {
    default:
      return config.api.url;
  }
}

function processAuthorization(response: AxiosResponse) {
  const token = response.headers.authorization;
  const authUser = response.headers['authorized-user'];

  if (token) {
    setAuthCookie({ id: response.data.id, token });
    setUserCookie(authUser);
  }
}

function processOptions(rawOptions: RequestOptions) {
  let data = rawOptions.data;

  if (rawOptions.multipart) {
    data = new FormData();
    Object.keys(rawOptions.data).forEach((dataItemKey) => {
      const dataItem = rawOptions.data[dataItemKey];

      if (Array.isArray(dataItem)) {
        dataItem.forEach((dataItemChild) => {
          if (dataItemChild.id) {
            data.append(dataItemKey, dataItemChild.id);
          } else {
            data.append(dataItemKey, dataItemChild);
          }
        });
      } else if (dataItem.id) {
        data.append(dataItemKey, dataItem.id);
      } else {
        data.append(dataItemKey, dataItem);
      }
    });
  }

  return {
    ...rawOptions,
    data,
  };
}
