/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

export const responses = {
  passwordConfirmation: () => `Пароли должны совпадать.`,
};

yup.addMethod(yup.string, 'passwordConfirmation', () =>
  yup.string().oneOf([validator.ref('password')], responses.passwordConfirmation())
);

yup.setLocale({
  mixed: {
    required: 'Пожалуйста, заполните данное поле.',
  },
  string: {
    email: 'Введите правильный еmail.',
    min: 'The field length must be more then ${min}.',
    max: 'The field length must be more then ${max}.',
    url: 'The field value must be a valid URL string.',
  },
  number: {
    integer: 'The field value must be an integer.',
    positive: 'The field value must be a positive number.',
  },
});

const validator = yup;

export default validator;
