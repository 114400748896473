import * as React from 'react';
import { FieldProps } from 'formik';
import Select from 'react-select';

import { formSelectStyles } from './FormSelect.styled';
import { ComponentProps, Option, OptionWithQuantity } from './FormSelect.types';
import MultiValueLabel from './MultiValueLabel/MultiValueLabel';

function selectOption(options: Option[], value: string) {
  return options.find((option) => option.value === value);
}

const FormSelectComponent: React.FC<ComponentProps & FieldProps> = ({
  options,
  isMulti,
  isSearchable,
  isWithQuantity,
  placeholder = 'Поиск...',
  field,
  form,
}) => {
  const sortedOptions = React.useMemo(() => {
    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [options]);

  const handleChange = React.useCallback(
    (selected: Option | Option[] | null) => {
      if (selected) {
        if (isWithQuantity) {
          const parsedValue = Array.isArray(selected)
            ? selected.map((_) => ({
                id: _.value,
                quantity: _.quantity,
              }))
            : {
                id: selected.value,
                quantity: selected.quantity,
              };
          form.setFieldValue(field.name, parsedValue);
        } else {
          const parsedValue = Array.isArray(selected) ? selected.map((_) => _.value) : selected.value;
          form.setFieldValue(field.name, parsedValue);
        }
      } else {
        form.setFieldValue(field.name, null);
      }
    },
    [isWithQuantity, form, field]
  );

  const getValue = React.useCallback(() => {
    if (isWithQuantity) {
      const selected: { id: string; quantity: number } | { id: string; quantity: number }[] | null = field.value;

      if (selected) {
        return Array.isArray(selected)
          ? selected.map((_) => ({
              ...selectOption(sortedOptions, _.id),
              quantity: _.quantity,
            }))
          : { ...selectOption(sortedOptions, selected.id), quantity: selected.quantity };
      }
    } else {
      const selected: string | string[] | null = field.value;

      if (selected) {
        return Array.isArray(selected)
          ? selected.map((_) => selectOption(sortedOptions, _))
          : selectOption(sortedOptions, selected);
      }
    }

    return null;
  }, [isWithQuantity, field, sortedOptions]);

  const getOptions = React.useCallback(() => {
    return sortedOptions.reduce((filteredOptions, option) => {
      if (parseInt(option.value) === 1 && option.label === '') {
        if (isMulti) {
          return filteredOptions;
        }

        return [
          ...filteredOptions,
          {
            value: option.value,
            label: 'Не выбрано',
          },
        ];
      }

      return [...filteredOptions, option];
    }, []);
  }, [sortedOptions]);

  const handleChangeQuantity = React.useCallback(
    (id: string, quantity: number) => {
      const newFieldValue = field.value.map((value: OptionWithQuantity) => {
        return value.id === id ? { ...value, quantity } : value;
      });
      form.setFieldValue(field.name, newFieldValue);
    },
    [field.value, field.name, form.setFieldValue]
  );

  return (
    <Select
      styles={formSelectStyles}
      options={getOptions()}
      components={{
        MultiValueLabel: MultiValueLabel.component,
      }}
      isSearchable={isSearchable}
      isClearable={isSearchable}
      isMulti={isMulti}
      placeholder={placeholder}
      noOptionsMessage={() => 'Ничего не найдено'}
      value={getValue()}
      onChange={handleChange}
      onChangeQuantity={handleChangeQuantity}
    />
  );
};

export default FormSelectComponent;
