import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { NotFoundWrapper, NotFoundTitle } from './NotFound.styled';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NotFoundWrapper>
      <Helmet title={t('general:notFoundPageTitle')} />
      <NotFoundTitle>{t('general:notFoundPageTitle')}</NotFoundTitle>
    </NotFoundWrapper>
  );
};

export default {
  component: NotFound,
};
