import * as React from 'react';
import { Breadcrumbs as BreadcrumbsContainer } from 'react-breadcrumbs-dynamic';

import { BreadcrumbsWrapper, BreadcrumbsLink, BreadcrumbsFinalItem, BreadcrumbsSeparator } from './Breadcrumbs.styled';
import { Container, Icon } from '@common/components';

const BreadcrumbsContainerComponent: React.FC = ({ children }) => {
  return (
    <BreadcrumbsWrapper>
      <Container>{children}</Container>
    </BreadcrumbsWrapper>
  );
};

export const Breadcrumbs: React.FC = () => {
  return (
    <BreadcrumbsContainer
      separator={
        <BreadcrumbsSeparator>
          <Icon type='arrow' variant='right' />
        </BreadcrumbsSeparator>
      }
      container={BreadcrumbsContainerComponent}
      item={BreadcrumbsLink}
      finalItem={BreadcrumbsFinalItem}
      // @ts-ignore
      hideIfEmpty={true}
    />
  );
};
