import config from '../../config';

export const getDeliverySelfDiscount = (price: number) => {
  return (price / 100) * parseFloat(config.settings.deliverySelfDiscount);
};

export const getDeliveryCourier = () => {
  return parseFloat(config.settings.deliveryCourier);
};

const priceHelper = {
  getDeliverySelfDiscount,
  getDeliveryCourier,
};

export default priceHelper;
