import styled, { media } from '@common/theme/styled-components';

export const Product = styled.section``;

export const ProductInfo = styled.section`
  ${media.desktop`
    display: flex;
  `}
`;

export const ProductGalleria = styled.div`
  ${media.desktop`
    overflow: hidden;

    flex: 3 1;
  `}
`;

export const ProductProfile = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    flex: 2 1;

    margin-top: 0;
    margin-left: ${(props) => props.theme.smallPadding};
  `}
`;
