import styled, { icon, media } from '@common/theme/styled-components';

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.smallPadding};

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const InfoGroup = styled.div``;

export const InfoTitle = styled.h2`
  text-align: center;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${(props) => props.theme.smallPadding};

  &:first-child {
    margin-top: 0;
  }
`;

export const InfoItemIcon = styled.div`
  width: 25px;
  ${(props) => icon({ size: props.theme.largeFontSize })};
`;

export const InfoItemValue = styled.div`
  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.mediumFontSize};
`;

export const InfoItemLabel = styled.div``;

export const InfoItemLink = styled.a`
  margin-left: ${(props) => props.theme.xsmallPadding};

  color: ${(props) => props.theme.brandAltColor};
  font-size: ${(props) => props.theme.mediumFontSize};
`;
