import styled from '@common/theme/styled-components';

export const FormCheckbox = styled.div``;

export const FormCheckboxInput = styled.input`
  opacity: 0;
`;

export const FormCheckboxLabel = styled.label<{ isChecked: boolean }>`
  display: block;

  position: relative;

  padding-left: 27.5px;

  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    left: 0;
    top: 0;

    width: 18px;
    height: 18px;
    background: ${(props) => props.theme.whiteColor};
    border: 1px solid ${(props) => (props.isChecked ? props.theme.brandAltColor : props.theme.brandBgGrayColor)};
  }

  &:hover {
    &::before {
      border-color: ${(props) => props.theme.brandAltColor};
    }
  }

  &::after {
    display: none;

    content: '';

    position: absolute;
    left: 3px;
    top: 3px;
    z-index: 1;

    width: 14px;
    height: 14px;

    background: ${(props) => props.theme.brandAltColor};

    ${(props) =>
      props.isChecked &&
      `
      display: block;
    `}
  }
`;

export const FormCheckboxCount = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  font-weight: bold;
`;
