import * as React from 'react';

import { DesktopMenuWrapper, DesktopMenuInner } from './DesktopMenu.styled';

import { Container } from '@common/components';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import { Navigation } from './Navigation/Navigation';

export const DesktopMenu: React.FC = () => (
  <DesktopMenuWrapper>
    <Container>
      <DesktopMenuInner>
        <CategoryMenu />
        <Navigation />
      </DesktopMenuInner>
    </Container>
  </DesktopMenuWrapper>
);
