import * as React from 'react';
import { FieldProps } from 'formik';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { subDays } from 'date-fns/fp';
import ru from 'date-fns/locale/ru';

import { FormDateInput } from './FormDate.styled';
import { FormDateProps } from './FormDate.types';

registerLocale('ru', ru);
setDefaultLocale('ru');

const FormDateComponent: React.FC<FieldProps & FormDateProps> = ({
  field,
  form,
  placeholder,
  isOnlyPassed = false,
  isToday = true,
}) => {
  const filterDate = React.useCallback(
    (date: Date) => {
      if (isOnlyPassed) {
        if (isToday) {
          return date > subDays(1)(new Date());
        }

        return date > new Date();
      }

      return true;
    },
    [isOnlyPassed, isToday]
  );

  const handleChange = React.useCallback(
    (date: Date) => {
      form.setFieldValue(field.name, date);
    },
    [field.name, form.setFieldValue]
  );

  return (
    <DatePicker
      selected={field.value}
      onChange={handleChange}
      filterDate={filterDate}
      popperClassName={'custom-calendar-container'}
      wrapperClassName={'custom-calendar-container'}
      placeholderText={placeholder}
      customInput={<FormDateInput />}
      dateFormat={'dd MMMM (EEEE)'}
      disabledKeyboardNavigation={true}
      shouldCloseOnSelect={true}
    />
  );
};

export default FormDateComponent;
