import styled, { icon } from '@common/theme/styled-components';

export const UserMenuWrapper = styled.section`
  position: relative;
`;

export const UserMenuTitle = styled.div`
  height: ${(props) => props.theme.toolbarHeight};
  line-height: ${(props) => props.theme.toolbarHeight};

  background: ${(props) => props.theme.brandColor};
  color: ${(props) => props.theme.whiteColor};

  font-size: ${(props) => props.theme.largeFontSize};
  font-family: ${(props) => props.theme.headerFontFamily};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const UserMenuClose = styled.button`
  position: absolute;
  top: 20px;
  left: ${(props) => props.theme.smallPadding};

  border: 0;

  background: none;

  &:hover {
    border: 0;
  }

  ${(props) => icon({ color: props.theme.whiteColor })};
`;
