import * as React from 'react';

import { GroupValue, GroupValueLabel, GroupValueValue, GroupValueRender } from './GroupValue.styled';
import { ComponentProps } from './GroupValue.types';

const GroupValueComponent: React.FC<ComponentProps> = ({ label, value, renderValue, isLarge }) => {
  return (
    <GroupValue>
      <GroupValueLabel>{label}</GroupValueLabel>
      {value && <GroupValueValue isLarge={isLarge}>{value}</GroupValueValue>}
      {renderValue && <GroupValueRender>{renderValue()}</GroupValueRender>}
    </GroupValue>
  );
};

export default GroupValueComponent;
