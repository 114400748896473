import * as React from 'react';
import { withFormik, InjectedFormikProps, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { Tools, ToolsItem } from './Tools.styled';
import { ComponentProps } from './Tools.types';
import { FormSelect } from '@common/components';
import { ProductSearchParams } from '@common/store/product/types';

const ToolsComponent: React.FC<InjectedFormikProps<ComponentProps, ProductSearchParams>> = ({
  values,
  handleChangeSearchParams,
}) => {
  const { t } = useTranslation();

  const didMountRef = React.useRef(false);

  React.useEffect(() => {
    if (didMountRef.current) {
      handleChangeSearchParams(values);
    } else {
      didMountRef.current = true;
    }
  }, [values]);

  return (
    <Tools>
      <ToolsItem>
        <Field
          name={'s_price'}
          placeholder={t('listing:toolDefault')}
          options={[
            {
              label: t('listing:toolSortPriceAsc'),
              value: 'asc',
            },
            {
              label: t('listing:toolSortPriceDesc'),
              value: 'desc',
            },
          ]}
          component={FormSelect}
        />
      </ToolsItem>
      <ToolsItem>
        <Field
          name={'pageSize'}
          options={[
            {
              label: `${t('listing:toolPageSize')} 15`,
              value: '15',
            },
            {
              label: `${t('listing:toolPageSize')} 30`,
              value: '30',
            },
            {
              label: `${t('listing:toolPageSize')} 45`,
              value: '45',
            },
          ]}
          component={FormSelect}
        />
      </ToolsItem>
    </Tools>
  );
};

export default {
  component: withFormik<ComponentProps, ProductSearchParams>({
    mapPropsToValues: ({ searchParams }) => {
      return searchParams;
    },
    handleSubmit: (values, { props: { handleChangeSearchParams } }) => {
      handleChangeSearchParams(values);
    },
    enableReinitialize: true,
  })(ToolsComponent),
};
