import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { withFormik, InjectedFormikProps, Field } from 'formik';

import {
  Filters,
  FiltersResetHandler,
  Filter,
  FilterLabel,
  FilterOpenHandler,
  FilterOptionList,
} from './Filters.styled';

import { ComponentProps, ComponentFilledFilter, RootFilterState, FILTER_TYPE } from './Filters.types';
import { FormCheckboxGroup, FormRange, Icon, FormRadioGroup } from '@common/components';
import { ProductSearchParams } from '@common/store/product/types';
import { aggregateFilterOptions, isVisibleFilter } from '@common/lib/helpers/filters';

const FiltersComponent: React.FC<InjectedFormikProps<ComponentProps, ProductSearchParams>> = ({
  values,
  resetForm,
  filters,
  initialSearchParams,
  handleChangeSearchParams,
  handleResetSearchParams,
}) => {
  const { t } = useTranslation();

  const didMountRef = React.useRef(false);

  React.useEffect(() => {
    if (didMountRef.current) {
      handleChangeSearchParams(values);
    } else {
      didMountRef.current = true;
    }
  }, [values]);

  const [filtersState, setFiltersState] = React.useState(
    filters.reduce<RootFilterState>((filtersState, filter) => {
      return {
        ...filtersState,
        [filter.key]: {
          isOpen: filter.isOpen,
        },
      };
    }, {})
  );

  const filteredFilters = React.useMemo(() => {
    const availableFilters = filters.reduce<ComponentFilledFilter[]>((availableFilters, filter) => {
      if ([FILTER_TYPE.AGGR, FILTER_TYPE.MULTY_AGGR].includes(filter.type)) {
        if (!filter.aggregations) {
          return availableFilters;
        }

        return [
          ...availableFilters,
          {
            ...filter,
            options: aggregateFilterOptions(filter.options, filter.aggregations),
          },
        ];
      }

      return [
        ...availableFilters,
        {
          ...filter,
          options: [],
        },
      ];
    }, []);

    return availableFilters;
  }, [filters]);

  const handleToggleOpen = React.useCallback(
    (filterKey) => {
      setFiltersState({
        ...filtersState,
        [filterKey]: {
          ...filtersState[filterKey],
          isOpen: !filtersState[filterKey].isOpen,
        },
      });
    },
    [filtersState]
  );

  const handleReset = React.useCallback(() => {
    resetForm({
      values: initialSearchParams,
    });
    handleResetSearchParams();

    setFiltersState(
      filters.reduce<RootFilterState>((filtersState, filter) => {
        return {
          ...filtersState,
          [filter.key]: {
            isOpen: filter.isOpen,
          },
        };
      }, {})
    );
  }, [resetForm, handleResetSearchParams, filters, initialSearchParams]);

  return (
    <Filters>
      {filteredFilters.map(
        (filter) =>
          isVisibleFilter(filter) && (
            <Filter key={filter.key}>
              <FilterLabel onClick={() => handleToggleOpen(filter.key)}>
                {filter.label}
                <FilterOpenHandler>
                  <Icon type='arrow' variant={filtersState[filter.key].isOpen ? 'down' : 'right'} />
                </FilterOpenHandler>
              </FilterLabel>
              {filter.type === FILTER_TYPE.MULTY_AGGR && (
                <FilterOptionList isOpen={filtersState[filter.key].isOpen}>
                  <Field
                    component={FormCheckboxGroup}
                    name={filter.key}
                    buttons={filter.options.map((filterOption) => ({
                      label: filterOption.option.getName(),
                      name: filterOption.option.getId().toString(),
                      count: filterOption.count,
                    }))}
                  />
                </FilterOptionList>
              )}
              {filter.type === FILTER_TYPE.AGGR && (
                <FilterOptionList isOpen={filtersState[filter.key].isOpen}>
                  <Field
                    component={FormRadioGroup}
                    name={filter.key}
                    buttons={filter.options.map((filterOption) => ({
                      label: filterOption.option.getName(),
                      name: filterOption.option.getId().toString(),
                      count: filterOption.count,
                    }))}
                  />
                </FilterOptionList>
              )}
              {filter.type === FILTER_TYPE.RANGE && (
                <FilterOptionList isOpen={filtersState[filter.key].isOpen}>
                  <Field name={filter.key} min={filter.range.min} max={filter.range.max} component={FormRange} />
                </FilterOptionList>
              )}
            </Filter>
          )
      )}
      <FiltersResetHandler onClick={handleReset}>
        <Icon type='close' />
        {t('listing:resetFilters')}
      </FiltersResetHandler>
    </Filters>
  );
};

export default {
  component: withFormik<ComponentProps, ProductSearchParams>({
    mapPropsToValues: ({ filters }) => {
      return filters.reduce((actualFilters, filter) => {
        return {
          ...actualFilters,
          [filter.key]: filter.value,
        };
      }, {} as ProductSearchParams);
    },
    handleSubmit: (values, { props: { handleChangeSearchParams } }) => {
      handleChangeSearchParams(values);
    },
    enableReinitialize: true,
  })(FiltersComponent),
};
