import { State } from '@common/store/reducer';

function selectIsSidebarOpen(state: State) {
  return state.ui.isSidebarOpen;
}

function selectIsUserMenuOpen(state: State) {
  return state.ui.isUserMenuOpen;
}

function selectIsListingMenuOpen(state: State) {
  return state.ui.isListingMenuOpen;
}

function selectIsMenuOpen(state: State) {
  return state.ui.isMenuOpen;
}

function selectCatalog(state: State) {
  return state.ui.catalog;
}

export const uiSelectors = {
  selectIsSidebarOpen,
  selectIsUserMenuOpen,
  selectIsListingMenuOpen,
  selectIsMenuOpen,
  selectCatalog,
};
