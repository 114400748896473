import { action } from 'typesafe-actions';

import { BasketCookie, BasketActionTypes } from './types';
import { ProductDataInterface } from '../product/types';
import { ErrorInterface } from '../types';
import { generateNormalizer } from '@common/lib/pagination/store';
import { productSchema } from '../product/schema';

function initBasket() {
  return action(BasketActionTypes.INIT_BASKET);
}

function setUpBasket(basket: BasketCookie) {
  return action(BasketActionTypes.SET_UP_BASKET, { basket });
}

function addToBasket(id: string, quantity = 1) {
  return action(BasketActionTypes.ADD_TO_BASKET, { id, quantity });
}

function removeFromBasket(id: string, quantity = 1) {
  return action(BasketActionTypes.REMOVE_FROM_BASKET, { id, quantity });
}

function updateBasket(id: string, quantity = 1) {
  return action(BasketActionTypes.UPDATE_BASKET, { id, quantity });
}

function clearBasket() {
  return action(BasketActionTypes.CLEAR_BASKET);
}

function loadBasketRequest() {
  return action(BasketActionTypes.LOAD_BASKET_REQUEST);
}

function loadBasketSuccess(products: ProductDataInterface[]) {
  const normalizer = generateNormalizer<ProductDataInterface>(productSchema);

  return action(BasketActionTypes.LOAD_BASKET_SUCCESS, normalizer.normalizeList(products));
}

function loadBasketFailure(error: ErrorInterface) {
  return action(BasketActionTypes.LOAD_BASKET_FAILURE, { error });
}

export const basketActions = {
  initBasket,
  setUpBasket,
  addToBasket,
  removeFromBasket,
  updateBasket,
  clearBasket,
  loadBasketRequest,
  loadBasketSuccess,
  loadBasketFailure,
};

export default basketActions;
