import styled from '@common/theme/styled-components';

import 'rc-tooltip/assets/bootstrap.css';

export const FormRange = styled.div`
  ${require('rc-slider/assets/index.css')};

  padding: 18px 8px;

  .rc-slider {
    position: relative;

    .rc-slider-track {
      background-color: ${(props) => props.theme.brandColor};
    }

    .rc-slider-handle {
      border: 2px solid ${(props) => props.theme.brandColor};

      &:active {
        box-shadow: 0 0 2px ${(props) => props.theme.brandColor};
      }

      &:focus {
        box-shadow: 0 0 0 2px ${(props) => props.theme.brandColor};
      }
    }

    .rc-slider-mark {
      .rc-slider-mark-text {
        white-space: nowrap;
      }
    }

    .rc-slider-tooltip {
      .rc-slider-tooltip-inner {
        white-space: nowrap;
      }
    }
  }
`;
