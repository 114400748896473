export enum ButtonTypes {
  DEFAULT = 'DEFAULT',
  BORDERLESS = 'BORDERLESS',
  PRIMARY = 'PRIMARY',
}

export enum ButtonSizes {
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

export type ButtonTheme = {
  borderColor: string;
  borderColorHover: string;
  background: string;
  backgroundHover: string;
  color: string;
  colorHover: string;
  iconColor: string;
  horizontalPadding: string;
  verticalPadding: string;
};

export type ButtonThemes = {
  [themeName: string]: ButtonTheme;
};

export interface ComponentProps {
  type?: 'button' | 'submit' | 'reset';
  buttonType?: ButtonTypes;
  buttonSize?: ButtonSizes;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  form?: string;
  to?: string;
  icon?: {
    type: string;
    variant?: string;
  };
  actionIcon?: {
    type: string;
    variant?: string;
  };
}
