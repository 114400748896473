import i18next from 'i18next';

export type GetSeo = {
  title?: string;
  description?: string;
  url?: string;
  imgUrl?: string;
};

export type Meta = {
  name?: string;
  property?: string;
  content?: string;
};

export type Seo = {
  title?: string;
  meta?: Meta[];
};

function getSeo(t: i18next.TFunction, { title, description, url, imgUrl }: GetSeo) {
  const seo: Seo = { meta: [] };

  if (title) {
    seo.title = title;
    seo.meta = [
      ...seo.meta,
      {
        property: 'og:title',
        content: title + t('general:appTitleTemplate'),
      },
      {
        name: 'twitter:title',
        content: title + t('general:appTitleTemplate'),
      },
    ];
  }

  if (description) {
    seo.meta = [
      ...seo.meta,
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        name: 'twitter:description',
        content: description,
      },
    ];
  }

  if (url) {
    seo.meta = [
      ...seo.meta,
      {
        property: 'og:url',
        content: url,
      },
      {
        name: 'twitter:url',
        content: url,
      },
    ];
  }

  if (imgUrl) {
    seo.meta = [
      ...seo.meta,
      {
        property: 'og:image',
        content: imgUrl,
      },

      {
        name: 'twitter:image',
        content: imgUrl,
      },
    ];
  }

  return seo;
}

const seoHelper = {
  getSeo,
};

export default seoHelper;
