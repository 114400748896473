import * as React from 'react';

import { Sidebar, SidebarTitle, SidebarBody } from './Sidebar.styled';

interface Props {
  title: string;
}

const Component: React.FC<Props> = ({ title, children }) => (
  <Sidebar>
    <SidebarTitle>{title}</SidebarTitle>
    <SidebarBody>{children}</SidebarBody>
  </Sidebar>
);

export default Component;
