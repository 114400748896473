import styled, { media, icon } from '@common/theme/styled-components';

export const SliderArrow = styled.span`
  && {
    height: auto;
    width: auto;

    top: 50%;
    transform: translateY(-50%);

    margin: 0;

    background: none;
    outline: none;
    opacity: 0.5;
    transition: ${(props) => props.theme.defaultTransition};

    ${(props) => icon({ color: props.theme.brandColor, size: props.theme.xlargeFontSize })};

    ${media.desktop`
      &:hover {
        opacity: 1;
      }
    `}

    &::after {
      display: none;
    }
  }
`;
