import { toast } from 'react-toastify';

export enum NOTIFICATION_TYPES {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  DEFAULT = 'DEFAULT',
}

function pushNotification(message: string, type: NOTIFICATION_TYPES = NOTIFICATION_TYPES.DEFAULT) {
  switch (type) {
    case NOTIFICATION_TYPES.INFO:
      toast.info(message);
      break;
    case NOTIFICATION_TYPES.SUCCESS:
      toast.success(message);
      break;
    case NOTIFICATION_TYPES.WARNING:
      toast.warn(message);
      break;
    case NOTIFICATION_TYPES.ERROR:
      toast.error(message);
      break;
    case NOTIFICATION_TYPES.DEFAULT:
      toast(message);
      break;
    default:
  }
}

const notificationHelper = {
  pushNotification,
};

export default notificationHelper;
