import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  HomeWrapper,
  HomeCategories,
  HomeAdvantages,
  HomeSection,
  HomeMostPopular,
  HomeFeatured,
  HomeNew,
} from './Home.styled';

import { Container, Page } from '@common/components';
import { Categories } from './Categories/Categories';
import { Advantages } from './Advantages/Advantages';
import { MostPopular } from './MostPopular/MostPopular';
import { Featured } from './Featured/Featured';
import { New } from './New/New';
import { PRODUCT_SELECTIONS } from '@common/store/product/types';
import { productModel } from '@common/store/product/model';
import { productSelectors } from '@common/store/product/selectors';
import { State } from '@common/store/reducer';
import { productActions } from '@common/store/product/actions';
import { productSagas } from '@common/store/product/sagas';

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const mostPopularProducts = useSelector((state: State) =>
    productSelectors.selectSelection(state, PRODUCT_SELECTIONS.MOST_POPULAR)
  );

  const featuredProducts = useSelector((state: State) =>
    productSelectors.selectSelection(state, PRODUCT_SELECTIONS.FEATURED)
  );

  const newProducts = useSelector((state: State) => productSelectors.selectSelection(state, PRODUCT_SELECTIONS.NEW));

  React.useEffect(() => {
    if (!mostPopularProducts.isLoaded) {
      dispatch(productActions.loadSelectionRequest(PRODUCT_SELECTIONS.MOST_POPULAR));
    }
  }, []);

  React.useEffect(() => {
    if (!featuredProducts.isLoaded) {
      dispatch(productActions.loadSelectionRequest(PRODUCT_SELECTIONS.FEATURED));
    }
  }, []);

  React.useEffect(() => {
    if (!newProducts.isLoaded) {
      dispatch(productActions.loadSelectionRequest(PRODUCT_SELECTIONS.NEW));
    }
  }, []);

  return (
    <Page withContainer={false}>
      <HomeWrapper>
        <HomeCategories>
          <Container>
            <Categories />
          </Container>
        </HomeCategories>
        <Container>
          <HomeAdvantages>
            <Advantages />
          </HomeAdvantages>
          <HomeSection>
            <HomeMostPopular>
              <MostPopular
                isLoading={mostPopularProducts.isLoading}
                isLoaded={mostPopularProducts.isLoaded}
                products={mostPopularProducts.data.map((_) => productModel(_))}
              />
            </HomeMostPopular>
            <HomeFeatured>
              <Featured
                isLoading={featuredProducts.isLoading}
                isLoaded={featuredProducts.isLoaded}
                products={featuredProducts.data.map((_) => productModel(_))}
              />
            </HomeFeatured>
          </HomeSection>
          <HomeNew>
            <New
              isLoading={newProducts.isLoading}
              isLoaded={newProducts.isLoaded}
              products={newProducts.data.map((_) => productModel(_))}
            />
          </HomeNew>
        </Container>
      </HomeWrapper>
    </Page>
  );
};

const preload = () => {
  return [
    () => productSagas.loadSelection(productActions.loadSelectionRequest(PRODUCT_SELECTIONS.MOST_POPULAR)),
    () => productSagas.loadSelection(productActions.loadSelectionRequest(PRODUCT_SELECTIONS.FEATURED)),
    () => productSagas.loadSelection(productActions.loadSelectionRequest(PRODUCT_SELECTIONS.NEW)),
  ];
};

export default {
  component: Home,
  preload,
};
