import styled, { media } from '@common/theme/styled-components';

export const LanguagesWrapper = styled.div``;

export const LanguagesLabel = styled.span`
  font-size: 13px;

  ${media.tablet`
    margin-right: 5px;
  `}
`;

export const LanguageHandler = styled.div`
  ${media.tablet`
    display: flex;
    align-items: center;

    & svg {
      display: initial;
      width: 12px;
      height: 12px;
    }
  `}

  & svg {
    display: none;
    fill: ${(props) => props.theme.textDisabledColor};
  }
`;
