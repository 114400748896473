import styled from '@common/theme/styled-components';

export const BasketQuickBuyLabel = styled.div``;

export const BasketQuickBuyLabelFields = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
`;

export const BasketQuickBuyLabelActions = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: ${(props) => props.theme.smallPadding};
`;
