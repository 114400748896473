import { Link } from 'react-router-dom';
import styled, { media } from '../../../theme/styled-components';

export const HeaderWrapper = styled.header``;

export const HeaderMenuToggle = styled.div`
  ${media.desktop`
    display: none;
  `};
`;

export const HeaderTop = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${(props) => props.theme.zIndexes.header};

  border-bottom: 1px solid ${(props) => props.theme.brandBgColor};

  background: ${(props) => props.theme.whiteColor};
`;

export const HeaderTopInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  line-height: 40px;

  svg {
    max-width: 20px;
    max-height: 20px;
  }
`;

export const HeaderTopLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;

  & > * {
    margin-right: 10px;
  }
`;

export const HeaderTopMiddle = styled.div`
  flex: 1 1;
  text-align: center;
`;

export const HeaderTopLogo = styled(Link)`
  color: ${(props) => props.theme.brandAltColor};
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
`;

export const HeaderTopRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;

  & > * {
    margin-left: 10px;
  }
`;

export const HeaderBody = styled.section``;

export const HeaderBodyInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLogo = styled(Link)`
  display: flex;
  justify-content: center;

  padding: 15px 15px 15px 0;

  & svg {
    width: 50px;
    height: 50px;

    fill: ${(props) => props.theme.brandAltColor};
  }

  ${media.desktop`
    flex: 0 0 ${(props) => props.theme.desktopMenu.width};

    padding: 30px 30px 30px 15px;

    border-left: 1px solid ${(props) => props.theme.brandBgColor};
    border-right: 1px solid ${(props) => props.theme.brandBgColor};

    & svg {
      width: 65px;
      height: 65px;
    }
  `}
`;

export const HeaderSearchBox = styled.div`
  width: 100%;

  ${media.desktop`
    padding: 0 30px;
  `}
`;

export const HeaderContacts = styled.div`
  display: none;

  ${media.desktop`
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 0 0 ${(props) => props.theme.desktopMenu.width};

    font-size: 24px;
    font-weight: bold;

    & svg {
      fill: ${(props) => props.theme.brandAltColor};

      width: 30px;
      height: 30px;

      margin-right: 10px;
    }
  `}
`;

export const ContactPhone = styled.a`
  display: flex;
  align-items: center;

  text-decoration: none;
`;
