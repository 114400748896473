import * as React from 'react';

import { NewWrapper, NewProducts } from './New.styled';
import { NewProps } from './New.types';

import { ProductSection, ProductItem, Loader } from '@common/components';
import { PRODUCT_ITEM_TYPES } from '@common/components/ProductItem/ProductItem.types';

export const New: React.FC<NewProps> = ({ isLoading, isLoaded, products }) => (
  <NewWrapper>
    <Loader
      isLoading={isLoading}
      isLoaded={isLoaded}
      render={() => (
        <ProductSection title={'Новые товары'}>
          <NewProducts>
            {products.map((product) => (
              <ProductItem key={product.getId()} item={product} type={PRODUCT_ITEM_TYPES.DEFAULT} />
            ))}
          </NewProducts>
        </ProductSection>
      )}
    />
  </NewWrapper>
);
