import styled, { media } from '@common/theme/styled-components';

export const HomeWrapper = styled.section``;

export const HomeCategories = styled.section`
  height: ${(props) => props.theme.mainSlider.heightMobile};

  background-color: ${(props) => props.theme.whiteColorHover};
  background-image: url('${require('@assets/images/bg/home-full-width-bg.png')}');
  background-size: cover;

  ${media.desktop`
    height: ${(props) => props.theme.mainSlider.height};
  `}
`;

export const HomeAdvantages = styled.section`
  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    margin-top: ${(props) => props.theme.largePadding};
  `}
`;

export const HomeSection = styled.section`
  ${media.desktop`
    display: flex;
  `}
`;

export const HomeMostPopular = styled.section`
  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    width: ${(props) => props.theme.sidebarMaxWidth};

    margin-top: ${(props) => props.theme.largePadding};
    padding-right: ${(props) => props.theme.smallPadding};
  `}
`;

export const HomeFeatured = styled.section`
  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    width: 100%;

    margin-top: ${(props) => props.theme.largePadding};

    overflow: hidden;
  `}
`;

export const HomeNew = styled.section`
  margin-top: ${(props) => props.theme.smallPadding};
`;
