import styled from '@common/theme/styled-components';

export const FormTextArea = styled.textarea`
  display: block;

  width: 100%;

  padding: 10px 12px;
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  resize: none;

  & + & {
    margin-top: ${(props) => props.theme.smallPadding};
  }
`;
