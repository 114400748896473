import * as React from 'react';

import {
  FormRadio,
  FormRadioLabel,
  FormRadioDescription,
  FormRadioHighlight,
  FormRadioCount,
} from './FormRadio.styled';
import { FormRadioProps } from './FormRadio.types';

const FormRadioComponent: React.FC<FormRadioProps> = ({
  label,
  value,
  name,
  highlight,
  description,
  count,
  onChange,
}) => {
  const handleChange = React.useCallback(() => {
    onChange(name, !value);
  }, [value, name, onChange]);

  return (
    <FormRadio onClick={handleChange}>
      <FormRadioLabel isChecked={value}>
        {label} {highlight && <FormRadioHighlight>{highlight}</FormRadioHighlight>}
        {!!count && <FormRadioCount>({count})</FormRadioCount>}
      </FormRadioLabel>
      {description && <FormRadioDescription>{description}</FormRadioDescription>}
    </FormRadio>
  );
};

export default FormRadioComponent;
