import styled, { media, icon } from '@common/theme/styled-components';

export const Listing = styled.section`
  ${media.desktop`
    display: flex;
  `}
`;

export const ListingSidebar = styled.div`
  display: none;

  ${media.desktop`
    display: block;

    width: 300px;
  `}
`;

export const ListingBody = styled.div`
  ${media.desktop`
    flex: 1;

    margin-left: ${(props) => props.theme.smallPadding};
  `}
`;

export const ListingTools = styled.div`
  display: none;

  ${media.desktop`
    display: block;

    margin-top: 0;
  `}
`;

export const ListingFiltersHandler = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: ${(props) => props.theme.smallPadding};
  border: 1px solid ${(props) => props.theme.brandAltColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  background: none;
  color: ${(props) => props.theme.brandAltColor};
  font-weight: bold;
  text-transform: uppercase;

  ${(props) => icon({ color: props.theme.brandAltColor, size: props.theme.xsmallFontSize })}

  ${media.desktop`
    display: none;
  `}

  svg {
    margin-right: ${(props) => props.theme.xsmallPadding};
  }
`;

export const ListingGrid = styled.div`
  ${media.mobile`
    margin-top: ${(props) => props.theme.smallPadding};
  `}
`;

export const ListingNotFound = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListingNotFoundLabel = styled.div`
  margin-bottom: ${(props) => props.theme.smallPadding};

  font-size: ${(props) => props.theme.mediumFontSize};
  text-align: center;
`;
