import { Category } from '@common/store/ui/types';

type GetCategoryArgs = {
  catalog: Category[];
  systemName: string;
};

function getCategory({ catalog, systemName }: GetCategoryArgs) {
  function findCategory({ catalog, systemName }: GetCategoryArgs) {
    let currentCategory: undefined | Category;

    catalog &&
      catalog.forEach((category: Category) => {
        if (category.link.includes(systemName)) {
          currentCategory = category;
        }
        if (!currentCategory) {
          currentCategory = findCategory({ catalog: category.children, systemName });
        }
      });

    return currentCategory;
  }

  const category = findCategory({ catalog, systemName }) || {
    id: 1,
    title: '',
    system_name: '',
    link: '/',
    isRoot: true,
    children: [],
  };

  return category;
}

const catalogHelper = {
  getCategory,
};

export default catalogHelper;
