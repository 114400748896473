import { ComponentFilledFilter, FILTER_TYPE } from '@common/pages/Listing/Filters/Filters.types';
import { DatabaseModel } from '@common/store/model';

export type AggregatedFilterOption = { option: DatabaseModel; count: number };

export function aggregateFilterOptions(
  options: DatabaseModel[],
  aggregations: {
    key: string;
    doc_count: number;
  }[]
): AggregatedFilterOption[] {
  return options.reduce((aggregatedOptions, option) => {
    if (parseInt(option.getId()) === 1) {
      return aggregatedOptions;
    }

    const bucket = aggregations.find((aggs) => aggs.key === option.getId().toString());

    if (!bucket) {
      return aggregatedOptions;
    }

    return [
      ...aggregatedOptions,
      {
        option,
        count: bucket ? bucket.doc_count : 0,
      },
    ];
  }, []);
}

export const isVisibleFilter = (filter: ComponentFilledFilter) => {
  if ([FILTER_TYPE.AGGR, FILTER_TYPE.MULTY_AGGR].includes(filter.type)) {
    return filter.options.length > 0;
  }

  return true;
};
