import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ContactsAddresses } from './Contacts.styled';
import { contactsSeo } from './Contacts.seo';
import Map from './Map/Map';
import { Info } from './Info/Info';
import { Page, Container } from '@common/components';

const ContactsComponent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('contacts:pageTitle')}
      withContainer={false}
      seo={contactsSeo(t)}
      breadcrumbs={[
        {
          link: '/contacts',
          title: t('contacts:pageTitle'),
        },
      ]}
    >
      <Container>
        <Map />
        <ContactsAddresses>
          <Info />
        </ContactsAddresses>
      </Container>
    </Page>
  );
};

export default {
  component: ContactsComponent,
};
