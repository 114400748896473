import * as React from 'react';
import Swiper, { SwiperInstance } from 'react-id-swiper';

import { Slider } from './Slider.styled';
import { ComponentProps } from './Slider.types';
import SliderArrow from './SliderArrow/SliderArrow';
import { sizes } from '@common/theme/styled-components';
import { SwiperOptions } from 'swiper';

const SliderComponent: React.FC<ComponentProps> = ({ children, config = {}, initSwiper, thumbSwiper }) => {
  const [swiper, setSwiper] = React.useState<SwiperInstance>(null);

  React.useEffect(() => {
    initSwiper(swiper);
  }, [swiper, initSwiper]);

  React.useEffect(() => {
    if (swiper) {
      swiper.controller.control = thumbSwiper;
    }
  }, [swiper, thumbSwiper]);

  const defaultConfig: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 1,
    slidesPerColumnFill: 'row',
    spaceBetween: 5,
    breakpointsInverse: true,
    loop: true,
    autoplay: true,
    ...config,
  };

  if (config.isWithPagination) {
    defaultConfig.pagination = {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      ...config.pagination,
    };
  }

  if (config.isWithNavigation) {
    defaultConfig.navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      ...config.navigation,
    };
  }

  if (!config.isSingle) {
    defaultConfig.loop = false;
    defaultConfig.autoplay = false;
    defaultConfig.breakpoints = {
      [sizes.mobile]: {
        slidesPerView: 2,
        ...config.breakpoints?.[sizes.mobile],
      },
      [sizes.tablet]: {
        slidesPerView: 3,
        spaceBetween: 15,
        ...config.breakpoints?.[sizes.tablet],
      },
      [sizes.desktop]: {
        slidesPerView: 4,
        spaceBetween: 15,
        ...config.breakpoints?.[sizes.desktop],
      },
      [sizes.large]: {
        slidesPerView: 4,
        spaceBetween: 15,
        ...config.breakpoints?.[sizes.large],
      },
    };
  }

  const defaultThumbConfig: SwiperOptions = config.isThumb
    ? {
        slidesPerView: 4,
        centeredSlides: true,
        slideToClickedSlide: true,
        breakpoints: {
          [sizes.mobile]: {
            ...config.breakpoints?.[sizes.mobile],
          },
          [sizes.tablet]: {
            spaceBetween: 15,
            ...config.breakpoints?.[sizes.tablet],
          },
          [sizes.desktop]: {
            spaceBetween: 15,
            ...config.breakpoints?.[sizes.desktop],
          },
        },
      }
    : {};

  return (
    <Slider>
      <Swiper
        getSwiper={setSwiper}
        {...defaultConfig}
        {...defaultThumbConfig}
        renderPrevButton={() => (
          <SliderArrow className='swiper-button-prev' orientation={'left'}>
            Prev
          </SliderArrow>
        )}
        renderNextButton={() => (
          <SliderArrow className='swiper-button-next' orientation={'right'}>
            Next
          </SliderArrow>
        )}
      >
        {React.Children.map(children, (child) => (
          <div>{child}</div>
        ))}
      </Swiper>
    </Slider>
  );
};

export default SliderComponent;
