export enum InputTypes {
  TEXT = 'text',
  PHONE = 'phone',
  PASSWORD = 'password',
  NUMBER = 'number',
}

export interface ComponentProps {
  type?: InputTypes;
  placeholder?: string;
}
