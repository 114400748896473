import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { DummyLink } from './SmarkLink.styled';

const SmartLink: React.FC<LinkProps> = ({ children, ...props }) => {
  if (props.to) {
    return <Link {...props}>{children}</Link>;
  }

  return <DummyLink className={props.className}>{children}</DummyLink>;
};

export default SmartLink;
