import styled from '@common/theme/styled-components';

export const FormTime = styled.div`
  display: flex;
  align-items: center;
`;

export const FormTimeSeparator = styled.div`
  margin: 0 ${(props) => props.theme.smallPadding};
`;

export const FormTimeSelect = styled.select<{ isPlaceholder: boolean }>`
  position: relative;
  width: 100%;

  padding: 10px 12px;
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  color: ${(props) => props.isPlaceholder && props.theme.textDisabledColor};
`;

export const FormTimeOption = styled.option``;
