import * as React from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormikProps, withFormik, Field } from 'formik';

import {
  BasketItem,
  BasketItemIndex,
  BasketItemImage,
  BasketItemSrc,
  BasketItemTitle,
  BasketItemPrice,
  BasketItemQuantity,
  BasketItemTotal,
  BasketItemRemove,
  BasketItemTop,
  BasketItemBottom,
} from './BasketItem.styled';
import { BasketItemProps, BasketItemFormProps } from './BasketItem.types';
import { GroupValue, FormNum, Icon } from '@common/components';
import validator from '@common/lib/validator/validator';
import { basketActions } from '@common/store/basket/actions';
import { usePrevious } from '@common/hooks/usePrevious';

const BasketItemComponent: React.FC<InjectedFormikProps<BasketItemProps, BasketItemFormProps>> = ({
  item,
  basketItem,
  index,
  values,
}) => {
  const dispatch = useDispatch();

  const prevQuantity = usePrevious(values.quantity);

  React.useEffect(() => {
    if (prevQuantity) {
      dispatch(basketActions.updateBasket(item.getId(), values.quantity));
    }
  }, [values.quantity]);

  const handleRemove = React.useCallback(() => {
    dispatch(basketActions.removeFromBasket(item.getId(), basketItem.quantity));
  }, [item, basketItem]);

  return (
    <BasketItem>
      <BasketItemTop>
        <BasketItemIndex>{index}</BasketItemIndex>
        <BasketItemImage to={item.getViewLink()} title={item.getTitle()}>
          <BasketItemSrc src={item.getMainImage()} title={item.getTitle()} alt={item.getTitle()} />
        </BasketItemImage>
        <BasketItemTitle>
          <GroupValue label={'Наименование'} value={item.getTitle()} />
        </BasketItemTitle>
      </BasketItemTop>
      <BasketItemBottom>
        <BasketItemPrice>
          <GroupValue label={'Цена'} value={item.getPrice()} />
        </BasketItemPrice>
        <BasketItemQuantity>
          <GroupValue label={'Количество'} renderValue={() => <Field component={FormNum} name='quantity' />} />
        </BasketItemQuantity>
        <BasketItemTotal>
          <GroupValue label={'Сумма'} value={item.getPrice(basketItem.quantity)} isLarge={true} />
        </BasketItemTotal>
      </BasketItemBottom>
      <BasketItemRemove onClick={handleRemove}>
        <Icon type='close' />
      </BasketItemRemove>
    </BasketItem>
  );
};

export default withFormik<BasketItemProps, BasketItemFormProps>({
  mapPropsToValues: ({ basketItem }) => {
    return {
      quantity: basketItem.quantity,
    };
  },
  validationSchema: () => {
    return validator.object().shape({
      quantity: validator.number().required(),
    });
  },
  handleSubmit: () => {
    //
  },
})(BasketItemComponent);
