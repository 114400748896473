import styled, { icon } from '@common/theme/styled-components';
import { NavLink } from 'react-router-dom';

export const BreadcrumbsWrapper = styled.div`
  margin: ${(props) => props.theme.smallPadding} 0;
  line-height: 1;
`;

export const BreadcrumbsLink = styled(NavLink)`
  color: ${(props) => props.theme.brandColor};
  font-weight: bold;
  text-decoration: none;
`;

export const BreadcrumbsFinalItem = styled.span`
  font-weight: bold;
`;

export const BreadcrumbsSeparator = styled.span`
  display: inline-block;

  padding: ${(props) => props.theme.xsmallPadding};

  ${(props) => icon({ color: props.theme.brandColor, size: '8px' })}
`;
