import styled, { media } from '@common/theme/styled-components';

export const FeaturedWrapper = styled.div``;

export const FeaturedProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${(props) => props.theme.xsmallPadding};

  & > * {
    display: none;

    &:nth-child(-n + 6) {
      display: block;
    }

    ${media.tablet`
      display: block;
    `}
  }

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${media.desktop`
    gap: ${(props) => props.theme.smallPadding};
  `}
`;
