import styled, { media } from '@common/theme/styled-components';

export const TotalBox = styled.div``;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: ${(props) => props.theme.xsmallPadding};

  ${media.desktop`
    justify-content: flex-start;
  `}
`;

export const TotalLabel = styled.div`
  ${media.desktop`
    width: 200px;
  `}
`;

export const TotalValue = styled.div`
  font-weight: bold;
`;

export const GrandTotal = styled(Total)`
  color: ${(props) => props.theme.brandAltColor};
  font-size: ${(props) => props.theme.largeFontSize};
`;
