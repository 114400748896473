import * as React from 'react';
import { FieldProps } from 'formik';

import { FormInput } from './FormInput.styled';
import { ComponentProps, InputTypes } from './FormInput.types';
import { FormError } from '@common/components';
import stringHelper from '@common/lib/helpers/strings';

const TYPING_TIMEOUT = 750;

const masks: {
  [key: string]: string;
} = {
  [InputTypes.PHONE]: '+375 (99) 999-99-99',
};

const FormInputComponent: React.FC<ComponentProps & FieldProps> = ({
  type = InputTypes.TEXT,
  placeholder,
  field,
  form,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState(field.value);

  React.useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    if (field.value !== inputValue) {
      form.setFieldValue(field.name, inputValue);
    }
  }, [inputValue]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (type === InputTypes.NUMBER) {
      setInputValue(parseFloat(event.currentTarget.value));
    } else if (type === InputTypes.PHONE) {
      setInputValue(stringHelper.fromPhone(event.currentTarget.value));
    } else {
      setInputValue(event.currentTarget.value);
    }
  };

  return (
    <React.Fragment>
      <FormInput
        name={field.name}
        value={inputValue}
        onChange={handleChange}
        mask={masks[type] || ''}
        type={type}
        placeholder={placeholder}
        {...props}
      />
      <FormError error={form.errors[field.name] as string} />
    </React.Fragment>
  );
};

const FormAsyncInputComponent: React.FC<ComponentProps & FieldProps> = ({
  type = InputTypes.TEXT,
  placeholder,
  field,
  form,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState(field.value);
  const [typingTimeout, setTypingTimeout] = React.useState(null);

  React.useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        if (field.value !== inputValue) {
          form.setFieldValue(field.name, inputValue);
        }
      }, TYPING_TIMEOUT)
    );
  }, [inputValue]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (type === InputTypes.NUMBER) {
      setInputValue(parseFloat(event.currentTarget.value));
    } else if (type === InputTypes.PHONE) {
      setInputValue(stringHelper.fromPhone(event.currentTarget.value));
    } else {
      setInputValue(event.currentTarget.value);
    }
  };

  return (
    <React.Fragment>
      <FormInput
        name={field.name}
        value={inputValue}
        onChange={handleChange}
        mask={masks[type] || ''}
        type={type}
        placeholder={placeholder}
        {...props}
      />
      <FormError error={form.errors[field.name] as string} />
    </React.Fragment>
  );
};

const FormInputPhoneComponent: React.SFC<ComponentProps & FieldProps> = (props) => (
  <FormInputComponent {...props} type={InputTypes.PHONE} />
);

export {
  FormInputComponent as FormInput,
  FormAsyncInputComponent as FormAsyncInput,
  FormInputPhoneComponent as FormInputPhone,
};

export default FormInputComponent;
