import styled from '@common/theme/styled-components';

export const Page = styled('section')<{ withContainer: boolean }>`
  padding-bottom: ${(props) => props.withContainer && props.theme.smallPadding};
`;

export const PageTitle = styled.h1`
  text-align: center;

  margin-bottom: ${(props) => props.theme.smallPadding};
  padding: 0 ${(props) => props.theme.smallPadding};
`;

export const PageBody = styled.div``;
