import { ResponseError, ResponseStatus } from './request.types';

function isForbidden(error: null | ResponseError) {
  return error && error.status === ResponseStatus.FORBIDDEN;
}

const requestHelper = {
  isForbidden,
};

export default requestHelper;
