import * as React from 'react';

import { Icon } from '@common/components';

import {
  CategoryMenuWrapper,
  CategoryMenuHandler,
  CategoryMenuSet,
  CategoryMenuItem,
  CategoryMenuLink,
  CategoryMenuArrow,
} from './CategoryMenu.styled';
import { Category } from '@common/store/ui/types';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelectors } from '@common/store/ui/selectors';
import { uiActions } from '@common/store/ui/actions';

export const CategoryMenu: React.FC = () => {
  const dispatch = useDispatch();

  const catalog = useSelector(uiSelectors.selectCatalog);
  const isMenuOpen = useSelector(uiSelectors.selectIsMenuOpen);

  const handleActivation = React.useCallback(() => {
    dispatch(uiActions.toggleMenu());
  }, [uiActions.toggleMenu]);

  const handleClickMenuItem = React.useCallback((event: any, link: string) => {
    if (!link) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, []);

  const renderMenuGrid = (gridRootNodes: Category[], level = 1): React.ReactNode[] => {
    let items: React.ReactNode[] = [];

    gridRootNodes.forEach((rootNode) => {
      let childrenBox: React.ReactNode[] = [];

      if (rootNode.children && rootNode.children.length) {
        childrenBox = [
          <CategoryMenuSet key={rootNode.title} className={`ac-menu ac-menu-level-${level + 1}`} level={level + 1}>
            {renderMenuGrid(rootNode.children, level + 1)}
          </CategoryMenuSet>,
        ];
      }

      items = [
        ...items,
        <CategoryMenuItem key={rootNode.title} className={`ac-menu-item ac-menu-item-level-${level}`}>
          <CategoryMenuLink
            className={`ac-menu-link ac-menu-link-level-${level}`}
            to={rootNode.link}
            onClick={(event) => handleClickMenuItem(event, rootNode.link)}
          >
            {rootNode.title}
            {!!childrenBox.length && (
              <React.Fragment>
                <CategoryMenuArrow className={`ac-menu-arrow`}>
                  <Icon type={'arrow'} variant={'down'} />
                </CategoryMenuArrow>
              </React.Fragment>
            )}
          </CategoryMenuLink>
          {childrenBox}
        </CategoryMenuItem>,
      ];
    });

    return items;
  };

  return (
    <CategoryMenuWrapper onClick={handleActivation}>
      <CategoryMenuHandler className={isMenuOpen ? 'active' : 'inactive'}>
        {'Каталог'}
        <Icon type={'arrowLong'} variant={'down'} />
      </CategoryMenuHandler>
      <CategoryMenuSet className={`ac-menu ac-menu-level-1 ${isMenuOpen ? 'active' : 'inactive'}`} level={1}>
        {renderMenuGrid(catalog)}
      </CategoryMenuSet>
    </CategoryMenuWrapper>
  );
};
