import * as React from 'react';

import { Button, ButtonLink, ButtonText } from './Button.styled';
import { ComponentProps, ButtonTypes, ButtonThemes, ButtonSizes } from './Button.types';
import { theme } from '@common/theme/theme';
import { Icon } from '..';

const ButtonComponent: React.FC<ComponentProps> = ({
  children,
  type,
  onClick,
  form,
  to,
  icon,
  actionIcon,
  buttonType = ButtonTypes.DEFAULT,
  buttonSize = ButtonSizes.DEFAULT,
}) => {
  const buttonThemes: ButtonThemes = {
    [ButtonTypes.DEFAULT]: {
      borderColor: theme.brandBgGrayColor,
      borderColorHover: theme.brandColor,
      background: 'none',
      backgroundHover: 'none',
      color: theme.textColor,
      colorHover: theme.brandColor,
      iconColor: theme.brandColor,
      horizontalPadding: theme.mediumPadding,
      verticalPadding: buttonSize === ButtonSizes.DEFAULT ? theme.smallPadding : '10px',
    },
    [ButtonTypes.BORDERLESS]: {
      borderColor: 'transparent',
      borderColorHover: 'transparent',
      background: 'none',
      backgroundHover: 'none',
      color: theme.textColor,
      colorHover: theme.brandColor,
      iconColor: theme.brandColor,
      horizontalPadding: '0',
      verticalPadding: buttonSize === ButtonSizes.DEFAULT ? theme.smallPadding : '10px',
    },
    [ButtonTypes.PRIMARY]: {
      borderColor: theme.brandColor,
      borderColorHover: theme.brandHoverColor,
      background: theme.brandColor,
      backgroundHover: theme.brandHoverColor,
      color: theme.whiteColor,
      colorHover: theme.whiteColor,
      iconColor: theme.whiteColor,
      horizontalPadding: theme.mediumPadding,
      verticalPadding: buttonSize === ButtonSizes.DEFAULT ? theme.smallPadding : '10px',
    },
  };

  return to ? (
    <ButtonLink to={to} buttonTheme={buttonThemes[buttonType]}>
      {icon && <Icon {...icon} />}
      <ButtonText>{children}</ButtonText>
      {actionIcon && <Icon {...actionIcon} />}
    </ButtonLink>
  ) : (
    <Button onClick={onClick} type={type || 'button'} form={form} buttonTheme={buttonThemes[buttonType]}>
      {icon && <Icon {...icon} />}
      <ButtonText>{children}</ButtonText>
      {actionIcon && <Icon {...actionIcon} />}
    </Button>
  );
};

export default ButtonComponent;
