import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { withFormik, InjectedFormikProps, Field } from 'formik';
import { useLocation } from 'react-router-dom';

import { SearchBoxWrapper, SearchBoxInput, SearchBoxButton } from './SearchBox.styled';
import { SearchBoxProps, SearchBoxFormProps } from './SearchBox.types';
import { Icon } from '@common/components';
import { redirect } from '@common/lib/router/router';
import { getQueryParams } from '@common/lib/helpers/strings';

const SearchBox: React.FC<InjectedFormikProps<SearchBoxProps, SearchBoxFormProps>> = ({
  handleSubmit,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  React.useEffect(() => {
    const searchParams = getQueryParams(location.search);

    setFieldValue('search', searchParams['search'] || '');
  }, [location.search]);

  return (
    <SearchBoxWrapper onSubmit={handleSubmit}>
      <Field name='search'>
        {({ field }: any) => <SearchBoxInput {...field} placeholder={t('general:searchPhrase')} />}
      </Field>
      <SearchBoxButton type='submit'>
        <Icon type={'search'} />
      </SearchBoxButton>
    </SearchBoxWrapper>
  );
};

export default withFormik<SearchBoxProps, SearchBoxFormProps>({
  mapPropsToValues: () => {
    return {
      search: '',
    };
  },
  handleSubmit: (values) => {
    redirect(`/catalog?search=${values.search}`);
  },
})(SearchBox);
