import { Link } from 'react-router-dom';

import styled, { media } from '@common/theme/styled-components';

export const HeaderLinkWrapper = styled.div``;

export const HeaderLinkHandler = styled(Link)`
  position: relative;

  text-decoration: none;

  ${media.tablet`
    display: flex;
    align-items: center;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    & svg {
      width: 15px;
      height: 15px;
    }
  `}

  & svg {
    fill: ${(props) => props.theme.brandAltColor};
  }
`;

export const HeaderLinkLabel = styled.span`
  display: none;

  ${media.tablet`
    display: initial;
    margin-right: 5px;

    font-size: 13px;
  `}
`;

export const HeaderLinkQuantity = styled.div`
  position: absolute;
  top: -5px;
  right: -10px;

  display: flex;
  justify-content: center;

  height: 15px;
  width: 15px;
  line-height: 16px;

  padding: 0 5px;
  border-radius: 100%;

  background: ${(props) => props.theme.brandColor};

  font-size: 12px;

  ${media.tablet`
    top: 8px;
  `}
`;
