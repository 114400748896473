import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { ProductActionTypes, ProductDataInterface } from './types';
import * as actionsCreators from './actions';
import { initialPaginationPageState, initialSearchParamsState } from '@common/lib/pagination/structures';
import { PaginationInterface } from '../../lib/pagination/types';
import { generateSearchHash } from '@common/lib/helpers/strings';
import { productConfig } from './config';

export type ProductState = PaginationInterface<ProductDataInterface>;
export type ProductAction = ActionType<typeof actionsCreators>;

const searchParams = {
  ...initialSearchParamsState,
  ...productConfig.initialSearchParams,
};
export const productInitialState: ProductState = {
  pagination: {
    [generateSearchHash(searchParams)]: initialPaginationPageState,
  },
  searchParams,
  models: {
    active: {
      id: '0',
    },
  },
  selections: {},
  isLoaded: false,
  isLoading: false,
};

export const productReducer: Reducer<ProductState, ProductAction> = (state = productInitialState, action) => {
  const sHash = generateSearchHash(state.searchParams);

  switch (action.type) {
    case ProductActionTypes.LOAD_LIST_REQUEST:
      return (() => {
        const isNewPagination = !state.pagination[sHash];

        return {
          ...state,
          pagination: isNewPagination
            ? {
                ...state.pagination,
                [sHash]: initialPaginationPageState,
              }
            : state.pagination,
          isLoading: true,
        };
      })();

    case ProductActionTypes.LOAD_LIST_SUCCESS:
      return (() => {
        const { result: page } = action.payload;

        return {
          ...state,
          pagination: {
            ...state.pagination,
            [sHash]: {
              ...state.pagination[sHash],
              lastUpdateTime: new Date(),
              pages: {
                ...(state.pagination[sHash] ? state.pagination[sHash].pages : {}),
                [state.pagination[sHash].params.page]: page,
              },
            },
          },
          isLoading: false,
        };
      })();

    case ProductActionTypes.LOAD_LIST_FAILURE:
      return (() => {
        return {
          ...state,
          pagination: {
            ...state.pagination,
            [sHash]: {
              ...state.pagination[sHash],
              lastUpdateTime: new Date(),
              error: action.payload,
            },
          },
          isLoading: false,
        };
      })();

    case ProductActionTypes.LOAD_SELECTION_REQUEST:
      return (() => {
        return {
          ...state,
          selections: {
            ...state.selections,
            [action.payload.type]: {
              isLoaded: false,
              isLoading: true,
              data: [] as number[],
            },
          },
        };
      })();

    case ProductActionTypes.LOAD_SELECTION_SUCCESS:
      return (() => {
        return {
          ...state,
          selections: {
            ...state.selections,
            [action.payload.type]: {
              isLoaded: true,
              isLoading: false,
              data: action.payload.result,
            },
          },
        };
      })();

    case ProductActionTypes.SET_ACTIVE:
      return (() => {
        return {
          ...state,
          models: {
            ...state.models,
            active: {
              ...state.models.active,
              ...action.payload,
            },
          },
        };
      })();

    case ProductActionTypes.SET_PAGINATION:
      return (() => {
        return {
          ...state,
          pagination: {
            ...state.pagination,
            [sHash]: {
              ...state.pagination[sHash],
              params: action.payload,
            },
          },
        };
      })();

    case ProductActionTypes.SET_AGGREGATIONS:
      return (() => {
        return {
          ...state,
          pagination: {
            ...state.pagination,
            [sHash]: {
              ...state.pagination[sHash],
              aggregations: action.payload,
            },
          },
        };
      })();

    case ProductActionTypes.SET_SEARCH_PARAMS:
      return (() => {
        return {
          ...state,
          searchParams: {
            ...state.searchParams,
            ...action.payload,
          },
        };
      })();

    default: {
      return state;
    }
  }
};
