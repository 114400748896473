import { request } from '@common/lib/request/request';
import config from '@common/config';
import { SHIPPING_TYPES, PAYMENT_TYPES } from '@common/store/setting/types';
import { BasketItem } from '@common/store/basket/types';

type OrderMakeRequest = {
  products: BasketItem[];
  clientFirstName: string;
  clientPhoneNumber: string;
  clientEmail: string;
  clientAddress: string;
  comment: string;
  shippingType: SHIPPING_TYPES;
  paymentType: PAYMENT_TYPES;
};

function make(data: OrderMakeRequest) {
  return request({
    method: config.api.methods.post,
    url: config.api.endPoints.order.make(),
    data,
  });
}

type OrderMakeQuickRequest = {
  products: BasketItem[];
  clientFirstName: string;
  clientPhoneNumber: string;
};

function makeQuick(data: OrderMakeQuickRequest) {
  return request({
    method: config.api.methods.post,
    url: config.api.endPoints.order.makeQuick(),
    data,
  });
}

const order = {
  make,
  makeQuick,
};

export default order;
