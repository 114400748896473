import { all, put, select, takeLatest, fork, call } from 'redux-saga/effects';
import * as qs from 'qs';

import { BasketActionTypes, BasketCookie } from './types';

import { setBasketCookie, getBasket } from '@common/lib/cookie/cookie';
import stringHelper from '@common/lib/helpers/strings';
import { basketSelectors } from './selectors';
import { basketActions } from './actions';
import controllers from '@common/controllers';
import notificationHelper from '@common/lib/helpers/notification';
import { ApiPaginationInterface } from '@common/lib/pagination/types';
import { ProductDataInterface } from '../product/types';

function* initBasket() {
  const basket = getBasket();

  if (basket) {
    yield put(basketActions.setUpBasket(stringHelper.decode(basket)));
  }
}

function* watchInitBasket() {
  yield takeLatest(BasketActionTypes.INIT_BASKET, initBasket);
}

function* addToBasket(action: ReturnType<typeof basketActions.addToBasket>) {
  const basket: BasketCookie = yield select(basketSelectors.selectBasketCookie);
  const newBasket = { ...basket };

  if (newBasket[action.payload.id]) {
    newBasket[action.payload.id] = {
      ...newBasket[action.payload.id],
      quantity: newBasket[action.payload.id].quantity + action.payload.quantity,
    };
  } else {
    newBasket[action.payload.id] = {
      id: action.payload.id,
      quantity: action.payload.quantity,
    };
  }

  setBasketCookie(stringHelper.encode(newBasket));

  yield put(basketActions.setUpBasket(newBasket));

  notificationHelper.pushNotification('Товар добавлен в корзину.');
}

function* watchAddToBasket() {
  yield takeLatest(BasketActionTypes.ADD_TO_BASKET, addToBasket);
}

function* updateBasket(action: ReturnType<typeof basketActions.updateBasket>) {
  const basket: BasketCookie = yield select(basketSelectors.selectBasketCookie);
  const newBasket = { ...basket };

  newBasket[action.payload.id] = {
    id: action.payload.id,
    quantity: action.payload.quantity,
  };

  setBasketCookie(stringHelper.encode(newBasket));

  yield put(basketActions.setUpBasket(newBasket));
}

function* watchUpdateBasket() {
  yield takeLatest(BasketActionTypes.UPDATE_BASKET, updateBasket);
}

function* removeFromBasket(action: ReturnType<typeof basketActions.removeFromBasket>) {
  const basket: BasketCookie = yield select(basketSelectors.selectBasketCookie);
  const newBasket = { ...basket };

  if (!newBasket[action.payload.id]) {
    return;
  }

  if (newBasket[action.payload.id].quantity - action.payload.quantity > 0) {
    newBasket[action.payload.id].quantity = newBasket[action.payload.id].quantity - action.payload.quantity;
  } else {
    delete newBasket[action.payload.id];
  }

  setBasketCookie(stringHelper.encode(newBasket));

  yield put(basketActions.setUpBasket(newBasket));
}

function* watchRemoveFromBasket() {
  yield takeLatest(BasketActionTypes.REMOVE_FROM_BASKET, removeFromBasket);
}

function* clearBasket() {
  const newBasket = {};

  setBasketCookie(stringHelper.encode(newBasket));

  yield put(basketActions.setUpBasket(newBasket));
}

function* watchClearBasket() {
  yield takeLatest(BasketActionTypes.CLEAR_BASKET, clearBasket);
}

function* loadBasket() {
  const basketCookie = getBasket();

  if (!basketCookie) {
    return;
  }

  const basket = stringHelper.decode(basketCookie);

  if (!Object.keys(basket).length) {
    return;
  }

  try {
    const pagination: ApiPaginationInterface<ProductDataInterface> = yield call(() =>
      controllers.product.search(qs.stringify({ ids: Object.keys(basket).toString() }))
    );

    yield put(basketActions.loadBasketSuccess(pagination.data));
  } catch (error) {
    yield put(basketActions.loadBasketFailure(error));
  }
}

function* watchLoadBasket() {
  yield takeLatest(BasketActionTypes.LOAD_BASKET_REQUEST, loadBasket);
}

export const basketSagas = {
  initBasket,
  addToBasket,
  updateBasket,
  removeFromBasket,
  clearBasket,
  loadBasket,
};

export function* basketRootSaga() {
  yield all({
    watchInitBasket: fork(watchInitBasket),
    watchAddToBasket: fork(watchAddToBasket),
    watchUpdateBasket: fork(watchUpdateBasket),
    watchRemoveFromBasket: fork(watchRemoveFromBasket),
    watchClearBasket: fork(watchClearBasket),
    watchLoadBasket: fork(watchLoadBasket),
  });
}
