import 'swiper/css/swiper.css';
import styled from '@common/theme/styled-components';

export const Slider = styled.div`
  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;

    transition: ${(props) => props.theme.defaultTransition};

    &.swiper-pagination-bullet-active {
      border: 3px solid ${(props) => props.theme.brandAltColor};

      background: transparent;
    }
  }
`;
