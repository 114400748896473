import styled from '@common/theme/styled-components';

export const MultiValueLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiValueLabelQuantity = styled.div`
  height: 100%;

  border-right: 1px solid ${(props) => props.theme.brandBgGrayColor};
`;
