import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  ProductItemWrapper,
  ProductItemImage,
  ProductItemImgSrc,
  ProductItemTitle,
  ProductItemActionsBox,
  ProductItemPrice,
  ProductItemActions,
  ProductItemBasket,
  ProductItemSmall,
  ProductItemContentSmall,
  ProductItemBodySmall,
  ProductItemImageSmall,
  ProductItemTitleSmall,
  ProductItemActionsSmall,
} from './ProductItem.styled';
import { ComponentProps, PRODUCT_ITEM_TYPES } from './ProductItem.types';
import { Icon } from '..';
import { basketActions } from '@common/store/basket/actions';

export const ProductItem: React.FC<ComponentProps> = ({ item, type = PRODUCT_ITEM_TYPES.DEFAULT }) => {
  const dispatch = useDispatch();

  const addToBasket = React.useCallback(() => {
    dispatch(basketActions.addToBasket(item.getId(), 1));
  }, [item]);

  return (
    <React.Fragment>
      {type === PRODUCT_ITEM_TYPES.SMALL && (
        <ProductItemSmall>
          <ProductItemImageSmall to={item.showInCatalog() ? item.getViewLink() : ''} title={item.getTitle()}>
            <ProductItemImgSrc src={item.getMainImage()} title={item.getTitle()} alt={item.getTitle()} />
          </ProductItemImageSmall>
          <ProductItemContentSmall>
            <ProductItemBodySmall>
              <ProductItemTitleSmall to={item.showInCatalog() ? item.getViewLink() : ''} title={item.getTitle()}>
                {item.getTitle()}
              </ProductItemTitleSmall>
            </ProductItemBodySmall>
            <ProductItemActionsSmall>
              <ProductItemPrice>{item.getPrice()}</ProductItemPrice>
              <ProductItemBasket onClick={addToBasket}>
                <Icon type='basket' />
              </ProductItemBasket>
            </ProductItemActionsSmall>
          </ProductItemContentSmall>
        </ProductItemSmall>
      )}
      {type === PRODUCT_ITEM_TYPES.DEFAULT && (
        <ProductItemWrapper>
          <ProductItemImage to={item.showInCatalog() ? item.getViewLink() : ''} title={item.getTitle()}>
            <ProductItemImgSrc src={item.getMainImage()} title={item.getTitle()} alt={item.getTitle()} />
          </ProductItemImage>
          <ProductItemTitle to={item.showInCatalog() ? item.getViewLink() : ''} title={item.getTitle()}>
            {item.getTitle()}
          </ProductItemTitle>
          <ProductItemActionsBox>
            <ProductItemPrice>{item.getPrice()}</ProductItemPrice>
            <ProductItemActions>
              <ProductItemBasket onClick={addToBasket}>
                <Icon type='basket' />
              </ProductItemBasket>
            </ProductItemActions>
          </ProductItemActionsBox>
        </ProductItemWrapper>
      )}
    </React.Fragment>
  );
};
