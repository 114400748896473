import 'react-datepicker/dist/react-datepicker.css';
import styled from '@common/theme/styled-components';

export const FormDateInput = styled.input`
  width: 100%;

  padding: 10px 12px;
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  & + & {
    margin-top: ${(props) => props.theme.smallPadding};
  }
`;
