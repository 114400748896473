import { request } from '@common/lib/request/request';
import config from '@common/config';

type CallbackCreate = {
  phoneNumber: string;
};

function create(callback: CallbackCreate) {
  return request({
    method: config.api.methods.post,
    url: config.api.endPoints.callback.create(),
    data: callback,
  });
}

const callback = {
  create,
};

export default callback;
