import * as React from 'react';

import { FormField, FormFieldLabel, FormFieldLabelRequired, FormFieldInput } from './FormField.styled';
import { FormFieldProps } from './FormField.types';

const FormFieldComponent: React.FC<FormFieldProps> = ({ label, isReqired = false, children }) => (
  <FormField>
    <FormFieldLabel>
      {label}
      {': '}
      {isReqired && <FormFieldLabelRequired>{'*'}</FormFieldLabelRequired>}
    </FormFieldLabel>
    <FormFieldInput>{children}</FormFieldInput>
  </FormField>
);

export default FormFieldComponent;
