import styled, { media } from '@common/theme/styled-components';

export const Galleria = styled.section`
  background-color: ${(props) => props.theme.whiteColor};
`;

export const GalleriaItem = styled.div`
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${(props) => props.theme.xsmallPadding};

  ${media.mobile`
    height: 300px;
  `}

  ${media.tablet`
    height: 400px;
  `}

  ${media.desktop`
    height: 500px;
  `}
`;

export const GalleriaItemThumb = styled.div`
  height: 75px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${(props) => props.theme.smallPadding};
  padding: ${(props) => props.theme.xsmallPadding};
  border: 1px solid ${(props) => props.theme.brandBgColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  transition: ${(props) => props.theme.defaultTransition};

  ${media.mobile`
    height: 100px;
  `}

  ${media.tablet`
    height: 125px;
  `}

  ${media.desktop`
    height: 150px;

    &:hover {
      border-color: ${(props) => props.theme.brandAltColor};
    }
  `}
`;

export const GalleriaItemSource = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;
