import i18next from 'i18next';

import { ProductSeoProps } from './Product.types';
import seoHelper from '@common/lib/helpers/seo';

export const productSeo = (t: i18next.TFunction, { title, imgUrl }: ProductSeoProps) => {
  return seoHelper.getSeo(t, {
    title: t('product:seoTitle', { title }),
    description: t('product:seoDescription', { title }),
    imgUrl,
  });
};
