import { Link } from 'react-router-dom';
import styled, { media, icon } from '@common/theme/styled-components';

export const ProductSection = styled.section`
  & ~ & {
    margin-top: 15px;
  }
`;

export const ProductSectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.brandBgColor};
`;

export const ProductSectionTitle = styled.div`
  line-height: 1.2em;

  font-size: 24px;
  font-weight: bold;
`;

export const ProductSectionArrowsBox = styled.div`
  display: flex;
`;

export const ProductSectionArrow = styled.div`
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  background: ${(props) => props.theme.brandBgColor};

  cursor: pointer;

  transition: ${(props) => props.theme.defaultTransition};

  &:first-child {
    margin-right: 5px;
  }

  ${icon({ size: '10px' })}

  & svg {
    transition: ${(props) => props.theme.defaultTransition};
  }

  &:hover {
    ${media.desktop`
      background: ${(props) => props.theme.brandColor};


      ${(props) => icon({ color: props.theme.whiteColor })}
    `}
  }
`;

export const ProductSectionShowMore = styled(Link)`
  color: ${(props) => props.theme.brandColor};

  &:hover {
    color: ${(props) => props.theme.brandAltColor};
    text-decoration: none;
  }
`;

export const ProductSectionBody = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
`;

export const ProductSectionFooter = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${(props) => props.theme.smallPadding};
`;
