import * as React from 'react';
import * as Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import { FieldProps } from 'formik';

import { FormRange } from './FormRange.styled';
import { FormRangeProps } from './FormRange.types';

const Range = Slider.createSliderWithTooltip(Slider.Range);

const handle = ({ value, dragging, index, ...restProps }: any) => (
  <Tooltip prefixCls={'rc-slider-tooltip'} overlay={value} visible={dragging} placement={'top'} key={index}>
    <Slider.Handle value={value} {...restProps} />
  </Tooltip>
);

const FormRangeComponent: React.FC<FormRangeProps & FieldProps> = ({ field, form, min, max }) => {
  const defaultMin = Math.floor(min);
  const defaultMax = Math.ceil(max);
  const defaultValue = field.value ? field.value.split(',') : [defaultMin, defaultMax];

  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [field.value, min, max]);

  const handleChange = React.useCallback(
    (value: number[]) => {
      form.setFieldValue(field.name, value.toString());
    },
    [field.name, form.setFieldValue]
  );

  return (
    <FormRange>
      <Range
        min={defaultMin}
        max={defaultMax}
        value={value}
        tipFormatter={(value: number) => `${value} р.`}
        onChange={setValue}
        onAfterChange={handleChange}
        handle={handle}
        marks={{ [defaultMin]: `${defaultMin} р.`, [defaultMax]: `${defaultMax} р.` }}
      />
    </FormRange>
  );
};

export default FormRangeComponent;
