import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import { Page, PageTitle, PageBody } from './Page.styled';
import { ComponentProps } from './Page.types';
import { Container } from '..';

const PageComponent: React.FC<ComponentProps> = ({ title, breadcrumbs, withContainer = true, seo, children }) => {
  return (
    <Page withContainer={withContainer}>
      {seo && <Helmet {...seo} />}
      <BreadcrumbsItem to='/'>{'Главная'}</BreadcrumbsItem>
      {breadcrumbs &&
        breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbsItem key={breadcrumb.title} to={breadcrumb.link}>
            {breadcrumb.title}
          </BreadcrumbsItem>
        ))}
      {title && <PageTitle>{title}</PageTitle>}
      <PageBody>{withContainer ? <Container>{children}</Container> : children}</PageBody>
    </Page>
  );
};

export default PageComponent;
