import styled, { css } from '@common/theme/styled-components';

export const FormNum = styled.div`
  display: flex;
`;

const formNumButton = css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 35px;

  border: 1px solid ${(props) => props.theme.brandBgGrayColor};

  font-weight: bold;
  cursor: pointer;
  user-select: none;
`;

export const FormNumInc = styled.div`
  ${formNumButton};

  border-left: 0;
  border-radius: 0 20px 20px 0;
`;

export const FormNumDec = styled.div`
  ${formNumButton};

  border-right: 0;
  border-radius: 20px 0 0 20px;
`;

export const FormNumInput = styled.input`
  width: 40px;
  height: 35px;

  border: 1px solid ${(props) => props.theme.brandBgGrayColor};

  text-align: center;
`;
