import { call, put, select, takeLatest, fork, takeEvery, all } from 'redux-saga/effects';
import * as qs from 'qs';

import { ProductActionTypes, ProductDataInterface, ProductSearchParams, PRODUCT_SELECTIONS } from './types';
import controllers from '@common/controllers';
import { productSelectors } from './selectors';
import { productActions } from './actions';
import { ApiPaginationInterface } from '@common/lib/pagination/types';
import { parsePaginationParams } from '@common/lib/pagination/structures';

function* loadList() {
  try {
    const searchParams: ProductSearchParams = yield select(productSelectors.selectSearchParams);
    const { data, aggregations, ...params }: ApiPaginationInterface<ProductDataInterface> = yield call(() =>
      controllers.product.search(qs.stringify(searchParams))
    );

    yield put(productActions.setPaginationParams(parsePaginationParams(params)));
    yield put(productActions.setAggregations(aggregations));
    yield put(productActions.loadListSuccess(data));
  } catch (error) {
    yield put(productActions.loadListFailure(error));
  }
}

function* watchLoadList() {
  yield takeLatest(ProductActionTypes.LOAD_LIST_REQUEST, loadList);
}

function* loadSelection(action: ReturnType<typeof productActions.loadSelectionRequest>) {
  try {
    const selection: ProductDataInterface[] = yield call(() =>
      controllers.product.select({ type: action.payload.type })
    );
    yield put(productActions.loadSelectionSuccess(action.payload.type, selection));
  } catch (error) {
    yield put(productActions.loadSelectionFailure(error));
  }
}

function* watchLoadSelection() {
  yield takeEvery(ProductActionTypes.LOAD_SELECTION_REQUEST, loadSelection);
}

function* setActiveItem() {
  const active: ProductDataInterface = yield select(productSelectors.selectActive);
  const models: {
    [key: string]: ProductDataInterface;
  } = yield select(productSelectors.selectModels);

  if (!(models[active.id] && models[active.id].isLoaded)) {
    yield put(productActions.loadItemRequest(active.id));
  }
}

function* watchSetActiveItem() {
  yield takeLatest(ProductActionTypes.SET_ACTIVE, setActiveItem);
}

function* loadItem() {
  const active: ProductDataInterface = yield select(productSelectors.selectActive);

  try {
    const productData: ProductDataInterface = yield call(() => controllers.product.get(active.id));
    const relatedProductsData: ProductDataInterface[] = yield call(() =>
      controllers.product.select({ type: PRODUCT_SELECTIONS.RELATED, ids: [active.id] })
    );

    yield put(
      productActions.loadItemSuccess(active.id, {
        ...productData,
        selections: {
          [PRODUCT_SELECTIONS.RELATED]: relatedProductsData,
        },
      })
    );
  } catch (error) {
    yield put(productActions.loadItemFailure(active.id, error.data));
  }
}

function* watchLoadItem() {
  yield takeLatest(ProductActionTypes.LOAD_ITEM_REQUEST, loadItem);
}

export const productSagas = {
  loadList,
  loadSelection,
  setActiveItem,
  loadItem,
};

export function* productRootSaga() {
  yield all({
    watchLoadList: fork(watchLoadList),
    watchLoadSelection: fork(watchLoadSelection),
    watchSetActiveItem: fork(watchSetActiveItem),
    watchLoadItem: fork(watchLoadItem),
  });
}
