import { addHours, addMinutes, getHours, getMinutes, setHours, setMinutes } from 'date-fns/fp';

function datePlusTime(dateString: Date | null, hours: number) {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  const h = Math.floor(hours);
  const m = (hours - h) * 60;

  return addMinutes(m, addHours(h, date));
}

function getDate(dateString: Date | null) {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  return setMinutes(0, setHours(0, date));
}

function getTime(dateString: Date | null) {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  const h = getHours(date);
  const m = getMinutes(date);

  return h + parseFloat((m / 60).toFixed(1));
}

const dateHelper = {
  datePlusTime,
  getDate,
  getTime,
};

export default dateHelper;
