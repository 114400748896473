import styled from '@common/theme/styled-components';

export const FormRadio = styled.div``;

export const FormRadioLabel = styled.label<{ isChecked: boolean }>`
  display: flex;
  align-items: center;

  position: relative;

  padding-left: 27.5px;

  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    left: 0;
    top: 0;

    width: 18px;
    height: 18px;
    background: ${(props) => props.theme.whiteColor};
    border: 1px solid ${(props) => (props.isChecked ? props.theme.brandAltColor : props.theme.brandBgGrayColor)};
    border-radius: 100%;
  }

  &:hover {
    &::before {
      border-color: ${(props) => props.theme.brandAltColor};
    }
  }

  &::after {
    display: none;

    content: '';

    position: absolute;
    left: 3px;
    top: 3px;
    z-index: 1;

    width: 14px;
    height: 14px;

    border-radius: 100%;

    background: ${(props) => props.theme.brandAltColor};

    ${(props) =>
      props.isChecked &&
      `
      display: block;
    `}
  }
`;

export const FormRadioHighlight = styled.div`
  color: ${(props) => props.theme.brandAltColor};
  font-weight: bold;

  &::before {
    content: '-';

    margin: 0 5px;
  }
`;

export const FormRadioDescription = styled.div`
  margin-left: 28px;

  font-size: ${(props) => props.theme.xsmallFontSize};
`;

export const FormRadioCount = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  font-weight: bold;
`;
