import { Link } from 'react-router-dom';
import styled, { css } from '@common/theme/styled-components';

export const Menu = styled.div``;

export const MenuSet = styled('nav')<{
  level: number;
}>`
  .ac-menu-link-level-${(props) => props.level}, .ac-menu-title-level-${(props) => props.level} {
    padding-left: ${(props) => props.level * 10}px;
  }

  ${(props) =>
    props.level > 1 &&
    `
    display: none;
  `}

  &.active {
    display: block;
  }
`;

export const MenuItem = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.brandBgColor};

  &:last-child {
    border-bottom: 0;
  }
`;

const menuItemStyles = css`
  display: block;

  padding: 8px 10px;

  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  text-decoration: none;
`;

export const MenuItemLink = styled(Link)`
  ${menuItemStyles};

  &.ac-menu-title {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const MenuItemLabel = styled.span`
  ${menuItemStyles};

  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    width: 12px;
    height: 12px;
  }
`;
