import * as React from 'react';

import { NavigationWrapper, NavigationItem } from './Navigation.styled';

export const Navigation: React.FC = () => (
  <NavigationWrapper>
    <NavigationItem to={'/'}>{'Главная'}</NavigationItem>
    <NavigationItem to={'/contacts'}>{'Контакты'}</NavigationItem>
  </NavigationWrapper>
);
