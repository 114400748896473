export enum BasketActionTypes {
  INIT_BASKET = '@@basket/INIT_BASKET',
  SET_UP_BASKET = '@@basket/SET_UP_BASKET',
  ADD_TO_BASKET = '@@basket/ADD_TO_BASKET',
  UPDATE_BASKET = '@@basket/UPDATE_BASKET',
  REMOVE_FROM_BASKET = '@@basket/REMOVE_FROM_BASKET',
  CLEAR_BASKET = '@@basket/CLEAR_BASKET',

  LOAD_BASKET_REQUEST = '@@basket/LOAD_BASKET_REQUEST',
  LOAD_BASKET_SUCCESS = '@@basket/LOAD_BASKET_SUCCESS',
  LOAD_BASKET_FAILURE = '@@basket/LOAD_BASKET_FAILURE',
}

export type BasketItem = {
  id: string;
  quantity: number;
};

export type BasketCookie = {
  [id: string]: BasketItem;
};

export type Basket = {
  cookie: BasketCookie;
  isLoaded: boolean;
  isLoading: boolean;
};
