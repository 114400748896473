import { ImageDataInterface } from '../image/types';
import { ProductSearchParams } from './types';
import config from '@common/config';

export const defaultMainImage: ImageDataInterface = {
  id: '0',
  isMain: true,
  path: config.api.defaultImageSrc,
};

export const productConfig = {
  initialSearchParams: {
    search: '',
    page: '1',
    pageSize: '15',
  } as ProductSearchParams,
};
