import styled from '@common/theme/styled-components';

export const FormRadioGroup = styled.div`
  & > * {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
`;
