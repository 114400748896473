import styled, { media } from '../../theme/styled-components';

export const FormField = styled.label`
  display: block;

  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.desktop`
    display: flex;
  `}
`;

export const FormFieldLabel = styled.div`
  max-width: 100%;
  width: 100%;

  margin-bottom: 5px;

  ${media.desktop`
    max-width: 250px;
  `}
`;

export const FormFieldLabelRequired = styled.span`
  color: ${(props) => props.theme.brandRedColor};
`;

export const FormFieldInput = styled.div`
  max-width: 100%;
  width: 100%;
`;
