import * as React from 'react';

import { FormError } from './FormError.styled';
import { ComponentProps } from './FormError.types';

const FormErrorComponent: React.FC<ComponentProps> = ({ error }) => {
  return error ? <FormError>{error}</FormError> : null;
};

export default FormErrorComponent;
