export const categoriesConfig = {
  list: [
    {
      title: 'Продажа компьютеров',
      description: 'Мы предоставляем широкий ассортимент компьютерной техники для любых целей',
      link: '/catalog/notebook',
      image: require('@assets/images/main-slider/notebook.png'),
    },
    {
      title: 'Продажа мотоблоков',
      description: 'Мы предоставляем широкий ассортимент мото-техники для вашего хозяйства',
      link: '/catalog/walk-behind-tractor',
      image: require('@assets/images/main-slider/walk-behind-tractor.png'),
    },
    {
      title: 'Ремонт компьютерной техники',
      description: 'Мы предоставляем услуги по ремонту и обслуживанию компьютерной техники, заправке картриджей',
      link: '',
      image: require('@assets/images/main-slider/repair.png'),
    },
    {
      title: 'Установка Видеонаблюдения',
      description: 'Мы занимаемся установкой и настройкой систем видеонаблюдения под ключ',
      link: '',
      image: require('@assets/images/main-slider/camera.png'),
    },
    {
      title: 'Установка Ступникового TV',
      description: 'Мы занимаемся установкой и настройкой спутникового TV под ключ',
      link: '',
      image: require('@assets/images/main-slider/satellite.png'),
    },
  ],
};
