import { toPrice } from '@common/lib/helpers/strings';
import { Model } from '../model';
import { MODELS } from '../types';
import {
  ProductDataInterface,
  SimplePayload,
  SelectPayload,
  ImagePayload,
  ATTRIBUTES,
  PRODUCT_SELECTIONS,
} from './types';

export class Product extends Model<ProductDataInterface> {
  constructor(modelName: string, data?: ProductDataInterface) {
    super(modelName, {
      ...data,
    });
  }

  getViewLink() {
    return `/catalog/${this.getCategory().system_name}/${this.getId()}`;
  }

  getCategoryLink() {
    return `/catalog/${this.getCategory().system_name}`;
  }

  getAttr(attrSystemName: string) {
    const attr = this.model.custom_attributes.find((attr) => attr.system_name === attrSystemName);

    return attr ? attr.payload.value : undefined;
  }

  getId() {
    return this.model.id;
  }

  getCategory() {
    return this.getAttr(ATTRIBUTES.CATEGORY) as SelectPayload;
  }

  getBrand() {
    return this.getAttr(ATTRIBUTES.BRAND) as SelectPayload;
  }

  getTitle() {
    return this.getAttr(ATTRIBUTES.TITLE) as SimplePayload;
  }

  getDescription() {
    return this.getAttr(ATTRIBUTES.DESCRIPTION) as SimplePayload;
  }

  getPriceAttr(quantity = 1) {
    const priceAttr = this.getAttr(ATTRIBUTES.PRICE) as SimplePayload;

    return parseFloat(priceAttr.replace(' ', '')) * quantity;
  }

  getPrice(quantity = 1) {
    return this.showPrice() ? toPrice(this.getPriceAttr(quantity)) : 'Цена по запросу';
  }

  getShowPriceAttr() {
    return this.getAttr(ATTRIBUTES.SHOW_PRICE) as SimplePayload;
  }

  showPrice() {
    return this.getShowPriceAttr() === 'on';
  }

  getImages() {
    return this.getAttr('images') as ImagePayload;
  }

  getMainImage = () => {
    const images = this.getAttr('images');

    let mainImage = undefined;
    if (Array.isArray(images)) {
      mainImage = images.find((image) => image.is_general) || images[0];
      mainImage = mainImage && mainImage.path;
    }

    return mainImage || require('../../../assets/images/bg/default-product.png');
  };

  getRelated() {
    if (this.model.selections && this.model.selections[PRODUCT_SELECTIONS.RELATED]) {
      return this.model.selections[PRODUCT_SELECTIONS.RELATED].map((relatedProduct) => productModel(relatedProduct));
    }

    return [];
  }

  showInCatalog() {
    return true;
  }

  isLoaded() {
    return this.model.isLoaded || false;
  }

  toDataLayer() {
    return {
      id: this.getId(),
      name: this.getTitle(),
      brand: this.getBrand(),
      price: this.getPriceAttr(),
    };
  }
}

export function productModel(data?: ProductDataInterface) {
  return new Product(MODELS.PRODUCTS, data);
}
