import * as React from 'react';

import { Map, MapFrame } from './Map.styled';

const MapComponent: React.FC = () => {
  return (
    <Map>
      <MapFrame
        src='https://yandex.ru/map-widget/v1/?um=constructor%3A621fd40f66b1728a6552123ae540c9f586fbd05c79e6d22d21dabbfb0fdd9e7a&amp;source=constructor'
        width='100%'
        height='400'
      ></MapFrame>
    </Map>
  );
};

export default MapComponent;
