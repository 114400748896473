import i18next from 'i18next';

import seoHelper from '@common/lib/helpers/seo';
import { ListingSeoProps } from './Listing.types';

export const listingSeo = (t: i18next.TFunction, { title }: ListingSeoProps) => {
  return seoHelper.getSeo(t, {
    title: t('listing:seoTitle', { title }),
    description: t('listing:seoDescription', { title }),
  });
};
