import * as React from 'react';

import { Icons, ComponentProps } from '@common/components/Icon/Icon.types';

import { ReactComponent as Accept } from '@common/components/Icon/__svg__/Accept.svg';
import { ReactComponent as ArrowDown } from '@common/components/Icon/__svg__/ArrowDown.svg';
import { ReactComponent as ArrowLeft } from '@common/components/Icon/__svg__/ArrowLeft.svg';
import { ReactComponent as ArrowLongDown } from '@common/components/Icon/__svg__/ArrowLongDown.svg';
import { ReactComponent as ArrowLongLeft } from '@common/components/Icon/__svg__/ArrowLongLeft.svg';
import { ReactComponent as ArrowLongRight } from '@common/components/Icon/__svg__/ArrowLongRight.svg';
import { ReactComponent as ArrowLongUp } from '@common/components/Icon/__svg__/ArrowLongUp.svg';
import { ReactComponent as ArrowRight } from '@common/components/Icon/__svg__/ArrowRight.svg';
import { ReactComponent as ArrowUp } from '@common/components/Icon/__svg__/ArrowUp.svg';
import { ReactComponent as Balloon } from '@common/components/Icon/__svg__/Balloon.svg';
import { ReactComponent as Basket } from '@common/components/Icon/__svg__/Basket.svg';
import { ReactComponent as Close } from '@common/components/Icon/__svg__/Close.svg';
import { ReactComponent as Filter } from '@common/components/Icon/__svg__/Filter.svg';
import { ReactComponent as CreditCard } from '@common/components/Icon/__svg__/CreditCard.svg';
import { ReactComponent as DeliveryTruck } from '@common/components/Icon/__svg__/DeliveryTruck.svg';
import { ReactComponent as Instagram } from '@common/components/Icon/__svg__/Instagram.svg';
import { ReactComponent as Location } from '@common/components/Icon/__svg__/Location.svg';
import { ReactComponent as Logo } from '@common/components/Icon/__svg__/Logo.svg';
import { ReactComponent as Logout } from '@common/components/Icon/__svg__/Logout.svg';
import { ReactComponent as Mail } from '@common/components/Icon/__svg__/Mail.svg';
import { ReactComponent as Menu } from '@common/components/Icon/__svg__/Menu.svg';
import { ReactComponent as Phone } from '@common/components/Icon/__svg__/Phone.svg';
import { ReactComponent as PhoneThin } from '@common/components/Icon/__svg__/PhoneThin.svg';
import { ReactComponent as Question } from '@common/components/Icon/__svg__/Question.svg';
import { ReactComponent as Search } from '@common/components/Icon/__svg__/Search.svg';
import { ReactComponent as SmartPhone } from '@common/components/Icon/__svg__/SmartPhone.svg';
import { ReactComponent as Supermarket } from '@common/components/Icon/__svg__/Supermarket.svg';
import { ReactComponent as Telegram } from '@common/components/Icon/__svg__/Telegram.svg';
import { ReactComponent as TeleMarketer } from '@common/components/Icon/__svg__/TeleMarketer.svg';
import { ReactComponent as Time } from '@common/components/Icon/__svg__/Time.svg';
import { ReactComponent as Truck } from '@common/components/Icon/__svg__/Truck.svg';
import { ReactComponent as User } from '@common/components/Icon/__svg__/User.svg';
import { ReactComponent as Viber } from '@common/components/Icon/__svg__/Viber.svg';

const icons: Icons = {
  accept: { default: <Accept /> },
  arrow: { right: <ArrowRight />, down: <ArrowDown />, up: <ArrowUp />, left: <ArrowLeft /> },
  arrowLong: { right: <ArrowLongRight />, down: <ArrowLongDown />, up: <ArrowLongUp />, left: <ArrowLongLeft /> },
  balloon: { default: <Balloon /> },
  basket: { default: <Basket /> },
  close: { default: <Close /> },
  creditCard: { default: <CreditCard /> },
  deliveryTruck: { default: <DeliveryTruck /> },
  filter: { default: <Filter /> },
  instagram: { default: <Instagram /> },
  location: { default: <Location /> },
  logo: { default: <Logo /> },
  logout: { default: <Logout /> },
  mail: { default: <Mail /> },
  menu: { default: <Menu /> },
  phone: { default: <Phone />, thin: <PhoneThin />, smart: <SmartPhone /> },
  question: { default: <Question /> },
  search: { default: <Search /> },
  supermarket: { default: <Supermarket /> },
  telegram: { default: <Telegram /> },
  telemarket: { default: <TeleMarketer /> },
  time: { default: <Time /> },
  truck: { default: <Truck /> },
  user: { default: <User /> },
  viber: { default: <Viber /> },
};

const IconComponent: React.FC<ComponentProps> = ({ type, variant = 'default' }) => {
  return (icons[type] && icons[type][variant]) || <i data-type={type} data-variant={variant} />;
};

export default IconComponent;
