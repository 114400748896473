import { generateNormalizer } from '@common/lib/pagination/store';
import { generateSearchHash } from '../../lib/helpers/strings';
import { initialPaginationPageState } from '../../lib/pagination/structures';
import { State } from '../reducer';
import { MODELS } from '../types';
import { productSchema } from './schema';
import { ProductDataInterface } from './types';

const { denormalizeItem, denormalizeList } = generateNormalizer<ProductDataInterface>(productSchema);

function selectIsLoading(state: State) {
  return state.product.isLoading;
}

function selectIsLoaded(state: State) {
  return state.product.isLoaded;
}

function selectModels(state: State) {
  return state.model[MODELS.PRODUCTS];
}

function selectCurrentModel(state: State) {
  const active = selectActive(state);
  const activeModel = denormalizeItem(active.id, state.model);

  return (
    activeModel ||
    ({
      isLoaded: false,
      isLoading: false,
      error: null,
    } as ProductDataInterface)
  );
}

function selectCurrentPageModels(state: State) {
  return denormalizeList(selectCurrentPageData(state), state.model);
}

function selectSearchParams(state: State) {
  return state.product.searchParams;
}

function selectPaginationPage(state: State) {
  const paginationPage = state.product.pagination[generateSearchHash(selectSearchParams(state))];

  return paginationPage ? paginationPage : initialPaginationPageState;
}

function selectCurrentPageParams(state: State) {
  return selectPaginationPage(state).params;
}

function selectCurrentPageNumber(state: State) {
  return selectPaginationPage(state).params.page;
}

function selectCurrentPageAggregations(state: State) {
  return selectPaginationPage(state).aggregations;
}

function selectCurrentPageData(state: State) {
  const currentPageData = selectPaginationPage(state).pages[selectCurrentPageNumber(state)];

  return currentPageData ? currentPageData : [];
}

const selectIsCurrentPageLoaded = (state: State) => {
  const pagination = state.product.pagination[generateSearchHash(selectSearchParams(state))];

  if (!pagination) {
    return false;
  }

  const page = selectPaginationPage(state).pages[selectCurrentPageNumber(state)];

  if (!page) {
    return false;
  }

  return true;
};

function selectActive(state: State) {
  return state.product.models.active;
}

function selectSelection(state: State, type: string) {
  const selection = state.product.selections[type];

  if (!selection) {
    return {
      isLoaded: false,
      isLoading: true,
      data: [],
    };
  }

  return {
    ...selection,
    data: denormalizeList(selection.data, state.model),
  };
}

export const productSelectors = {
  selectIsLoaded,
  selectIsLoading,
  selectModels,
  selectCurrentPageModels,
  selectCurrentModel,
  selectSearchParams,
  selectPaginationPage,
  selectCurrentPageParams,
  selectCurrentPageNumber,
  selectCurrentPageAggregations,
  selectCurrentPageData,
  selectIsCurrentPageLoaded,
  selectActive,
  selectSelection,
};
