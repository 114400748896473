import * as React from 'react';
import { components } from 'react-select';

import { MultiValueLabel, MultiValueLabelQuantity } from './MultiValueLabel.styled';
import { ComponentProps } from './MultiValueLabel.types';
import QuantityInput from '../QuantityInput/QuantityInput';

const MultiValueLabelComponent = (props: ComponentProps) => {
  return (
    <MultiValueLabel>
      <MultiValueLabelQuantity>
        <QuantityInput.component
          value={props.data.quantity}
          onChange={(quantity) => props.selectProps.onChangeQuantity(props.data.value, quantity)}
        />
      </MultiValueLabelQuantity>
      <components.MultiValueLabel {...props} />
    </MultiValueLabel>
  );
};

export default {
  component: MultiValueLabelComponent,
};
