import * as React from 'react';

import { SliderArrow } from './SliderArrow.styled';
import { ComponentProps } from './SliderArrow.types';
import { Icon } from '@common/components';

const Component: React.FC<ComponentProps> = ({ className, orientation }) => (
  <SliderArrow className={className}>
    <Icon type='arrow' variant={orientation === 'right' ? 'right' : 'left'} />
  </SliderArrow>
);

export default Component;
