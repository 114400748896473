import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Product, ProductInfo, ProductGalleria, ProductProfile } from './Product.styled';
import { ProductRouteParams, ProductPreloadProps } from './Product.types';
import { productSeo } from './Product.seo';
import Galleria from './Galleria/Galleria';
import Profile from './Profile/Profile';
import { Page, Loader, ProductSection, ProductItem } from '@common/components';
import { productModel } from '@common/store/product/model';
import { basketActions } from '@common/store/basket/actions';
import { productSelectors } from '@common/store/product/selectors';
import { productActions } from '@common/store/product/actions';
import { productSagas } from '@common/store/product/sagas';
import { useParams } from 'react-router';
import { uiSelectors } from '@common/store/ui/selectors';
import catalogHelper from '@common/lib/helpers/catalog';

const ProductComponent: React.FC = () => {
  const { t } = useTranslation();

  const { productId: id, categorySystemName } = useParams<ProductRouteParams>();

  const dispatch = useDispatch();

  const product = useSelector(productSelectors.selectCurrentModel);
  const catalog = useSelector(uiSelectors.selectCatalog);

  const category = React.useMemo(() => {
    return catalogHelper.getCategory({ catalog, systemName: categorySystemName });
  }, [catalog, categorySystemName]);

  React.useEffect(() => {
    dispatch(productActions.setActive(id));
  }, [id]);

  const addToBasket = React.useCallback(() => {
    dispatch(basketActions.addToBasket(productModel(product).getId(), 1));
  }, [product]);

  return (
    <Loader
      isLoaded={product.isLoaded}
      isLoading={product.isLoading}
      errorNetwork={product.error}
      render={() => (
        <Page
          seo={productSeo(t, {
            title: productModel(product).getTitle(),
            imgUrl: productModel(product).getMainImage(),
          })}
          breadcrumbs={[
            {
              title: t('listing:pageTitle'),
              link: '/catalog',
            },
            {
              title: category.title,
              link: productModel(product).getCategoryLink(),
            },
            {
              title: productModel(product).getTitle(),
              link: productModel(product).getViewLink(),
            },
          ]}
        >
          <Product>
            <ProductInfo>
              <ProductGalleria>
                <Galleria.component product={productModel(product)} />
              </ProductGalleria>
              <ProductProfile>
                <Profile.component product={productModel(product)} addToBasket={addToBasket} />
              </ProductProfile>
            </ProductInfo>
            {productModel(product).getRelated().length > 0 && (
              <ProductSection title='Похожие товары' isSlider={true}>
                {productModel(product)
                  .getRelated()
                  .map((item) => (
                    <ProductItem key={item.getId()} item={item} />
                  ))}
              </ProductSection>
            )}
          </Product>
        </Page>
      )}
    />
  );
};

const preload = ({
  match: {
    params: { productId: id },
  },
  dispatch,
}: ProductPreloadProps) => {
  dispatch(productActions.setActive(id));

  return [productSagas.loadItem];
};

export default {
  component: ProductComponent,
  preload,
};
