import * as React from 'react';
import { FieldProps } from 'formik';
import { getDayOfYear, getHours } from 'date-fns/fp';

import { FormTime, FormTimeSeparator, FormTimeSelect, FormTimeOption } from './FormTime.styled';
import { FormTimeProps, TimeOption, GetTimeSlotsProps } from './FormTime.types';

const FormTimeComponent: React.FC<FieldProps & FormTimeProps> = ({
  field,
  form,
  date,
  minH = 0,
  maxH = 24,
  offsetH = 0,
}) => {
  const [fromTimeSlot, setFromTimeSlot] = React.useState(field.value.from);
  const [toTimeSlot, setToTimeSlot] = React.useState(field.value.to);

  const getTimeSlots = React.useCallback(({ from, to }: GetTimeSlotsProps) => {
    const timeSlots: TimeOption[] = [];

    for (let h = from; h <= to; h += 0.5) {
      const hLabel = h < 10 ? `0${Math.floor(h)}` : Math.floor(h);
      const mLabel = Math.floor(h) !== Math.round(h) ? '30' : '00';

      timeSlots.push({ value: h, label: `${hLabel}:${mLabel}` });
    }

    return timeSlots;
  }, []);

  const fromTimeSlots = React.useMemo(() => {
    return getTimeSlots({ from: minH, to: maxH - offsetH });
  }, [minH, maxH, offsetH, getTimeSlots]);

  const toTimeSlots = React.useMemo(() => {
    return getTimeSlots({ from: fromTimeSlot + offsetH, to: maxH });
  }, [maxH, offsetH, fromTimeSlot, getTimeSlots]);

  const handleChangeFromTimeSlot = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newFromTimeSlot = parseFloat(event.target.value);

      if (!toTimeSlot || toTimeSlot - newFromTimeSlot < offsetH) {
        setToTimeSlot(newFromTimeSlot + offsetH);
      }

      setFromTimeSlot(parseFloat(event.target.value));
    },
    [offsetH, toTimeSlot, setToTimeSlot, setFromTimeSlot]
  );

  const handleChangeToTimeSlot = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setToTimeSlot(parseFloat(event.target.value));
    },
    [setToTimeSlot]
  );

  React.useEffect(() => {
    form.setFieldValue(field.name, { from: fromTimeSlot, to: toTimeSlot });
  }, [fromTimeSlot, toTimeSlot]);

  React.useEffect(() => {
    if (date) {
      const selectedDay = getDayOfYear(new Date(date));
      const currentDay = getDayOfYear(new Date());
      const currentHour = getHours(new Date());

      if (selectedDay === currentDay) {
        form.setFieldValue(field.name, { from: currentHour + 1, to: currentHour + 1 + offsetH });
        setFromTimeSlot(currentHour + 1);
        setToTimeSlot(currentHour + 1 + offsetH);
      }
    }
  }, [date]);

  return (
    <FormTime>
      <FormTimeSelect
        value={fromTimeSlot !== null ? fromTimeSlot : ''}
        onChange={handleChangeFromTimeSlot}
        isPlaceholder={fromTimeSlot === null}
      >
        <FormTimeOption value={''} disabled hidden>
          {'От'}
        </FormTimeOption>
        {fromTimeSlots.map((fromTimeSlot) => (
          <FormTimeOption key={fromTimeSlot.value} value={fromTimeSlot.value}>
            {fromTimeSlot.label}
          </FormTimeOption>
        ))}
      </FormTimeSelect>
      <FormTimeSeparator>{'-'}</FormTimeSeparator>
      <FormTimeSelect
        value={toTimeSlot !== null ? toTimeSlot : ''}
        onChange={handleChangeToTimeSlot}
        isPlaceholder={toTimeSlot === null}
      >
        <FormTimeOption value={''} disabled hidden>
          {'До'}
        </FormTimeOption>
        {toTimeSlots.map((toTimeSlot) => (
          <FormTimeOption key={toTimeSlot.value} value={toTimeSlot.value}>
            {toTimeSlot.label}
          </FormTimeOption>
        ))}
      </FormTimeSelect>
    </FormTime>
  );
};

export default FormTimeComponent;
