import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import {
  Listing,
  ListingSidebar,
  ListingBody,
  ListingFiltersHandler,
  ListingTools,
  ListingGrid,
  ListingNotFound,
  ListingNotFoundLabel,
} from './Listing.styled';
import { ListingPreloadProps, ListingRouteParams } from './Listing.types';
import { listingSeo } from './Listing.seo';
import Filters from './Filters/Filters';
import Grid from './Grid/Grid';
import Tools from './Tools/Tools';
import { Page, Loader, Button, Icon, Modal } from '@common/components';
import { getQueryParams, updateQueryParams } from '@common/lib/helpers/strings';
import { productModel } from '@common/store/product/model';
import { uiSelectors } from '@common/store/ui/selectors';
import { uiActions } from '@common/store/ui/actions';
import { productSelectors } from '@common/store/product/selectors';
import { productActions } from '@common/store/product/actions';
import { productConfig } from '@common/store/product/config';
import { ProductSearchParams } from '@common/store/product/types';
import { productSagas } from '@common/store/product/sagas';
import catalogHelper from '@common/lib/helpers/catalog';

const ListingComponent: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { categorySystemName } = useParams<ListingRouteParams>();

  const models = useSelector(productSelectors.selectCurrentPageModels);
  const pagination = useSelector(productSelectors.selectCurrentPageParams);
  const aggregations = useSelector(productSelectors.selectCurrentPageAggregations);
  const searchParams = useSelector(productSelectors.selectSearchParams);
  const isLoaded = useSelector(productSelectors.selectIsCurrentPageLoaded);
  const isLoading = useSelector(productSelectors.selectIsLoading);
  const isListingMenuOpen = useSelector(uiSelectors.selectIsListingMenuOpen);
  const catalog = useSelector(uiSelectors.selectCatalog);

  const category = React.useMemo(() => {
    return catalogHelper.getCategory({ catalog, systemName: categorySystemName });
  }, [catalog, categorySystemName]);
  const urlParams = React.useMemo(() => {
    return {
      f_category_id: category.id,
    };
  }, [category]);
  const [isHistoryUpdated, setIsHistoryUpdated] = React.useState(false);

  React.useEffect(() => {
    return () => {
      dispatch(productActions.setSearchParams(productConfig.initialSearchParams));
    };
  }, []);

  React.useEffect(() => {
    if (!isHistoryUpdated) {
      handleChangeSearchParams({
        ...productConfig.initialSearchParams,
        ...urlParams,
        ...getQueryParams(location.search),
      });
    } else {
      setIsHistoryUpdated(false);
    }
  }, [location]);

  React.useEffect(() => {
    if (!isLoaded) {
      dispatch(productActions.loadListRequest());
    }
  }, [isLoaded]);

  const handleChangeSearchParams = React.useCallback(
    (searchParams: ProductSearchParams) => {
      const actualSearchParams = searchParams.page
        ? searchParams
        : {
            ...searchParams,
            page: '1',
          };

      dispatch(productActions.setSearchParams(actualSearchParams));

      setIsHistoryUpdated(true);

      if (isListingMenuOpen) {
        dispatch(uiActions.toggleListingMenu());
      }

      history.push({
        pathname: location.pathname,
        search: updateQueryParams(location.search, actualSearchParams),
      });
    },
    [productActions.setSearchParams, location.pathname, location.search, setIsHistoryUpdated, isListingMenuOpen]
  );

  const handleResetSearchParams = React.useCallback(() => {
    dispatch(productActions.setSearchParams(productConfig.initialSearchParams));

    setIsHistoryUpdated(true);

    if (isListingMenuOpen) {
      dispatch(uiActions.toggleListingMenu());
    }

    history.push({
      pathname: location.pathname,
      search: updateQueryParams(location.search, productConfig.initialSearchParams),
    });
  }, [productActions.setSearchParams, location.pathname, location.search, setIsHistoryUpdated, isListingMenuOpen]);

  const handleToggleFilters = React.useCallback(() => {
    dispatch(uiActions.toggleListingMenu());
  }, [uiActions.toggleListingMenu]);

  const renderFilters = () => (
    <Filters.component
      filters={[]}
      initialSearchParams={productConfig.initialSearchParams}
      handleChangeSearchParams={handleChangeSearchParams}
      handleResetSearchParams={handleResetSearchParams}
    />
  );

  const renderTools = () => (
    <Tools.component searchParams={searchParams} handleChangeSearchParams={handleChangeSearchParams} />
  );

  return (
    <Loader
      isLoaded={isLoaded}
      isLoading={isLoading}
      error={''}
      render={() => (
        <Page
          title={t('listing:pageTitle')}
          seo={listingSeo(t, { title: category.title })}
          breadcrumbs={[
            {
              title: t('listing:pageTitle'),
              link: '/catalog',
            },
            ...(category.system_name
              ? [
                  {
                    title: category.title,
                    link: `/catalog/${category.system_name}`,
                  },
                ]
              : []),
          ]}
        >
          <Listing>
            <React.Fragment>
              <ListingSidebar>{renderFilters()}</ListingSidebar>
              <ListingBody>
                <ListingFiltersHandler onClick={handleToggleFilters}>
                  <Icon type='filter' />
                  {'Фильтры'}
                </ListingFiltersHandler>
                <ListingTools>{renderTools()}</ListingTools>
                <ListingGrid>
                  {models.length > 0 ? (
                    <Grid.component
                      items={models.map((model) => productModel(model))}
                      pagination={pagination}
                      handleChangeSearchParams={handleChangeSearchParams}
                    />
                  ) : (
                    <ListingNotFound>
                      <ListingNotFoundLabel>
                        {'К сожалению, товары по заданным критериям не найдены.'}
                      </ListingNotFoundLabel>
                      <Button onClick={handleResetSearchParams}>{t('listing:resetFilters')}</Button>
                    </ListingNotFound>
                  )}
                </ListingGrid>
              </ListingBody>

              <Modal title='Фильтры' isOpen={isListingMenuOpen} onClose={handleToggleFilters}>
                {renderTools()}
                {renderFilters()}
              </Modal>
            </React.Fragment>
          </Listing>
        </Page>
      )}
    />
  );
};

const preload = ({
  location: { search },
  match: {
    params: { categorySystemName },
  },
  dispatch,
  state,
}: ListingPreloadProps) => {
  const catalog = uiSelectors.selectCatalog(state);
  const category = catalogHelper.getCategory({ catalog, systemName: categorySystemName });

  dispatch(productActions.setSearchParams({ f_category_id: category.id, ...getQueryParams(search) }));

  return [productSagas.loadList];
};

export default {
  component: ListingComponent,
  preload,
};
