import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  BasketTopActionsWrapper,
  BasketActions,
  BasketSummaryWrapper,
  BasketSummary,
  BasketPromo,
  BasketTotal,
  BasketTotalLabel,
  BasketTotalValues,
  BasketBottomActionsWrapper,
  BasketProductsWrapper,
  BasketProducts,
  BasketNotFound,
  BasketNotFoundLabel,
} from './Basket.styled';
import { basketSeo } from './Basket.seo';
import BasketItem from './BasketItem/BasketItem';
import BasketQuickBuy from './BasketQuickBuy/BasketQuickBuy';
import { Page, Container, Button, GroupValue, Loader } from '@common/components';
import { ButtonTypes } from '@common/components/Button/Button.types';
import stringHelper from '@common/lib/helpers/strings';
import { useSelector, useDispatch } from 'react-redux';
import { basketSelectors } from '@common/store/basket/selectors';
import { basketActions } from '@common/store/basket/actions';
import { basketSagas } from '@common/store/basket/sagas';
import { productModel } from '@common/store/product/model';

const BasketComponent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isOpenQuickBuy, setIsOpenQuickBuy] = React.useState(false);

  const handleCloseQuickBuy = React.useCallback(() => {
    setIsOpenQuickBuy(false);
  }, [setIsOpenQuickBuy]);

  const handleOpenQuickBuy = React.useCallback(() => {
    setIsOpenQuickBuy(true);
  }, [setIsOpenQuickBuy]);

  const basketProducts = useSelector(basketSelectors.selectBasketProducts);
  const isBasketLoaded = useSelector(basketSelectors.selectIsBasketLoaded);
  const isBasketLoading = useSelector(basketSelectors.selectIsBasketLoading);
  const basketCookie = useSelector(basketSelectors.selectBasketCookie);
  const basketTotalQuantity = useSelector(basketSelectors.selectBasketTotalQuantity);
  const basketTotalPrice = useSelector(basketSelectors.selectBasketTotalPrice);
  const basketShowTotalPrice = useSelector(basketSelectors.selectBasketShowTotalPrice);

  const formProducts = React.useMemo(() => {
    return basketProducts
      .map((_) => productModel(_))
      .map((basketProduct) => {
        return {
          id: basketProduct.getId(),
          quantity: basketCookie[basketProduct.getId()].quantity,
        };
      });
  }, [basketProducts, basketCookie]);

  React.useEffect(() => {
    if (!isBasketLoaded) {
      dispatch(basketActions.loadBasketRequest());
    }
  }, []);

  const resetBasket = React.useCallback(() => {
    dispatch(basketActions.clearBasket());

    history.push('/');
  }, []);

  return (
    <Page
      title={t('basket:pageTitle')}
      withContainer={false}
      seo={basketSeo(t)}
      breadcrumbs={[
        {
          link: '/basket',
          title: t('basket:pageTitle'),
        },
      ]}
    >
      {basketProducts.length > 0 ? (
        <React.Fragment>
          <BasketTopActionsWrapper>
            <Container>
              <BasketActions>
                <Button actionIcon={{ type: 'arrowLong', variant: 'right' }} onClick={handleOpenQuickBuy}>
                  {'Оформить в 1 клик'}
                </Button>
                <Button
                  actionIcon={{ type: 'arrowLong', variant: 'right' }}
                  buttonType={ButtonTypes.PRIMARY}
                  to='/checkout'
                >
                  {'Все верно, перейти к оформлению'}
                </Button>
              </BasketActions>
            </Container>
          </BasketTopActionsWrapper>
          <BasketProductsWrapper>
            <Container>
              <Loader
                isLoaded={isBasketLoaded}
                isLoading={isBasketLoading}
                error={''}
                render={() => (
                  <BasketProducts>
                    {basketProducts
                      .map((_) => productModel(_))
                      .map((basketProduct, basketProductIndex) => (
                        <BasketItem
                          key={basketProduct.getId()}
                          item={basketProduct}
                          basketItem={basketCookie[basketProduct.getId()]}
                          index={basketProductIndex + 1}
                        />
                      ))}
                  </BasketProducts>
                )}
              />
            </Container>
          </BasketProductsWrapper>
          <BasketSummaryWrapper>
            <Container>
              <BasketSummary>
                <BasketPromo />
                <BasketTotal>
                  <BasketTotalLabel>{'Итого:'}</BasketTotalLabel>
                  <BasketTotalValues>
                    <GroupValue label={'Количество'} value={basketTotalQuantity} isLarge={true} />
                    <GroupValue
                      label={'Сумма'}
                      value={basketShowTotalPrice ? stringHelper.toPrice(basketTotalPrice) : 'Сумма по запросу'}
                      isLarge={true}
                    />
                  </BasketTotalValues>
                </BasketTotal>
              </BasketSummary>
            </Container>
          </BasketSummaryWrapper>
          <BasketBottomActionsWrapper>
            <Container>
              <BasketActions>
                <Button to='/catalog' icon={{ type: 'arrowLong', variant: 'left' }} buttonType={ButtonTypes.BORDERLESS}>
                  {'Продолжить покупки'}
                </Button>
                <Button actionIcon={{ type: 'arrowLong', variant: 'right' }} onClick={handleOpenQuickBuy}>
                  {'Оформить в 1 клик'}
                </Button>
                <Button
                  actionIcon={{ type: 'arrowLong', variant: 'right' }}
                  buttonType={ButtonTypes.PRIMARY}
                  to='/checkout'
                >
                  {'Все верно, перейти к оформлению'}
                </Button>
              </BasketActions>
            </Container>
          </BasketBottomActionsWrapper>
          <BasketQuickBuy
            isOpen={isOpenQuickBuy}
            onClose={handleCloseQuickBuy}
            formProducts={formProducts}
            resetBasket={resetBasket}
          />
        </React.Fragment>
      ) : (
        <BasketNotFound>
          <BasketNotFoundLabel>{'Ваша корзина пуста.'}</BasketNotFoundLabel>
          <Button to='/catalog'>{'Продолжить покупки'}</Button>
        </BasketNotFound>
      )}
    </Page>
  );
};

const preload = () => {
  return [basketSagas.loadBasket];
};

export default {
  component: BasketComponent,
  preload,
};
