import styled, { media } from '@common/theme/styled-components';

export const BasketProductsWrapper = styled.div``;

export const BasketProducts = styled.div`
  & > * {
    margin-top: ${(props) => props.theme.smallPadding};
  }
`;

export const BasketTopActionsWrapper = styled.div`
  padding: ${(props) => props.theme.mediumPadding} 0;

  ${media.tablet`
     padding: ${(props) => props.theme.largePadding} 0;
  `}
`;

export const BasketActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}

  & > * {
    margin-top: ${(props) => props.theme.smallPadding};

    ${media.tablet`
      margin-top: 0;
      margin-left: ${(props) => props.theme.mediumPadding};
    `}

    &:first-child {
      margin-top: 0;

      ${media.tablet`
        margin-left: 0;
      `}
    }
  }
`;

export const BasketBottomActionsWrapper = styled.div`
  padding: ${(props) => props.theme.mediumPadding} 0;

  background: ${(props) => props.theme.whiteColor};

  ${media.tablet`
     padding: ${(props) => props.theme.largePadding} 0;
  `}
`;

export const BasketSummaryWrapper = styled.div``;

export const BasketSummary = styled.div`
  padding: ${(props) => props.theme.largePadding} 0;
  border-bottom: 1px solid ${(props) => props.theme.brandBgGrayColor};

  ${media.desktop`
    display: flex;
  `}
`;

export const BasketPromo = styled.div`
  flex: 1 1;
`;

export const BasketTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    flex: 1 1;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    margin-right: ${(props) => props.theme.largePadding};
  `}

  ${media.desktop`
    justify-content: initial;
  `}
`;

export const BasketTotalValues = styled.div`
  display: flex;

  & > * {
    margin-left: ${(props) => props.theme.largePadding};

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const BasketTotalLabel = styled.div`
  font-size: 20px;
  font-family: ${(props) => props.theme.headerFontFamily};

  ${media.tablet`
    margin-right: ${(props) => props.theme.largePadding};
  `}
`;

export const BasketNotFound = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BasketNotFoundLabel = styled.div`
  margin-bottom: ${(props) => props.theme.smallPadding};

  font-size: ${(props) => props.theme.mediumFontSize};
  text-align: center;
`;
