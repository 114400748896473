import { Method } from 'axios';

const config = {
  methods: {
    get: 'GET' as Method,
    post: 'POST' as Method,
    put: 'PUT' as Method,
    delete: 'DELETE' as Method,
  },

  url: process.env.API_URL,
  endPoints: {
    auth: {
      login: () => `/auth`,
    },
    callback: {
      create: () => '/clients/callback',
    },
    order: {
      make: () => `/orders/make`,
      makeQuick: () => `/orders/make-quick`,
    },
    database: {
      getAll: () => `/databases`,
    },
    product: {
      search: (params: string) => `/catalog?${params}`,
      get: (id: string) => `products/${id}`,
      select: (ids: string[], type: string) => `products/?type=${type}${ids.length ? `&ids=${ids}` : ''}`,
    },
  },

  defaultImageSrc: 'static/images/no-photo.png',
};

export default config;
