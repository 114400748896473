import { Styles } from 'react-select';

import { theme } from '@common/theme/theme';

export const formSelectStyles: Partial<Styles> = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    border: `1px solid ${theme.brandBgGrayColor}`,
    borderRadius: theme.borderRadiuses.small,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '1px 12px',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '7px 12px',
  }),
};
