import { Link } from 'react-router-dom';
import styled, { media } from '@common/theme/styled-components';

export const CategoriesWrapper = styled.div``;

export const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${(props) => props.theme.mainSlider.heightMobile};

  ${media.desktop`
    height: ${(props) => props.theme.mainSlider.height};

    padding-left: ${(props) => props.theme.desktopMenu.width};
  `}
`;

export const CategoryImage = styled.div`
  display: none;

  ${media.desktop`
    display: block;

    height: 350px;
  `}
`;

export const CategoryImageSource = styled.img`
  ${media.desktop`
    height: 100%;
    width: auto;
  `}
`;

export const CategoryContent = styled.div`
  padding: 0 50px;
`;

export const CategoryContentTitle = styled.div`
  line-height: 1em;

  color: ${(props) => props.theme.brandAltColor};
  font-size: 28px;
`;

export const CategoryContentDescription = styled.div`
  padding: 15px 0;

  font-size: 12px;
`;

export const CategoryContentCta = styled(Link)`
  display: inline-block;

  padding: 5px 15px;
  border-radius: 50px;

  background: ${(props) => props.theme.brandAltColor};

  font-size: 12px;
  color: ${(props) => props.theme.whiteColor};
  text-decoration: none;

  transition: ${(props) => props.theme.defaultTransition};

  &:hover {
    background: ${(props) => props.theme.brandColor};
  }
`;
