import styled from '@common/theme/styled-components';

export const BottomLineWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;

  background: ${(props) => props.theme.brandAltColor};
  font-size: 12px;
`;

export const BottomLineLink = styled.a`
  margin: 0 2px;

  color: ${(props) => props.theme.whiteColor};
`;

export const Copyright = styled.div`
  color: ${(props) => props.theme.whiteColor};
`;
