import styled, { media, icon } from '@common/theme/styled-components';
import { Link } from 'react-router-dom';

export const Grid = styled.section``;

export const GridItemList = styled.div`
  ${media.mobile`
    display: flex;

    flex-wrap: wrap;

    margin: -${(props) => props.theme.smallPadding} -${(props) => props.theme.xsmallPadding} 0;
  `}

  & > * {
    padding-top: ${(props) => props.theme.smallPadding};

    ${media.mobile`
      flex: 0 0 50%;

      max-width: 50%;

      padding-right: ${(props) => props.theme.xsmallPadding};
      padding-left: ${(props) => props.theme.xsmallPadding};
    `}

    ${media.tablet`
      flex-basis: ${100 / 3}%;
      max-width: ${100 / 3}%;
    `}
  }
`;

export const GridItem = styled.div``;

export const Product = styled.div`
  padding: ${(props) => props.theme.smallPadding};
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  background-color: ${(props) => props.theme.whiteColor};

  &:hover {
    ${media.desktop`
      border-color: ${(props) => props.theme.brandAltColor};

      transition: ${(props) => props.theme.defaultTransition};
    `}
  }
`;

export const ProductImage = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 250px;
`;

export const ProductImgSrc = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const ProductTitle = styled(Link)`
  display: block;

  margin-top: ${(props) => props.theme.smallPadding};

  font-weight: bold;
  text-decoration: none;
  transition: ${(props) => props.theme.defaultTransition};

  ${media.mobile`
    min-height: 65px;
  `}

  &:hover {
    color: ${(props) => props.theme.brandAltColor};
  }
`;

export const ProductActionsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: ${(props) => props.theme.smallPadding};
`;

export const ProductPrice = styled.div`
  color: ${(props) => props.theme.brandAltColor};
  font-size: ${(props) => props.theme.largeFontSize};
  font-weight: bold;
`;

export const ProductActions = styled.div``;

export const ProductBasket = styled.button`
  padding: ${(props) => props.theme.xsmallPadding};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  background-color: ${(props) => props.theme.brandColor};

  ${(props) => icon({ color: props.theme.whiteColor, size: props.theme.largeFontSize })}
`;

export const GridPagination = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
`;
