import * as React from 'react';

import { BottomLineWrapper, BottomLineLink, Copyright } from './BottomLine.styled';

export const BottomLine: React.FC = () => {
  let host = '';
  if (typeof document !== 'undefined') {
    host = document.location.host;
  }

  return (
    <BottomLineWrapper>
      <Copyright>Сайт</Copyright>
      <BottomLineLink href={'/'}>{host}</BottomLineLink>
      <Copyright>
        не является интернет-магазином, а указанные цены не являются счетом для оплаты. Представленная информация носит
        информационный характер. Цены и фото на сайте и в магазине могут отличаться.
      </Copyright>
    </BottomLineWrapper>
  );
};
