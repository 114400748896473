import * as React from 'react';

import { FeaturedWrapper, FeaturedProducts } from './Featured.styled';
import { FeaturesProps } from './Featured.types';

import { ProductSection, ProductItem, Loader } from '@common/components';
import { PRODUCT_ITEM_TYPES } from '@common/components/ProductItem/ProductItem.types';

export const Featured: React.FC<FeaturesProps> = ({ isLoading, isLoaded, products }) => (
  <FeaturedWrapper>
    <Loader
      isLoading={isLoading}
      isLoaded={isLoaded}
      render={() => (
        <ProductSection title={'Рекомендуемые товары'}>
          <FeaturedProducts>
            {products.map((product) => (
              <ProductItem key={product.getId()} item={product} type={PRODUCT_ITEM_TYPES.DEFAULT} />
            ))}
          </FeaturedProducts>
        </ProductSection>
      )}
    />
  </FeaturedWrapper>
);
