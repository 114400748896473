import styledNormalize from 'styled-normalize';

import styled, { createGlobalStyle, media } from '@common/theme/styled-components';
import fonts from '@common/theme/fonts';
import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyle = createGlobalStyle`
  ${fonts};
  ${styledNormalize};

  body,
  pre {
    line-height: 1.6;

    color: ${(props) => props.theme.textColor};

    font-size: ${(props) => props.theme.textFontSize};
    font-family: ${(props) => props.theme.textFontFamily};
  }

  input,
  select,
  textarea {
    border-radius: 0;
    appearance: none !important;
    outline: none;

    &:focus {
      border-color: ${(props) => props.theme.brandAltColor};
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  svg {
    fill: ${(props) => props.theme.textColor};

    display: inherit;

    width: 16px;
    height: 16px;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::placeholder {
    color: ${(props) => props.theme.textDisabledColor};
  }

  a {
    color: ${(props) => props.theme.textColor};
  }

  button {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;

    font-family: ${(props) => props.theme.headerFontFamily};
    line-height: 1.4;
  }

  h1 {
    font-size: 28px;

    ${media.desktop`
      font-size: 48px;
    `}
  }

  h2 {
    font-size: 24px;

    ${media.desktop`
      font-size: 36px;
    `}
  }

  h3 {
    font-size: 18px;

    ${media.desktop`
      font-size: 20px;
    `}
  }

  #root {
    overflow-x: hidden;
  }

  .custom-notification-container {
    &.Toastify__toast-container {
      padding: ${(props) => props.theme.smallPadding};
    }

    .Toastify__toast--default {
      border-radius: ${(props) => props.theme.borderRadiuses.small};

      background: ${(props) => props.theme.brandAltColor};
      color: ${(props) => props.theme.whiteColor};
    }

    .Toastify__close-button--default {
      color: ${(props) => props.theme.whiteColor};
      opacity: 0.5;
    }
  }

  .custom-calendar-container {
    width: 100%;

    .react-datepicker {
      border: 1px solid ${(props) => props.theme.brandBgGrayColor};

      &__header {
        border-bottom: 1px solid ${(props) => props.theme.brandBgGrayColor};

        background: none;
      }

      &__current-month,
      &__day-name,
      &__day {
        color: ${(props) => props.theme.textColor};
      }

      &__day {
        &:hover {
          background: ${(props) => props.theme.brandBgGrayColor};
        }

        &--disabled {
          opacity: 0.5;
        }

        &--selected {
          color: ${(props) => props.theme.whiteColor};
          background: ${(props) => props.theme.brandAltColor};

          &:hover {
            background: ${(props) => props.theme.brandAltColor};
          }
        }
      }

      &__triangle {
        border-bottom-color: ${(props) => props.theme.whiteColor} !important;

        &:before {
          border-bottom-color: ${(props) => props.theme.brandBgGrayColor} !important;
        }
      }

      &__navigation {
        top: 14px;

        &--next {
          border-left-color: ${(props) => props.theme.textColor};
          opacity: 0.5;
        }

        &--previous {
          border-right-color: ${(props) => props.theme.textColor};
          opacity: 0.5;
        }
      }
    }
  }

  .highlight {
    color: ${(props) => props.theme.brandColor};
  }
`;

export const ContentWrapper = styled.section<{ isUserMenuOpen: boolean; isSidebarOpen: boolean }>`
  position: relative;

  padding: ${(props) => props.theme.toolbarHeight} 0 0;

  transition: 0.5s ease all;

  ${(props) =>
    props.isUserMenuOpen &&
    `
    transform: translateX(-80%);

    ${media.desktop`
      transform: translateX(-${(props) => props.theme.sidebarMaxWidth});
    `}
  `};

  ${(props) =>
    props.isSidebarOpen &&
    `
    transform: translateX(80%);

    ${media.desktop`
      transform: translateX(${(props) => props.theme.sidebarMaxWidth});
    `}
  `};

  &::after {
    content: '';
    display: ${(props) => !(props.isUserMenuOpen || props.isSidebarOpen) && 'none'};

    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${(props) => props.theme.zIndexes.contentShadow};

    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
`;

export const HeaderWrapper = styled.section``;

export const DesktopMenuWrapper = styled.section``;

export const BreadcrumbsWrapper = styled.section``;

export const MainWrapper = styled.section``;

export const SidebarWrapper = styled.section<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? '0' : '-80%')};
  bottom: 0;
  z-index: ${(props) => props.theme.zIndexes.sideBar};

  width: 80%;

  background-color: ${(props) => props.theme.brandBgColor};
  transition: 0.5s ease all;

  ${media.desktop`
    left: -${(props) => props.theme.sidebarMaxWidth};

    width: ${(props) => props.theme.sidebarMaxWidth};
  `}

  ${(props) =>
    props.isOpen &&
    `
    left: 0;
  `}
`;

export const UserMenuWrapper = styled.section<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: -80%;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndexes.sideBar};

  width: 80%;

  background-color: ${(props) => props.theme.brandBgColor};
  transition: 0.5s ease all;

  ${media.desktop`
    right: -${(props) => props.theme.sidebarMaxWidth};

    width: ${(props) => props.theme.sidebarMaxWidth};
  `}

  ${(props) =>
    props.isOpen &&
    `
    right: 0;
  `}
`;

export const BodyWrapper = styled.section``;

export const FooterWrapper = styled.section`
  margin-top: 15px;

  ${media.desktop`
    margin-top: 50px;
  `}
`;
