import basket from './basket';
import callback from './callback';
import checkout from './checkout';
import contacts from './contacts';
import footer from './footer';
import general from './general';
import listing from './listing';
import product from './product';

const translations = {
  basket,
  callback,
  checkout,
  contacts,
  footer,
  general,
  listing,
  product,
};

export default translations;
