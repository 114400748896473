import * as React from 'react';

import { MostPopularWrapper, MostPopularProducts } from './MostPopular.styled';
import { MostPopularProps } from './MostPopular.types';

import { ProductSection, ProductItem, Loader } from '@common/components';
import { PRODUCT_ITEM_TYPES } from '@common/components/ProductItem/ProductItem.types';

export const MostPopular: React.FC<MostPopularProps> = ({ isLoading, isLoaded, products }) => (
  <MostPopularWrapper>
    <Loader
      isLoading={isLoading}
      isLoaded={isLoaded}
      render={() => (
        <ProductSection title={'ТОП товары'}>
          <MostPopularProducts>
            {products.map((product) => (
              <ProductItem key={product.getId()} item={product} type={PRODUCT_ITEM_TYPES.SMALL} />
            ))}
          </MostPopularProducts>
        </ProductSection>
      )}
    />
  </MostPopularWrapper>
);
