import styled, { media } from '@common/theme/styled-components';

export const AdvantagesWrapper = styled.div``;

export const Advantage = styled.div`
  display: flex;
  align-items: center;

  padding: 12px;

  background: ${(props) => props.theme.whiteColorHover};

  transition: ${(props) => props.theme.defaultTransition};

  ${media.desktop`
    &:hover {
      background: ${(props) => props.theme.brandAltColor};
      color: ${(props) => props.theme.whiteColor};
    }
  `}
`;

export const AdvantageImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 50px;
  min-width: 50px;

  margin: 0 15px;
  border-radius: 100%;

  background: ${(props) => props.theme.whiteColor};

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const AdvantageContent = styled.div`
  padding-right: 10px;
`;

export const AdvantageTitle = styled.div`
  line-height: 1.2em;

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const AdvantageDescription = styled.div`
  line-height: 1.2em;
  margin-top: 5px;

  font-size: 12px;
`;
