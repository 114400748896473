import * as React from 'react';

import { Galleria, GalleriaItem, GalleriaItemThumb, GalleriaItemSource } from './Galleria.styled';
import { ComponentProps } from './Galleria.types';
import { Slider } from '@common/components';
import { toAssetLink } from '@common/lib/helpers/strings';
import { SwiperInstance } from 'react-id-swiper';

const sliderConfig = {
  isSingle: true,
  isWithNavigation: true,
  loop: false,
};

const thumbConfig = {
  isThumb: true,
  loop: false,
  height: 200,
};

const GalleriaComponent: React.FC<ComponentProps> = ({ product }) => {
  const [slider, setSlider] = React.useState<SwiperInstance>(null);
  const [thumb, setThumb] = React.useState<SwiperInstance>(null);

  return (
    <Galleria>
      <Slider config={sliderConfig} initSwiper={setSlider} thumbSwiper={thumb}>
        {product.getImages().map((image) => (
          <GalleriaItem key={image.id}>
            <GalleriaItemSource src={toAssetLink(image.path)} title={product.getTitle()} alt={product.getTitle()} />
          </GalleriaItem>
        ))}
      </Slider>
      <Slider config={thumbConfig} initSwiper={setThumb} thumbSwiper={slider}>
        {product.getImages().map((image) => (
          <GalleriaItemThumb key={image.id}>
            <GalleriaItemSource src={toAssetLink(image.path)} title={product.getTitle()} alt={product.getTitle()} />
          </GalleriaItemThumb>
        ))}
      </Slider>
    </Galleria>
  );
};

export default {
  component: GalleriaComponent,
};
