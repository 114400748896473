import { fork, all } from 'redux-saga/effects';

import { basketRootSaga } from './basket/sagas';
import { productRootSaga } from './product/sagas';

export function waitAll(sagas: any[]) {
  return function*() {
    yield all(sagas.map((saga) => fork(saga)));
  };
}

export const sagas = function*() {
  yield all({
    basket: fork(basketRootSaga),
    product: fork(productRootSaga),
  });
};
