import i18next from 'i18next';

import seoHelper from '@common/lib/helpers/seo';

export const rootSeo = (t: i18next.TFunction) => {
  return {
    defaultTitle: t('general:seoTitle'),
    titleTemplate: t('general:seoTitleTemplate'),
    ...seoHelper.getSeo(t, {
      title: t('general:seoTitle'),
      description: t('general:seoDescription'),
      url: t('general:seoUrl'),
      imgUrl: t('general:seoImgUrl'),
    }),
  };
};
