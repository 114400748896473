import { PaginationPage, PaginationParams, ApiPaginationParams } from './types';

export const initialSearchParamsState = {
  search: '',
  page: '1',
  pageSize: '15',
};

export const initialPaginationParamsState: PaginationParams = {
  page: 1,
  lastPage: 0,
  from: 0,
  to: 0,
  total: 0,
  pageSize: 15,
  path: '',
};

export const initialPaginationAggregations = {};

export const initialPaginationPageState: PaginationPage = {
  lastUpdateTime: new Date(),
  pages: {},
  params: initialPaginationParamsState,
  aggregations: initialPaginationAggregations,
  error: {},
};

export interface Page {
  number: number;
  isActive: boolean;
}

export const generatePagination = (pagination: PaginationParams): Page[] => {
  const actualPagination: Page[] = [];

  const pageOffset = 2;

  const firstPage = pagination.page - pageOffset > 1 ? pagination.page - pageOffset : 1;
  const lastPage =
    pagination.page + pageOffset < pagination.lastPage ? pagination.page + pageOffset : pagination.lastPage;

  for (let pageNumber = firstPage; pageNumber <= lastPage; pageNumber++) {
    actualPagination.push({
      number: pageNumber,
      isActive: pageNumber === pagination.page,
    });
  }

  return actualPagination;
};

export const parsePaginationParams = (apiPaginationParams: ApiPaginationParams): PaginationParams => {
  const { current_page: page, per_page: pageSize, last_page: lastPage, ...params } = apiPaginationParams;

  return {
    ...params,
    page,
    pageSize,
    lastPage,
  };
};
