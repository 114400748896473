import * as React from 'react';

import { Icon } from '@common/components';

import { HeaderLinkWrapper, HeaderLinkQuantity, HeaderLinkHandler, HeaderLinkLabel } from './HeaderLink.styled';
import { HeaderLinkProps } from './HeaderLink.types';

export const HeaderLink: React.FC<HeaderLinkProps> = ({ link, label, icon, quantity }) => (
  <HeaderLinkWrapper>
    <HeaderLinkHandler to={link}>
      <HeaderLinkLabel>{label}</HeaderLinkLabel>
      <Icon type={icon} />
      <HeaderLinkQuantity>{quantity}</HeaderLinkQuantity>
    </HeaderLinkHandler>
  </HeaderLinkWrapper>
);
