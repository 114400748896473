import { fontFace } from './styled-components';

const fonts = `
  ${fontFace('Lena', 'Lena/Lena')};
  ${fontFace('Roboto', 'Roboto/Roboto-Bold', '700')};
  ${fontFace('Roboto', 'Roboto/Roboto-Regular', '400')};
  ${fontFace('Roboto', 'Roboto/Roboto-Light', '100')};
`;

export default fonts;
