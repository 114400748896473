import { request } from '../lib/request/request';

import config from '../config';

type SelectArgs = {
  type: string;
  ids?: string[];
};

export const select = ({ type, ids = [] }: SelectArgs) => {
  return request({
    method: config.api.methods.get,
    url: config.api.endPoints.product.select(ids, type),
  });
};

export const get = (productName: string) => {
  return request({
    method: config.api.methods.get,
    url: config.api.endPoints.product.get(productName),
  });
};

export const search = (params: string) => {
  return request({
    method: config.api.methods.get,
    url: config.api.endPoints.product.search(params),
  });
};

const product = {
  select,
  get,
  search,
};

export default product;
