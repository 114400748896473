import * as React from 'react';
import { createUniversalPortal } from 'react-portal-universal';

import { ModalWrapper, Modal, ModalTitle, ModalBody, ModalClose } from './Modal.styled';
import { ComponentProps } from './Modal.types';
import { Icon } from '..';

const ModalComponent: React.FC<ComponentProps> = ({ title, isOpen, onClose, children }) => {
  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    isOpen &&
    createUniversalPortal(
      <ModalWrapper id="modal-wrapper'">
        <Modal>
          <ModalTitle>{title}</ModalTitle>
          <ModalBody>{children}</ModalBody>
          <ModalClose onClick={handleClose}>
            <Icon type='close' />
          </ModalClose>
        </Modal>
      </ModalWrapper>,
      'body'
    )
  );
};

export default ModalComponent;
