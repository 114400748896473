import styled, { icon, media } from '@common/theme/styled-components';

export const Filters = styled.section`
  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    margin-top: 0;
    border: 1px solid ${(props) => props.theme.brandBgGrayColor};
    border-radius: ${(props) => props.theme.borderRadiuses.small};
  `}
`;

export const FiltersResetHandler = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: ${(props) => props.theme.smallPadding} ${(props) => props.theme.smallPadding} 0;
  border: 0;

  background: none;
  color: ${(props) => props.theme.brandAltColor};
  font-weight: bold;
  text-transform: uppercase;

  ${(props) => icon({ color: props.theme.brandAltColor, size: props.theme.xsmallFontSize })}

  ${media.desktop`
    padding: 24px ${(props) => props.theme.smallPadding};

    font-size: ${(props) => props.theme.smallFontSize};

    ${(props) => icon({ color: props.theme.brandAltColor, size: props.theme.smallFontSize })}
  `}

  svg {
    margin-right: ${(props) => props.theme.xsmallPadding};
  }
`;

export const Filter = styled.div`
  border-top: 1px solid ${(props) => props.theme.brandBgColor};

  &:first-child {
    border-top: 0;
  }
`;

export const FilterLabel = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: ${(props) => props.theme.smallPadding};
  border: 0;

  background: none;
  color: ${(props) => props.theme.brandAltColor};
  font-size: ${(props) => props.theme.mediumFontSize};
  font-family: ${(props) => props.theme.headerFontFamily};

  ${(props) => icon({ size: props.theme.xsmallFontSize })}

  ${media.desktop`
    padding: 24px ${(props) => props.theme.smallPadding};

    font-size: ${(props) => props.theme.xlargeFontSize};
  `}
`;

export const FilterOpenHandler = styled.div`
  ${(props) => icon({ color: props.theme.brandAltColor })}
`;

export const FilterOptionList = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  padding: ${(props) => props.theme.smallPadding};
`;
