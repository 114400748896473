import styled, { media } from '@common/theme/styled-components';

export const Checkout = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${(props) => props.theme.smallPadding};

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const CheckoutBox = styled.div`
  min-width: 0;

  padding: ${(props) => props.theme.mediumPadding};
  border-radius: ${(props) => props.theme.borderRadiuses.medium};

  background: ${(props) => props.theme.brandBgColor};
`;

export const CheckoutBoxTitle = styled.h3`
  margin-top: ${(props) => props.theme.mediumPadding};

  &:first-child {
    margin-top: 0;
  }
`;

export const CheckoutBoxBody = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
`;

export const CheckoutHelp = styled.a`
  display: block;

  margin-top: 10px;
`;

export const CheckoutProducts = styled.div`
  overflow-x: auto;
`;

export const CheckoutProductsTable = styled.table`
  width: 100%;
`;

export const CheckoutProductsTHead = styled.thead``;

export const CheckoutProductsTBody = styled.tbody``;

export const CheckoutProductsHeaderCell = styled.th`
  padding: ${(props) => props.theme.xsmallPadding};

  text-align: left;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
  }
`;

export const CheckoutProductsRow = styled.tr`
  border-top: 1px solid ${(props) => props.theme.brandBgGrayColor};

  &:first-child {
    border-top: 0;
  }
`;

export const CheckoutProductsCell = styled.td`
  padding: ${(props) => props.theme.xsmallPadding};

  &:first-child {
    padding-left: 0;
  }
`;

export const CheckoutTotalBox = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
`;

export const CheckoutActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    flex-direction: row;
  `}

  & > * {
    margin-top: ${(props) => props.theme.smallPadding};

    ${media.desktop`
      margin-top: 0;
      margin-left: ${(props) => props.theme.mediumPadding};
    `}

    &:first-child {
      margin-top: 0;

      ${media.desktop`
        margin-left: 0;
      `}
    }
  }
`;
