import { action } from 'typesafe-actions';

import { ProductActionTypes, ProductDataInterface, ProductSearchParams } from './types';
import { ErrorInterface } from '../types';
import { productSchema } from './schema';
import { PaginationAggregations, PaginationParams } from '@common/lib/pagination/types';
import { generateNormalizer } from '@common/lib/pagination/store';
import { ResponseError } from '@common/lib/request/request.types';

const { normalizeItem, normalizeList } = generateNormalizer<ProductDataInterface>(productSchema);

const loadListRequest = () => {
  return action(ProductActionTypes.LOAD_LIST_REQUEST);
};

const loadListSuccess = (data: ProductDataInterface[]) => {
  return action(ProductActionTypes.LOAD_LIST_SUCCESS, normalizeList(data));
};

const loadListFailure = (err: ErrorInterface) => {
  return action(ProductActionTypes.LOAD_LIST_FAILURE, err);
};

const loadItemRequest = (id: string) => {
  return action(ProductActionTypes.LOAD_ITEM_REQUEST, { id, ...normalizeList([]) });
};

const loadItemSuccess = (id: string, model: ProductDataInterface) => {
  return action(ProductActionTypes.LOAD_ITEM_SUCCESS, { id, ...normalizeItem(model) });
};

const loadItemFailure = (id: string, error: ResponseError) => {
  return action(ProductActionTypes.LOAD_ITEM_FAILURE, { id, ...normalizeList([]), error });
};

const loadSelectionRequest = (type: string) => {
  return action(ProductActionTypes.LOAD_SELECTION_REQUEST, { type });
};

const loadSelectionSuccess = (type: string, models: ProductDataInterface[]) => {
  return action(ProductActionTypes.LOAD_SELECTION_SUCCESS, { type, ...normalizeList(models) });
};

const loadSelectionFailure = (err: ErrorInterface) => {
  return action(ProductActionTypes.LOAD_SELECTION_FAILURE, err);
};

const setActive = (id: string) => {
  return action(ProductActionTypes.SET_ACTIVE, { id });
};

const setPaginationParams = (params: PaginationParams) => {
  return action(ProductActionTypes.SET_PAGINATION, params);
};

const setAggregations = (aggregations: PaginationAggregations) => {
  return action(ProductActionTypes.SET_AGGREGATIONS, aggregations);
};

const setSearchParams = (searchParams: ProductSearchParams) => {
  return action(ProductActionTypes.SET_SEARCH_PARAMS, searchParams);
};

export const productActions = {
  loadListSuccess,
  loadListRequest,
  loadListFailure,
  loadItemRequest,
  loadItemSuccess,
  loadItemFailure,
  loadSelectionRequest,
  loadSelectionSuccess,
  loadSelectionFailure,
  setActive,
  setPaginationParams,
  setAggregations,
  setSearchParams,
};

export default productActions;
