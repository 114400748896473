import { Product } from '@common/store/product/model';

export const PRODUCT_ITEM_TYPES = {
  DEFAULT: 'default',
  SMALL: 'small',
};

export type ComponentProps = {
  item: Product;
  type?: string;
};
