import { Reducer } from 'react';
import { ActionType } from 'typesafe-actions';

import { ProductActionTypes, ProductDataInterface } from '../product/types';
import { basketActions } from '../basket/actions';
import { BasketActionTypes } from '../basket/types';
import { productActions } from '../product/actions';
import { MODELS } from '../types';
import { ResponseError } from '@common/lib/request/request.types';

const actions = {
  ...productActions,
  ...basketActions,
};

export type ModelAction = ActionType<typeof actions>;
export type ModelState = {
  [MODELS.PRODUCTS]: {
    [key: string]: ProductDataInterface & {
      error: null | ResponseError;
      isLoaded: boolean;
      isLoading: boolean;
    };
  };
  [key: string]: {
    [key: string]: any;
  };
};

export const modelInitialState: ModelState = {
  [MODELS.PRODUCTS]: {},
};

export const modelReducer: Reducer<ModelState, ModelAction> = (state = modelInitialState, action) => {
  const data = { entity: '', state };

  switch (action.type) {
    case ProductActionTypes.LOAD_LIST_SUCCESS:
    case ProductActionTypes.LOAD_ITEM_REQUEST:
    case ProductActionTypes.LOAD_ITEM_SUCCESS:
    case ProductActionTypes.LOAD_ITEM_FAILURE:
    case ProductActionTypes.LOAD_SELECTION_SUCCESS:
    case BasketActionTypes.LOAD_BASKET_SUCCESS: {
      data.entity = MODELS.PRODUCTS;
      break;
    }
  }

  switch (action.type) {
    case ProductActionTypes.LOAD_LIST_SUCCESS:
    case ProductActionTypes.LOAD_ITEM_REQUEST:
    case ProductActionTypes.LOAD_ITEM_SUCCESS:
    case ProductActionTypes.LOAD_ITEM_FAILURE:
    case ProductActionTypes.LOAD_SELECTION_SUCCESS:
    case BasketActionTypes.LOAD_BASKET_SUCCESS: {
      if (data.entity) {
        data.state = (() => {
          const entities = action.payload.entities;

          return Object.keys(entities).reduce((updatedState, entity) => {
            return {
              ...updatedState,
              [entity]: {
                ...updatedState[entity],
                ...entities[entity],
              },
            };
          }, state);
        })();
        break;
      }
    }
  }

  switch (action.type) {
    case ProductActionTypes.LOAD_ITEM_REQUEST: {
      data.state = (() => {
        return {
          ...data.state,
          [data.entity]: {
            ...data.state[data.entity],
            [action.payload.id]: {
              isLoaded: false,
              isLoading: true,
            },
          },
        };
      })();
      break;
    }
    case ProductActionTypes.LOAD_ITEM_SUCCESS: {
      data.state = (() => {
        const modelId = Object.keys(action.payload.entities[data.entity])[0];

        return {
          ...data.state,
          [data.entity]: {
            ...data.state[data.entity],
            [modelId]: {
              ...data.state[data.entity][modelId],
              isLoaded: true,
              isLoading: false,
            },
          },
        };
      })();
      break;
    }
    case ProductActionTypes.LOAD_ITEM_FAILURE: {
      data.state = (() => {
        return {
          ...data.state,
          [data.entity]: {
            ...data.state[data.entity],
            [action.payload.id]: {
              isLoaded: true,
              isLoading: false,
              error: action.payload.error,
            },
          },
        };
      })();
      break;
    }
  }

  return data.state;
};
