import styled from '@common/theme/styled-components';

export const GroupValue = styled.div`
  display: inline-block;
`;

export const GroupValueLabel = styled.div`
  color: ${(props) => props.theme.brandGrayColor};
`;

export const GroupValueValue = styled('div')<{ isLarge: boolean }>`
  font-weight: bold;
  font-size: ${(props) => (props.isLarge ? props.theme.largeFontSize : props.theme.smallFontSize)};
`;

export const GroupValueRender = styled.div`
  margin-top: 5px;
`;
