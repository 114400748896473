import styled, { media } from '@common/theme/styled-components';

export const SearchBoxWrapper = styled.form`
  display: flex;
`;

export const SearchBoxInput = styled.input`
  width: 100%;

  padding: 0 15px;
  border: 1px solid ${(props) => props.theme.brandBgColor};
  border-radius: 50px 0 0 50px;
  line-height: 35px;

  appearance: none !important;

  ${media.desktop`
    line-height: 45px;
  `}
`;

export const SearchBoxButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  line-height: 35px;

  border: 0;
  border-radius: 0 50px 50px 0;

  background: ${(props) => props.theme.brandAltColor};

  ${media.desktop`
    line-height: 45px;
  `}

  & svg {
    fill: ${(props) => props.theme.whiteColor};
  }
`;
