import config from '@common/config';
import { toPhone } from '@common/lib/helpers/strings';

export const advantagesConfig = {
  list: [
    {
      title: 'Бесплатная доставка',
      description: 'Бесплатная доставка по городу',
      iconType: 'deliveryTruck',
    },
    {
      title: 'Оплата',
      description: 'Оплата наличными или картой',
      iconType: 'creditCard',
    },
    {
      title: 'Поддержка',
      description: 'Техническая поддержка 24/7',
      iconType: 'telemarket',
    },
    {
      title: 'Консультация',
      description: `Позвоните нам по телефону ${toPhone(config.settings.phone)}`,
      iconType: 'phone',
      iconVariant: 'smart',
    },
  ],
};
