import styled, { media } from '@common/theme/styled-components';

export const Tools = styled.div`
  ${media.tablet`
    display: flex;
  `}
`;

export const ToolsItem = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};

  ${media.tablet`
    flex: 0 0 250px;

    margin-top: 0;
    margin-left: ${(props) => props.theme.xsmallPadding};
  `}

  &:first-child {
    margin-top: 0;

    ${media.tablet`
      margin-left: 0;
    `}
  }
`;
