import styled from '@common/theme/styled-components';

export const QuantityInput = styled.div`
  height: 100%;

  padding: 3px 6px;

  display: flex;
  align-items: center;
`;

export const QuantityInputField = styled.input`
  height: 100%;

  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  margin: 1px 5px 0 5px;

  background: none;
  text-align: center;
  font-size: 85%;
`;

export const QuantityInputButton = styled.button`
  border: 0;

  background: none;
`;
