import styled, { media } from '@common/theme/styled-components';

export const MostPopularWrapper = styled.div``;

export const MostPopularProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${(props) => props.theme.xsmallPadding};

  & > * {
    display: none;

    &:nth-last-child(n + 6) {
      display: flex;
    }

    ${media.tablet`
      display: flex;
    `}
  }

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr;
    gap: ${(props) => props.theme.smallPadding};
  `}
`;
