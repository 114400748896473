import * as React from 'react';

import { QuantityInput, QuantityInputField, QuantityInputButton } from './QuantityInput.styled';
import { ComponentProps } from './QuantityInput.types';

const QuantityInputComponent: React.FC<ComponentProps> = ({ value, onChange }) => {
  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.preventDefault();

      const parsedValued = parseInt(event.currentTarget.value);

      if (isNaN(parsedValued)) {
        onChange(1);
      } else {
        onChange(parsedValued);
      }
    },
    [onChange]
  );

  const handleIncrement = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      onChange(value + 1);
    },
    [value, onChange]
  );

  const handleDecrement = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      onChange(value > 1 ? value - 1 : value);
    },
    [value, onChange]
  );

  return (
    <QuantityInput>
      <QuantityInputButton onClick={handleDecrement}>-</QuantityInputButton>
      <QuantityInputField size={4} value={value} onChange={handleChange} />
      <QuantityInputButton onClick={handleIncrement}>+</QuantityInputButton>
    </QuantityInput>
  );
};

export default {
  component: QuantityInputComponent,
};
