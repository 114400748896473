import api from './api';
import languages from './languages';
import routing from './routing';
import settings from './settings';

export default {
  api,
  languages,
  routing,
  settings,
};
