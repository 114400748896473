import styled from '../../theme/styled-components';

export const Sidebar = styled.div``;

export const SidebarTitle = styled.div`
  text-align: center;

  padding: 10px;

  background: ${(props) => props.theme.brandAltColor};

  color: ${(props) => props.theme.whiteColor};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const SidebarBody = styled.div`
  padding: 10px;
`;
