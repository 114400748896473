import styled, { media, icon } from '@common/theme/styled-components';

export const Pagination = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const PaginationLabel = styled.span`
  font-size: 13px;
`;

export const PaginationControlsList = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${(props) => props.theme.xsmallPadding};

  ${media.tablet`
    margin-top: 0;
  `}
`;

export const PaginationControl = styled.button<{
  isActive?: boolean;
}>`
  display: flex;
  align-items: center;

  height: 28px;

  margin-left: ${(props) => props.theme.xsmallPadding};
  padding: 6px 12px;
  border: 1px solid ${(props) => props.theme.brandAltColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  color: ${(props) => props.theme.brandAltColor};
  background: none;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

  ${(props) => icon({ size: '6px', color: props.theme.brandAltColor })};

  ${media.desktop`
    &:hover {
      background: ${(props) => props.theme.brandAltColor};
      color: ${(props) => props.theme.whiteColor};

      ${(props) => icon({ color: props.theme.whiteColor })};
    }
  `}

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    svg {
      ${media.mobile`
        margin-right: ${(props) => props.theme.xsmallPadding};
      `}
    }
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    svg {
      ${media.mobile`
        margin-left: ${(props) => props.theme.xsmallPadding};
      `}
    }
  }

  ${(props) =>
    props.isActive &&
    `
    border: 1px solid ${props.theme.brandAltColor};

    background: ${props.theme.brandAltColor};
    color: ${props.theme.whiteColor};

    ${icon({ color: props.theme.whiteColor })};

    & + & {
      border-left: 1px solid ${props.theme.brandAltColor};
    }

    &:hover {
      background: ${props.theme.brandAltColor};
    }
  `}
`;

export const PaginationControlText = styled.span`
  display: none;

  ${media.mobile`
    display: inline-block;
  `}
`;
