import * as React from 'react';
import { FieldProps } from 'formik';

import { FormCheckboxGroup } from './FormCheckboxGroup.styled';
import { FormCheckboxGroupProps } from './FormCheckboxGroup.types';
import FormCheckbox from '../FormCheckbox/FormCheckbox';

const FormCheckboxGroupComponent: React.FC<FormCheckboxGroupProps & FieldProps> = ({ field, form, buttons }) => {
  const valueSet = new Set(field.value ? field.value.split(',') : []);

  const handleChange = React.useCallback(
    (value: string) => {
      if (valueSet.has(value)) {
        valueSet.delete(value);
      } else {
        valueSet.add(value);
      }

      form.setFieldValue(field.name, Array.from(valueSet).join(','));
    },
    [field.value, field.name, form.setFieldValue]
  );

  return (
    <FormCheckboxGroup>
      {buttons.map((button) => (
        <FormCheckbox key={button.label} {...button} isChecked={valueSet.has(button.name)} onChange={handleChange} />
      ))}
    </FormCheckboxGroup>
  );
};

export default FormCheckboxGroupComponent;
