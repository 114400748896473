import { DatabaseModelDataInterface, ModelInterface } from './types';

export class Model<M extends ModelInterface = ModelInterface> {
  protected model: M;

  constructor(modelName = 'models', model?: M) {
    this.model = model || ({} as M);
    this.model.modelName = modelName;
  }

  getId() {
    return this.model.id;
  }

  getAttr(attr: string) {
    return this.model[attr];
  }

  getViewLink() {
    return `/${this.model.modelName}/${this.model.id}`;
  }

  getListingLink(query = '') {
    if (query) {
      return `/${this.model.modelName}?search=${query}`;
    }

    return `/${this.model.modelName}`;
  }

  toSelect() {
    return {
      value: this.model.id,
      label: this.model.id,
    };
  }
}

export class DatabaseModel extends Model<DatabaseModelDataInterface> {
  getName() {
    return this.model.name;
  }
}

export function databaseModel(modelName: string, data?: DatabaseModelDataInterface) {
  return new DatabaseModel(modelName, data);
}
