import * as React from 'react';

import { FormCheckbox, FormCheckboxInput, FormCheckboxLabel, FormCheckboxCount } from './FormCheckbox.styled';
import { ComponentProps } from './FormCheckbox.types';

const FormCheckboxComponent: React.FC<ComponentProps> = ({ label, name, count, isChecked, onChange }) => {
  const handleChange = React.useCallback(() => {
    onChange(name);
  }, [name, onChange]);

  return (
    <FormCheckbox>
      <FormCheckboxLabel isChecked={isChecked}>
        <FormCheckboxInput type={'checkbox'} checked={isChecked} onChange={handleChange} />
        {label}
        {!!count && <FormCheckboxCount>({count})</FormCheckboxCount>}
      </FormCheckboxLabel>
    </FormCheckbox>
  );
};

export default FormCheckboxComponent;
