import * as React from 'react';
import { useHistory, withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { RouteComponentProps, useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { animateScroll as scroll } from 'react-scroll';
import { createUniversalPortal } from 'react-portal-universal';

import { Header } from './Header/Header';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { Footer } from './Footer/Footer';
// import { MobileMenu } from './MobileMenu/MobileMenu.container';
import { DesktopMenu } from './DesktopMenu/DesktopMenu';
import { UserMenu } from './UserMenu/UserMenu';
import { Sidebar } from './Sidebar/Sidebar';

import { routes } from '../../routes';

import {
  GlobalStyle,
  ContentWrapper,
  HeaderWrapper,
  DesktopMenuWrapper,
  BreadcrumbsWrapper,
  MainWrapper,
  SidebarWrapper,
  UserMenuWrapper,
  BodyWrapper,
  FooterWrapper,
} from './Root.styled';
import { uiSelectors } from '@common/store/ui/selectors';
import { uiActions } from '@common/store/ui/actions';
import { rootSeo } from './Root.seo';
import { basketSagas } from '@common/store/basket/sagas';
import { PreloadProps } from './Root.types';

const RootComponent: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isSidebarOpen = useSelector(uiSelectors.selectIsSidebarOpen);
  const isUserMenuOpen = useSelector(uiSelectors.selectIsUserMenuOpen);

  React.useEffect(() => {
    scroll.scrollToTop({
      duration: 250,
      smooth: true,
    });

    handleOpenContent();
    dispatch(uiActions.toggleMenu(location.pathname === '/'));
  }, [location]);

  const handleClickSidebar = React.useCallback(() => {
    dispatch(uiActions.toggleSidebar());
  }, [uiActions.toggleSidebar]);

  const handleClickUserMenu = React.useCallback(() => {
    history.push('/basket');
  }, []);

  const handleOpenContent = React.useCallback(() => {
    if (isUserMenuOpen) {
      dispatch(uiActions.toggleUserMenu());
    }

    if (isSidebarOpen) {
      dispatch(uiActions.toggleSidebar());
    }
  }, [isUserMenuOpen, isSidebarOpen, uiActions.toggleUserMenu, uiActions.toggleSidebar]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet {...rootSeo(t)} />
      <ContentWrapper isSidebarOpen={isSidebarOpen} isUserMenuOpen={isUserMenuOpen} onClick={handleOpenContent}>
        <BodyWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <DesktopMenuWrapper>
            <DesktopMenu />
          </DesktopMenuWrapper>
          <BreadcrumbsWrapper>
            <Breadcrumbs />
          </BreadcrumbsWrapper>
          <MainWrapper>{renderRoutes(routes)}</MainWrapper>
        </BodyWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </ContentWrapper>
      <SidebarWrapper isOpen={isSidebarOpen} id='sidebar-wrapper'>
        <Sidebar handleClose={handleClickSidebar} />
      </SidebarWrapper>
      <UserMenuWrapper isOpen={isUserMenuOpen} id='user-menu-wrapper'>
        <UserMenu handleClose={handleClickUserMenu} />
      </UserMenuWrapper>
      {createUniversalPortal(
        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          position={toast.POSITION.BOTTOM_RIGHT}
          className='custom-notification-container'
        />,
        'body'
      )}
    </React.Fragment>
  );
};

const preload = ({ dispatch }: PreloadProps) => {
  return [basketSagas.initBasket];
};

export default {
  component: withRouter(RootComponent),
  preload,
};
