export default {
  seoTitle: 'Интернет-магазин бытовой техники в Лепеле',
  seoTitleTemplate: '%s | Каримофф',
  seoDescription:
    'Купить бытовую технику по низким ценам в Лепеле с доставкой по Беларуси. Официальная гарантия на все товары. Акции, подарочные сертификаты, рассрочка',
  seoUrl: 'https://karimoff.by/',
  seoImgUrl: 'https://karimoff.by/img/logo.png',
  appTitle: 'Каримофф',
  appFullTitle: 'Каримофф',
  appTitleTemplate: ' | Каримофф',
  searchPhrase: 'Я ищу...',
  basket: 'Корзина',
  notFoundPageTitle: 'Страница не найдена',
};
