import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components';

import { ContactsWrapper, ContactsItem, ContactsLabel, ContactsLink, ContactsSocial } from './Contacts.styled';
import stringHelper from '@common/lib/helpers/strings';
import config from '@common/config';

export const Contacts: React.FC = () => {
  const { t } = useTranslation();

  const contacts = [
    {
      label: t('footer:officeAddress', { address: config.settings.officeAddress }),
    },
    {
      label: t('footer:shopAddress', { address: config.settings.shopAddress }),
    },
    {
      label: t('footer:phone'),
      link: stringHelper.toPhoneLink(config.settings.phone),
      linkLabel: stringHelper.toPhone(config.settings.phone),
    },
  ];

  const socials = [
    {
      iconType: 'instagram',
      link: stringHelper.toInstagramLink(config.settings.instagram),
    },
    {
      iconType: 'viber',
      label: t('contacts:viber'),
      link: stringHelper.toViberLink(config.settings.phone),
    },
    {
      iconType: 'mail',
      label: t('contacts:email'),
      link: stringHelper.toEmailLink(config.settings.email),
    },
  ];

  return (
    <ContactsWrapper>
      {contacts.map((contact) => (
        <ContactsItem key={contact.label}>
          <ContactsLabel>{contact.label}</ContactsLabel>
          {contact.link && (
            <ContactsLink href={contact.link} target='_blank' title={contact.label}>
              {contact.linkLabel}
            </ContactsLink>
          )}
        </ContactsItem>
      ))}
      <ContactsItem>
        {socials.map((social) => (
          <ContactsSocial key={social.link} href={social.link} target={'_blank'} title={social.label}>
            <Icon type={social.iconType} />
          </ContactsSocial>
        ))}
      </ContactsItem>
    </ContactsWrapper>
  );
};
