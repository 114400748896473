import * as React from 'react';
import { FieldProps } from 'formik';

import { FormTextArea } from './FormTextArea.styled';
import { ComponentProps } from './FormTextArea.types';
import { FormError } from '@common/components';

const FormTextAreaComponent: React.FC<ComponentProps & FieldProps> = ({ placeholder, field, form, ...props }) => {
  return (
    <React.Fragment>
      <FormTextArea placeholder={placeholder} rows={3} {...field} {...props} />
      <FormError error={form.errors[field.name] as string} />
    </React.Fragment>
  );
};

export default FormTextAreaComponent;
