import { Link } from 'react-router-dom';

import styled, { media, css } from '@common/theme/styled-components';

export const CategoryMenuWrapper = styled.div`
  ${media.desktop`
    position: relative;

    border-top: 1px solid ${(props) => props.theme.brandBgColor};
    border-bottom: 1px solid ${(props) => props.theme.brandBgColor};

    width: ${(props) => props.theme.desktopMenu.width};
  `}
`;

export const CategoryMenuHandler = styled.div`
  ${media.desktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    line-height: calc(${(props) => props.theme.desktopMenu.height} - 2px);

    background: ${(props) => props.theme.whiteColor};

    color: ${(props) => props.theme.brandAltColor};
    font-size: 20px;
    font-weight: bold;

    cursor: pointer;

    & svg {
      transition: ${(props) => props.theme.defaultTransition};
    }

    &.active {
      & svg {
        transform: rotate(180deg);
      }
    }
  `}
`;

export const CategoryMenuSet = styled('nav')<{
  level: number;
}>`
  position: absolute;
  left: -1000%;

  opacity: 0;

  border: 1px solid ${(props) => props.theme.brandBgColor};
  z-index: ${(props) => props.theme.zIndexes.desktopMenu};

  background: ${(props) => props.theme.whiteColor};

  &.active {
    opacity: 1;
  }

  .ac-menu-item-level-${(props) => props.level} {
    &:last-child {
      .ac-menu-link-level-${(props) => props.level} {
        border-bottom: 0;
      }
    }
  }

  ${(props) =>
    props.level === 1 &&
    `
    padding-bottom: 15px;
    border-radius: 0 0 15px 15px;
    transition: 0.25s ease opacity;

    &.active {
      top: 100%;
      left: 0;
      right: 0;
    }
  `}

  ${(props) =>
    props.level > 1 &&
    `
    position: absolute;
    top: 15px;
    width: ${props.theme.desktopMenu.width};

    transition: 0.25s ease;
    transition-property: top, opacity;
  `}
`;

export const CategoryMenuItem = styled.div`
  position: relative;

  &:hover {
    & > .ac-menu-link {
      & > .ac-menu-arrow {
        transform: rotate(-90deg);

        & svg {
          fill: ${(props) => props.theme.brandColor};
        }
      }
    }

    & > .ac-menu {
      opacity: 1;

      top: -1px;
      left: 100%;
    }
  }
`;

const StyledLink = css`
  ${media.desktop`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 10px;
    border-bottom: 1px solid ${(props) => props.theme.brandBgColor};
    line-height: 50px;

    color: ${(props) => props.theme.brandAltColor};
    font-size: 14px;
    text-decoration: none;

    transition: 0.25s ease color;

    & svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      color: ${(props) => props.theme.brandColor};
      background: ${(props) => props.theme.whiteColorHover};
    }
  `}
`;

export const CategoryMenuLink = styled(Link)`
  ${StyledLink}
`;

export const CategoryMenuEmptyLink = styled('div')<{
  to: string;
}>`
  ${StyledLink}

  cursor: pointer;
`;

export const CategoryMenuArrow = styled.span`
  ${media.desktop`
    transition: ${(props) => props.theme.defaultTransition};
  `}
`;
