import { ModelDataInterface, ModelInterface, ModelSearchParams } from '../types';
import { Category } from '../ui/types';

export enum ProductActionTypes {
  LOAD_FULL_LIST_REQUEST = '@@products/LOAD_FULL_LIST_REQUEST',
  LOAD_FULL_LIST_SUCCESS = '@@products/LOAD_FULL_LIST_SUCCESS',
  LOAD_FULL_LIST_FAILURE = '@@products/LOAD_FULL_LIST_FAILURE',

  LOAD_LIST_REQUEST = '@@products/LOAD_LIST_REQUEST',
  LOAD_LIST_SUCCESS = '@@products/LOAD_LIST_SUCCESS',
  LOAD_LIST_FAILURE = '@@products/LOAD_LIST_FAILURE',

  LOAD_ITEM_REQUEST = '@@products/LOAD_ITEM_REQUEST',
  LOAD_ITEM_SUCCESS = '@@products/LOAD_ITEM_SUCCESS',
  LOAD_ITEM_FAILURE = '@@products/LOAD_ITEM_FAILURE',

  LOAD_SELECTION_REQUEST = '@@products/LOAD_SELECTION_REQUEST',
  LOAD_SELECTION_SUCCESS = '@@products/LOAD_SELECTION_SUCCESS',
  LOAD_SELECTION_FAILURE = '@@products/LOAD_SELECTION_FAILURE',

  SET_ACTIVE = '@@products/SET_ACTIVE',

  SET_PAGINATION = '@@products/SET_PAGINATION',
  SET_AGGREGATIONS = '@@products/SET_AGGREGATIONS',
  SET_SEARCH_PARAMS = '@@products/SET_SEARCH_PARAMS',
}

export enum PRODUCT_SELECTIONS {
  SELECTION = 'selection',
  NEW = 'new',
  FEATURED = 'featured',
  MOST_POPULAR = 'most_popular',
  RELATED = 'related',
}

export enum ATTRIBUTES {
  TITLE = 'title',
  DESCRIPTION = 'description',
  PRICE = 'price',
  CATEGORY = 'category',
  BRAND = 'brand',
  SHOW_PRICE = 'show_price',
}

export type SimplePayload = string;

export interface SelectPayload {
  id: number;
  name: string;
  system_name?: string;
}

export interface Image {
  id: number;
  path: string;
  is_general: boolean;
}

export type ImagePayload = Image[];

export interface Attribute {
  id: number;
  name: string;
  system_name: string;
  payload: {
    id: number;
    value: SimplePayload | SelectPayload | ImagePayload;
  };
}

export interface ProductDataInterface extends ModelDataInterface {
  custom_attributes: Attribute[];
  created_at: string;
  updated_at: string;
  selections: {
    related: ProductDataInterface[];
    [key: string]: ProductDataInterface[];
  };
}

export interface ProductInterface extends ModelInterface {
  custom_attributes: Attribute[];
  created_at: string;
  updated_at: string;
  selections: {
    related: Selection;
    [type: string]: Selection;
  };
}

export type ProductSearchParams = ModelSearchParams;

export interface FiltersInterface {
  [filterName: string]: FilterInterface;
}

export interface FilterInterface {
  [filterOptionName: string]: any;
}

export interface SearchParams {
  category: Category;
  filters: FiltersInterface;
}

export interface Selection {
  items: number[];
  isLoading?: boolean;
  isLoaded?: boolean;
}

export interface ProductState {
  models: {
    [id: string]: ProductDataInterface;
  };
  selections: {
    new: Selection;
    featured: Selection;
    most_popular: Selection;
    [type: string]: Selection;
  };
}
