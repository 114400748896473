import styled, { media } from '@common/theme/styled-components';

export const NotFoundWrapper = styled.section`
  display: flex;
  justify-content: center;

  margin-top: 15px;

  ${media.desktop`
    margin-top: 50px;
  `}
`;

export const NotFoundTitle = styled.h1``;
