import * as React from 'react';

import { Icon } from '@common/components';

import { Menu, MenuSet, MenuItem, MenuItemLink, MenuItemLabel } from './MobileMenu.styled';
import { Category } from '@common/store/ui/types';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@common/store/ui/actions';
import { uiSelectors } from '@common/store/ui/selectors';

const Component = () => {
  const dispatch = useDispatch();

  const catalog = useSelector(uiSelectors.selectCatalog);

  const handleClickMenuItem = (event: any, link: string) => {
    if (!link) {
      event.stopPropagation();
      event.preventDefault();

      return;
    }

    dispatch(uiActions.toggleSidebar());
  };

  const handleOpenMenuSet = (event: any) => {
    const currentMenu = event.target.nextSibling;

    if (currentMenu.classList.contains('active')) {
      const menus: HTMLElement[] = currentMenu.querySelectorAll('.ac-menu');
      [].slice.call(menus).map((menu: HTMLElement) => menu.classList.remove('active'));
    }

    currentMenu.classList.toggle('active');
  };

  const renderMenuGrid = (gridRootNodes: Category[], level = 1): React.ReactNode[] => {
    let items: React.ReactNode[] = [];

    gridRootNodes.forEach((rootNode) => {
      let childrenBox: React.ReactNode[] = [];

      if (rootNode.children && rootNode.children.length) {
        childrenBox = [
          <MenuSet key={rootNode.title} className={`ac-menu ac-menu-level-${level + 1}`} level={level + 1}>
            <MenuItemLink
              className={`ac-menu-title ac-menu-title-level-${level + 1}`}
              to={rootNode.link}
              onClick={(event) => handleClickMenuItem(event, rootNode.link)}
            >
              {rootNode.title}
            </MenuItemLink>
            {renderMenuGrid(rootNode.children, level + 1)}
          </MenuSet>,
        ];
      }

      items = [
        ...items,
        <MenuItem key={rootNode.title} className={`ac-menu-item ac-menu-item-level-${level}`}>
          {childrenBox.length ? (
            <React.Fragment>
              <MenuItemLabel className={`ac-menu-link ac-menu-link-level-${level}`} onClick={handleOpenMenuSet}>
                {rootNode.title}
                <Icon type={'arrow'} variant={'down'} />
              </MenuItemLabel>
              {childrenBox}
            </React.Fragment>
          ) : (
            <MenuItemLink
              className={`ac-menu-link ac-menu-link-level-${level}`}
              to={rootNode.link}
              onClick={(event) => handleClickMenuItem(event, rootNode.link)}
            >
              {rootNode.title}
            </MenuItemLink>
          )}
        </MenuItem>,
      ];
    });

    return items;
  };

  return (
    <Menu>
      <MenuSet className={'ac-menu ac-menu-level-1'} level={1}>
        {renderMenuGrid(catalog)}
      </MenuSet>
    </Menu>
  );
};

export { Component as MobileMenu };
