import * as React from 'react';

import { Button } from '@common/components';

import {
  Profile,
  ProfileVendorCode,
  ProfileTitle,
  ProfileShortDescription,
  ProfileShortDescriptionFormat,
  ProfilePrice,
  ProfileButtons,
} from './Profile.styled';
import { ComponentProps } from './Profile.types';

const ProfileComponent: React.FC<ComponentProps> = ({ product, addToBasket }) => {
  return (
    <Profile>
      <ProfileVendorCode>{`Код товара: ${product.getId()}`}</ProfileVendorCode>
      <ProfileTitle>{product.getTitle()}</ProfileTitle>
      <ProfileShortDescription>
        <ProfileShortDescriptionFormat>{product.getDescription()}</ProfileShortDescriptionFormat>
      </ProfileShortDescription>
      <ProfilePrice>{product.getPrice()}</ProfilePrice>
      <ProfileButtons>
        <Button onClick={addToBasket} icon={{ type: 'basket' }}>
          {'В корзину'}
        </Button>
      </ProfileButtons>
    </Profile>
  );
};

export default {
  component: ProfileComponent,
};
