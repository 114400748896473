import { combineReducers, Reducer } from 'redux';

import { uiReducer, UiState, UiAction, uiInitialState } from './ui/reducer';
import { productReducer, ProductState, ProductAction, productInitialState } from './product/reducer';
import { basketReducer, BasketState, BasketAction, basketInitialState } from './basket/reducer';
import { modelReducer, ModelState, ModelAction, modelInitialState } from './model/reducer';

export type State = {
  basket: BasketState;
  ui: UiState;
  model: ModelState;
  product: ProductState;
};

export type Action = UiAction | ProductAction | BasketAction | ModelAction;

export const initialState: State = {
  basket: basketInitialState,
  ui: uiInitialState,
  model: modelInitialState,
  product: productInitialState,
};

export const reducer: Reducer<State> = combineReducers<State>({
  basket: basketReducer,
  ui: uiReducer,
  model: modelReducer,
  product: productReducer,
});
