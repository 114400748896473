import * as React from 'react';

import styled from '@common/theme/styled-components';
const InputMask = require('react-input-mask');

export const FormInput = styled((props) => <InputMask {...props} />)`
  width: 100%;

  padding: 10px 12px;
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  & + & {
    margin-top: ${(props) => props.theme.smallPadding};
  }
`;
