import styled, { media, icon } from '@common/theme/styled-components';
import SmartLink from '../SmartLink/SmartLink';

export const ProductItemWrapper = styled.div`
  position: relative;

  padding: ${(props) => props.theme.smallPadding};
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  background-color: ${(props) => props.theme.whiteColor};

  &:hover {
    ${media.desktop`
      border-color: ${(props) => props.theme.brandAltColor};

      transition: ${(props) => props.theme.defaultTransition};
    `}
  }
`;

export const ProductItemImage = styled(SmartLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 250px;
`;

export const ProductItemImgSrc = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const ProductItemTitle = styled(SmartLink)<{ to: string }>`
  display: block;

  margin-top: ${(props) => props.theme.smallPadding};

  font-weight: bold;
  text-decoration: none;
  transition: ${(props) => props.theme.defaultTransition};

  ${media.mobile`
    min-height: 65px;
  `}

  &:hover {
    color: ${(props: any) => props.to && props.theme.brandAltColor};
  }
`;

export const ProductItemActionsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: ${(props) => props.theme.smallPadding};
`;

export const ProductItemPrice = styled.div`
  color: ${(props) => props.theme.brandColor};
  font-size: ${(props) => props.theme.largeFontSize};
  font-weight: bold;
`;

export const ProductItemActions = styled.div``;

export const ProductItemBasket = styled.button`
  padding: ${(props) => props.theme.xsmallPadding};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  background-color: ${(props) => props.theme.brandAltColor};

  ${(props) => icon({ color: props.theme.whiteColor, size: props.theme.largeFontSize })}
`;

export const ProductItemImageSmall = styled(SmartLink)<{ to: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;

  padding: 5px;
  border: 1px solid ${(props) => props.theme.brandBgGrayColor};
  border-radius: ${(props) => props.theme.borderRadiuses.small};

  ${media.tablet`
    transition: ${(props) => props.theme.defaultTransition};

    &:hover {
      border-color: ${(props: any) => props.to && props.theme.brandAltColor};
    }
  `}
`;

export const ProductItemBodySmall = styled.div``;

export const ProductItemContentSmall = styled.div`
  width: 100%;

  padding: 0 15px;
`;

export const ProductItemTitleSmall = styled(ProductItemTitle)`
  margin-top: 0;
`;

export const ProductItemActionsSmall = styled(ProductItemActions)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductItemSmall = styled.div`
  position: relative;

  display: flex;
`;
