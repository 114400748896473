import { AxiosRequestConfig } from 'axios';

export enum RequestType {
  REQUEST_TYPE_DEFAULT = 'REQUEST_TYPE_DEFAULT',
  REQUEST_TYPE_ADMIN = 'REQUEST_TYPE_ADMIN',
}

export interface RequestOptions extends AxiosRequestConfig {
  multipart?: boolean;
}

export enum ResponseStatus {
  SUCCESS = 200,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export type ResponseError = {
  message: string;
  status: ResponseStatus;
};
