export interface ThemeInterface {
  textColor: string;
  textDisabledColor: string;
  textFontSize: string;
  textFontFamily: string;
  headerFontFamily: string;

  brandColor: string;
  brandHoverColor: string;
  brandAltColor: string;
  brandAltHoverColor: string;
  brandBgColor: string;
  brandGrayColor: string;
  brandRedColor: string;
  brandBgGrayColor: string;
  whiteColor: string;
  whiteColorHover: string;
  shadowBgColor: string;

  defaultTransition: string;
  defaultTextShadow: string;

  containerMaxWidth: string;
  sidebarMaxWidth: string;
  toolbarHeight: string;

  xsmallPadding: string;
  smallPadding: string;
  mediumPadding: string;
  largePadding: string;

  xsmallFontSize: string;
  smallFontSize: string;
  mediumFontSize: string;
  largeFontSize: string;
  xlargeFontSize: string;

  boxShadow: string;

  sideBar: {
    width: string;
    widthMobile: string;
    transition: string;
  };

  desktopMenu: {
    width: string;
    height: string;
  };

  mainSlider: {
    height: string;
    heightMobile: string;
  };

  borderRadiuses: {
    small: string;
    medium: string;
    large: string;
  };

  footer: {
    height: string;
    mobileHeight: string;
  };

  zIndexes: {
    desktopMenu: number;
    header: number;
    sideBar: number;
    toolBar: number;
    contentShadow: number;
    modal: number;
  };
}

export const theme: ThemeInterface = {
  textColor: '#444',
  textDisabledColor: '#999',
  textFontSize: '14px',
  textFontFamily: '"Roboto", sans-serif',
  headerFontFamily: '"Lena", sans-serif',

  brandColor: '#fc3',
  brandHoverColor: '#f7b500',
  brandAltColor: '#2e4056',
  brandAltHoverColor: '#364c66',
  brandBgColor: '#E7F1F4',
  brandBgGrayColor: '#d7d7d7',
  brandGrayColor: '#b6b6b6',
  brandRedColor: '#d42729',
  whiteColor: '#fff',
  whiteColorHover: '#f8f8f8',
  shadowBgColor: 'rgba(0, 0, 0, 0.25)',

  defaultTransition: '0.25s ease all',
  defaultTextShadow: '1px 1px 0 rgba(0, 0, 0, 0.3)',

  containerMaxWidth: '1320px',
  sidebarMaxWidth: '350px',
  toolbarHeight: '40px',

  xsmallPadding: '7.5px',
  smallPadding: '15px',
  mediumPadding: '30px',
  largePadding: '45px',

  xsmallFontSize: '12px',
  smallFontSize: '14px',
  mediumFontSize: '16px',
  largeFontSize: '18px',
  xlargeFontSize: '24px',

  boxShadow: '2px 2px 11px 0 rgba(0, 0, 0, .1)',

  sideBar: {
    width: '80%',
    widthMobile: '350px',
    transition: '0.5s ease',
  },

  desktopMenu: {
    width: '300px',
    height: '60px',
  },

  mainSlider: {
    height: '550px',
    heightMobile: '350px',
  },

  borderRadiuses: {
    small: '5px',
    medium: '15px',
    large: '30px',
  },

  footer: {
    height: '75px',
    mobileHeight: '100px',
  },

  zIndexes: {
    desktopMenu: 252,
    header: 254,
    sideBar: 256,
    toolBar: 256,
    contentShadow: 512,
    modal: 1024,
  },
};
