import { ResponseError } from '@common/lib/request/request.types';

export enum MODELS {
  PRODUCTS = 'products',
}

export interface ErrorInterface {
  [key: string]: string;
}

export interface ModelDataInterface {
  id?: string;
  isLoaded?: boolean;
  isLoading?: boolean;
  error?: ResponseError;
}

export interface ModelInterface extends ModelDataInterface {
  modelName?: string;

  [key: string]: any;
}

export interface ModelSearchParams {
  search?: string;
  page?: string;
  pageSize?: string;
  [key: string]: number | string;
}

export interface DatabaseModelDataInterface extends ModelDataInterface {
  name: string;
}

export type DatabaseSearchParams = ModelSearchParams;
