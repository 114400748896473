import * as React from 'react';

import { Container, Icon } from '../../../components';
import { BottomLine } from './BottomLine/BottomLine';
import { Contacts } from './Contacts/Contacts';
import { Callback } from './Callback/Callback';

import { FooterWrapper, FooterCallback, FooterBody, FooterInner, FooterInnerLeft, FooterLogo } from './Footer.styled';

export const Footer: React.FC = () => (
  <FooterWrapper>
    <FooterCallback>
      <Container>
        <Callback />
      </Container>
    </FooterCallback>
    <FooterBody>
      <Container>
        <FooterInner>
          <FooterInnerLeft>
            <FooterLogo>
              <Icon type={'logo'} />
            </FooterLogo>
            <Contacts />
          </FooterInnerLeft>
        </FooterInner>
      </Container>
    </FooterBody>
    <BottomLine />
  </FooterWrapper>
);
