import { FilterInterface, FiltersInterface } from '@common/store/product/types';
import { State } from '@common/store/reducer';
import * as qs from 'qs';

import config from '../../config';
import { initialPaginationParamsState } from '../pagination/structures';

export const generateSearchHash = (searchParams: any) => {
  return `${Buffer.from(unescape(encodeURIComponent(JSON.stringify(searchParams)))).toString('base64')}`;
};

export const toPrice = (price: string | number) => {
  return `${parseFloat(price.toString())
    .toFixed(2)
    .replace(/[.]+/g, ',')} р.`;
};

export const fromPrice = (price: string) => {
  return parseFloat(price.replace(/[ р.]+/g, ''));
};

export const toQuantity = (quantity: string) => {
  return `${parseInt(quantity)} шт.`;
};

export const toPhoneLink = (phone: string) => {
  return `tel:${phone}`;
};

export const toPhone = (phone: string) => {
  const numbers = phone.split('').reverse();

  return [
    numbers[0],
    numbers[1],
    '-',
    numbers[2],
    numbers[3],
    '-',
    numbers[4],
    numbers[5],
    numbers[6],
    ') ',
    numbers[7],
    numbers[8],
    ' (',
    numbers[9],
    numbers[10],
    numbers[11],
    '+',
  ]
    .reverse()
    .join('');
};

export const fromPhone = (phone: string) => {
  return phone.replace(/[^0-9]/g, '');
};

export const toInstagramLink = (instagram: string) => {
  return `https://www.instagram.com/${instagram}/`;
};

export const toInstagram = (instagram: string) => {
  return `@${instagram}`;
};

export const toTelegramLink = (telegram: string) => {
  return `tg://resolve?domain=${telegram}`;
};

export const toViberLink = (viber: string) => {
  return `viber://add?number=${viber}`;
};

export const toEmailLink = (email: string) => {
  return `mailto:${email}`;
};

export const toImagePath = (path: string) => {
  return `${config.api.url}/${path}`;
};

export const toBoolean = (boolean: boolean) => {
  return boolean ? '+' : '-';
};

export const toAssetLink = (link: string) => {
  if (link.includes('http')) {
    return link;
  }

  return `${config.api.url}/${link}`;
};

export const updateQueryParams = (query: string, params: any) => {
  return `?${qs.stringify({
    ...qs.parse(query.replace('?', '')),
    ...params,
  })}`;
};

export const getQueryParams = (query: string) => {
  return qs.parse(query.replace('?', ''));
};

export const getPathName = (query: string) => {
  return query.split('?')[0];
};

export const getLocationSearch = (query: string) => {
  return query.split('?')[1] || '';
};

export function encode(data = {}) {
  return Buffer.from(encodeURIComponent(JSON.stringify(data))).toString('base64');
}

export function decode(data = '') {
  return JSON.parse(decodeURIComponent(Buffer.from(data, 'base64').toString('ascii')));
}

export function getIdFromSlug(slug = '') {
  const slugParams = slug.split('-');

  if (!slugParams.length) {
    return '';
  }

  return slugParams[0];
}

type WrapWordArgs = {
  source: string;
  tag?: string;
  splitBy?: string | RegExp;
  wordPosition?: number;
};

export const wrapWord = ({ source, tag = 'span', splitBy = ' ', wordPosition = 0 }: WrapWordArgs): string => {
  const words = source.split(splitBy);
  words[wordPosition] = `<${tag} class="highlight">${words[wordPosition]}</${tag}>`;

  return words.join(splitBy instanceof RegExp ? '' : ' ');
};

export const wrapLogo = (source: string): string => {
  return wrapWord({ source, splitBy: /(офф$)/ });
};

const prepareFilter = (filters: FiltersInterface) => {
  const actualFilters: FiltersInterface = {};
  for (const filter in filters) {
    const actualFilter: FilterInterface = {};
    for (const filterProperty in filters[filter]) {
      if (filters[filter][filterProperty] !== undefined) {
        actualFilter[filterProperty] = filters[filter][filterProperty];
      }
    }
    actualFilters[filter] = actualFilter;
  }

  return actualFilters;
};

export const toFilter = (filterName: string, filterValue: any, filterOptionName = '') => {
  const filter: FiltersInterface = {
    [filterName]: filterValue,
  };

  return filter;
};

export const makeCategoryUrl = (parts: string[] = []) => {
  return parts.reduce((acc, cur) => `${acc}/${cur}`, '/catalog');
};

const stringHelper = {
  generateSearchHash,
  toPrice,
  fromPrice,
  toQuantity,
  toPhoneLink,
  toPhone,
  fromPhone,
  toInstagramLink,
  toInstagram,
  toTelegramLink,
  toViberLink,
  toEmailLink,
  toImagePath,
  toBoolean,
  toAssetLink,
  updateQueryParams,
  getPathName,
  getLocationSearch,
  getQueryParams,
  encode,
  decode,
  getIdFromSlug,
  toFilter,
  makeCategoryUrl,
};

export default stringHelper;
