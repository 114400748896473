import * as React from 'react';

import { Icon } from '@common/components';

import { LanguagesWrapper, LanguageHandler, LanguagesLabel } from './Languages.styled';

export const Languages: React.FC = () => (
  <LanguagesWrapper>
    <LanguageHandler>
      <LanguagesLabel>РУС</LanguagesLabel>
      <Icon type={'language'} />
    </LanguageHandler>
  </LanguagesWrapper>
);
