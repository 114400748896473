import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actionsCreators from './actions';
import { BasketActionTypes, Basket } from './types';

export type BasketState = Basket;
export type BasketAction = ActionType<typeof actionsCreators>;

export const basketInitialState: BasketState = {
  cookie: {},
  isLoaded: false,
  isLoading: false,
};

export const basketReducer: Reducer<BasketState, BasketAction> = (state = basketInitialState, action) => {
  switch (action.type) {
    case BasketActionTypes.SET_UP_BASKET:
      return (() => {
        return {
          ...state,
          cookie: action.payload.basket,
        };
      })();

    case BasketActionTypes.LOAD_BASKET_REQUEST:
      return (() => {
        return {
          ...state,
          isLoading: true,
        };
      })();

    case BasketActionTypes.LOAD_BASKET_SUCCESS:
      return (() => {
        return {
          ...state,
          isLoaded: true,
          isLoading: false,
        };
      })();

    case BasketActionTypes.LOAD_BASKET_FAILURE:
      return (() => {
        return {
          ...state,
          isLoading: false,
        };
      })();

    default: {
      return state;
    }
  }
};
