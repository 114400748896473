import styled, { icon, media } from '@common/theme/styled-components';
import { Link } from 'react-router-dom';

export const BasketItem = styled.div`
  position: relative;

  padding: ${(props) => props.theme.smallPadding};
  border-bottom: 1px solid ${(props) => props.theme.brandBgGrayColor};

  ${media.desktop`
    display: flex;
    align-items: flex-start;
  `}

  &:first-child {
    border-top: 1px solid ${(props) => props.theme.brandBgGrayColor};
  }
`;

export const BasketItemIndex = styled.div`
  margin: auto 0;
`;

export const BasketItemImage = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 75px;
  width: auto;

  ${media.desktop`
    height: 150px;
  `};
`;

export const BasketItemSrc = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const BasketItemTitle = styled.div``;

export const BasketItemPrice = styled.div``;

export const BasketItemQuantity = styled.div``;

export const BasketItemTotal = styled.div``;

export const BasketItemRemove = styled.div`
  position: absolute;
  right: ${(props) => props.theme.smallPadding};
  top: ${(props) => props.theme.smallPadding};

  padding: 5px;
  border-radius: 100%;

  background: ${(props) => props.theme.brandBgGrayColor};

  ${(props) => icon({ size: '10px', color: props.theme.whiteColor })}

  &:hover {
    background: ${(props) => props.theme.brandAltColor};
    transition: ${(props) => props.theme.defaultTransition};
    cursor: pointer;
  }
`;

export const BasketItemTop = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1;

  & > * {
    margin-left: ${(props) => props.theme.smallPadding};

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const BasketItemBottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 1;

  margin-top: ${(props) => props.theme.smallPadding};

  ${media.desktop`
    margin-top: 0;
  `}

  & > * {
    margin-left: ${(props) => props.theme.smallPadding};

    ${media.desktop`
      flex: 1 1;
    `}

    &:first-child {
      margin-left: 0;
    }
  }
`;
