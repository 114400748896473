import styled, { media } from '@common/theme/styled-components';

export const DesktopMenuWrapper = styled.div`
  display: none;

  ${media.desktop`
    display: block;

    background: ${(props) => props.theme.brandAltColor};
  `}
`;

export const DesktopMenuInner = styled.nav`
  ${media.desktop`
    display: flex;
    align-items: center;

    height: ${(props) => props.theme.desktopMenu.height};
  `}
`;
