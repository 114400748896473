import * as React from 'react';

import { Grid, GridItemList, GridItem, GridPagination } from './Grid.styled';
import { ComponentProps } from './Grid.types';
import { Pagination, ProductItem } from '@common/components';

const GridComponent: React.FC<ComponentProps> = ({ items, pagination, handleChangeSearchParams }) => {
  return (
    <Grid>
      <GridItemList>
        {items.map((item) => (
          <GridItem key={item.getId()}>
            <ProductItem item={item} />
          </GridItem>
        ))}
      </GridItemList>
      <GridPagination>
        <Pagination pagination={pagination} handleChangeSearchParams={handleChangeSearchParams} />
      </GridPagination>
    </Grid>
  );
};

export default {
  component: GridComponent,
};
