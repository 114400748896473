export const parseCheckboxValue = (value: string) => {
  if (!value) {
    return {};
  }

  return value.split(',').reduce((params, valueKey) => {
    return {
      ...params,
      [valueKey]: true,
    };
  }, {});
};

const formHelper = {
  parseCheckboxValue,
};

export default formHelper;
