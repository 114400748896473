import styled, { media } from '../../../theme/styled-components';

export const FooterWrapper = styled.footer``;

export const FooterCallback = styled.div`
  background: ${(props) => props.theme.brandAltColor};
`;

export const FooterBody = styled.div`
  padding: 15px 0;

  background-color: ${(props) => props.theme.whiteColorHover};
  background-image: url('${require('@assets/images/bg/home-full-width-bg.png')}');
  background-size: cover;

  ${media.desktop`
    padding: 50px 0;
  `}
`;

export const FooterInner = styled.div`
  ${media.desktop`
    display: flex;
  `}
`;

export const FooterInnerLeft = styled.div`
  ${media.desktop`
    width: ${(props) => props.theme.desktopMenu.width};
  `}
`;

export const FooterLogo = styled.div`
  padding: 0 15px 15px;
  border-bottom: 1px solid ${(props) => props.theme.brandAltColor};

  & svg {
    width: 50px;
    height: 50px;

    fill: ${(props) => props.theme.brandAltColor};
  }
`;
