import styled from '@common/theme/styled-components';

export const Container = styled.section`
  padding: 0 15px;
`;

export const ContainerInner = styled.div`
  max-width: ${(props) => props.theme.containerMaxWidth};
  margin: 0 auto;
`;
