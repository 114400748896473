import styled, { media, icon } from '@common/theme/styled-components';
import { Link } from 'react-router-dom';

export const Profile = styled.section``;

export const ProfileVendorCode = styled.div`
  opacity: 0.75;
`;

export const ProfileTitle = styled.h2`
  margin: ${(props) => props.theme.smallPadding} 0 0;
`;

export const ProfileShortDescription = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
  padding: ${(props) => props.theme.smallPadding} 0;
  border-top: 1px solid ${(props) => props.theme.brandBgColor};
  border-bottom: 1px solid ${(props) => props.theme.brandBgColor};

  ${media.desktop`
    padding: ${(props) => props.theme.mediumPadding} 0;
  `}
`;

export const ProfileShortDescriptionFormat = styled.pre``;

export const ProfilePrice = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};

  color: ${(props) => props.theme.brandColor};
  font-size: 20px;
  font-weight: bold;

  ${media.desktop`
    font-size: 24px;
  `}
`;

export const ProfileButtons = styled.div`
  margin-top: ${(props) => props.theme.smallPadding};
`;
