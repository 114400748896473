import * as React from 'react';
import { SwiperInstance } from 'react-id-swiper';

import {
  AdvantagesWrapper,
  Advantage,
  AdvantageImage,
  AdvantageContent,
  AdvantageTitle,
  AdvantageDescription,
} from './Advantages.styled';
import { advantagesConfig } from './Advantages.config';

import { sizes } from '@common/theme/styled-components';
import { Slider, Icon } from '@common/components';

export const Advantages: React.FC = () => {
  const [slider, setSlider] = React.useState<SwiperInstance>(null);

  return (
    <AdvantagesWrapper>
      <Slider
        initSwiper={setSlider}
        config={{
          isWithNavigation: true,
          breakpoints: {
            [sizes.mobile]: {
              slidesPerView: 1,
            },
            [sizes.tablet]: {
              slidesPerView: 2,
            },
            [sizes.desktop]: {
              slidesPerView: 3,
            },
            [sizes.large]: {
              slidesPerView: 4,
            },
          },
        }}
      >
        {advantagesConfig.list.map((advantage) => (
          <Advantage key={advantage.title}>
            <AdvantageImage>
              <Icon type={advantage.iconType} variant={advantage.iconVariant} />
            </AdvantageImage>
            <AdvantageContent>
              <AdvantageTitle>{advantage.title}</AdvantageTitle>
              <AdvantageDescription>{advantage.description}</AdvantageDescription>
            </AdvantageContent>
          </Advantage>
        ))}
      </Slider>
    </AdvantagesWrapper>
  );
};
