import { denormalize, normalize, schema } from 'normalizr';
import { ModelDataInterface } from '../../store/types';

export function generateNormalizer<MD = ModelDataInterface>(entitySchema: schema.Entity) {
  const normalizer = {
    normalizeItem: (model: MD) => {
      return normalize(model, entitySchema);
    },
    normalizeList: (models: MD[]) => {
      return normalize(models, [entitySchema]);
    },
    denormalizeItem: (modelId: string, models: { [key: string]: { [key: string]: MD } }): MD => {
      return denormalize(modelId, entitySchema, models);
    },
    denormalizeList: (page: number[] | string[], models: { [key: string]: { [key: string]: MD } }): MD[] => {
      if (!Object.keys(models).length) {
        return [];
      }

      return denormalize(page, [entitySchema], models).filter((_: MD) => _ !== undefined);
    },
  };

  return normalizer;
}
