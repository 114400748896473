import * as React from 'react';
import { SwiperInstance } from 'react-id-swiper';

import {
  CategoriesWrapper,
  Category,
  CategoryImage,
  CategoryImageSource,
  CategoryContent,
  CategoryContentTitle,
  CategoryContentDescription,
  CategoryContentCta,
} from './Categories.styled';
import { categoriesConfig } from './Categories.config';

import { Slider } from '@common/components';
import { wrapWord } from '@common/lib/helpers/strings';

export const Categories: React.FC = () => {
  const [slider, setSlider] = React.useState<SwiperInstance>(null);

  return (
    <CategoriesWrapper>
      <Slider initSwiper={setSlider} config={{ isSingle: true }}>
        {categoriesConfig.list.map((category) => (
          <Category key={category.title}>
            <CategoryContent>
              <CategoryContentTitle dangerouslySetInnerHTML={{ __html: wrapWord({ source: category.title }) }} />
              <CategoryContentDescription>{category.description}</CategoryContentDescription>
              {category.link && <CategoryContentCta to={category.link}>{'Подробнее'}</CategoryContentCta>}
            </CategoryContent>
            <CategoryImage>
              <CategoryImageSource src={category.image} />
            </CategoryImage>
          </Category>
        ))}
      </Slider>
    </CategoriesWrapper>
  );
};
