import * as React from 'react';
import { FieldProps } from 'formik';

import { FormNum, FormNumInc, FormNumDec, FormNumInput } from './FormNum.styled';

const FormNumComponent: React.FC<FieldProps> = ({ field, form }) => {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const newNumValue = parseInt(event.currentTarget.value);

      if (isNaN(newNumValue)) {
        setValue('');
      } else {
        form.setFieldValue(field.name, newNumValue);
      }
    },
    [field.name, form.setFieldValue]
  );

  const handleBlur = React.useCallback(() => {
    const newNumValue = parseInt(value);

    if (isNaN(newNumValue)) {
      setValue(1);
      form.setFieldValue(field.name, 1);
    }
  }, [value, field.name, form.setFieldValue]);

  const handleDec = React.useCallback(() => {
    const value = parseInt(field.value);
    const newValue = value > 1 ? value - 1 : 1;

    form.setFieldValue(field.name, newValue);
  }, [field.value, field.name, form.setFieldValue]);

  const handleInc = React.useCallback(() => {
    const newValue = parseInt(field.value) + 1;

    form.setFieldValue(field.name, newValue);
  }, [field.value, field.name, form.setFieldValue]);

  return (
    <FormNum>
      <FormNumDec onClick={handleDec}>-</FormNumDec>
      <FormNumInput {...field} value={value} onChange={handleChange} onBlur={handleBlur} />
      <FormNumInc onClick={handleInc}>+</FormNumInc>
    </FormNum>
  );
};

export default FormNumComponent;
