import * as React from 'react';
import { FieldProps } from 'formik';

import { FormRadioGroup } from './FormRadioGroup.styled';
import { FormRadioGroupProps } from './FormRadioGroup.types';
import FormRadio from '../FormRadio/FormRadio';

const FormRadioGroupComponent: React.FC<FormRadioGroupProps & FieldProps> = ({ field, form, buttons }) => {
  const formattedButtons = React.useMemo(() => {
    return buttons.map((button) => {
      return {
        ...button,
        value: button.name === field.value,
      };
    });
  }, [buttons, field.value]);

  const handleChange = React.useCallback(
    (name: string) => {
      form.setFieldValue(field.name, name);
    },
    [field.name, form.setFieldValue]
  );

  return (
    <FormRadioGroup>
      {formattedButtons.map((formattedButton) => (
        <FormRadio key={formattedButton.name} {...formattedButton} onChange={handleChange} />
      ))}
    </FormRadioGroup>
  );
};

export default FormRadioGroupComponent;
