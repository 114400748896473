import { RouteConfig } from 'react-router-config';

import Home from './pages/Home/Home';
import Listing from './pages/Listing/Listing';
import Product from './pages/Product/Product';
import Basket from './pages/Basket/Basket';
import Checkout from './pages/Checkout/Checkout';
import Contacts from './pages/Contacts/Contacts';
import NotFound from './pages/NotFound/NotFound';

export const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    ...Home,
  },
  {
    path: '/catalog',
    exact: true,
    ...Listing,
  },
  {
    path: '/catalog/:categorySystemName',
    exact: true,
    ...Listing,
  },
  {
    path: '/catalog/:categorySystemName/:productId',
    exact: true,
    ...Product,
  },
  {
    path: '/basket',
    exact: true,
    ...Basket,
  },
  {
    path: '/checkout',
    exact: true,
    ...Checkout,
  },
  {
    path: '/contacts',
    exact: true,
    ...Contacts,
  },
  {
    path: '*',
    ...NotFound,
  },
];
