export enum UiActionTypes {
  TOGGLE_SIDEBAR = '@@gui/TOGGLE_SIDEBAR',
  TOGGLE_USER_MENU = '@@gui/TOGGLE_USER_MENU',
  TOGGLE_LISTING_MENU = '@@gui/TOGGLE_LISTING_MENU',
  TOGGLE_MENU = '@@gui/TOGGLE_MENU',
}

export type Ui = {
  isSidebarOpen: boolean;
  isUserMenuOpen: boolean;
  isListingMenuOpen: boolean;
  isMenuOpen: boolean;
  catalog: Category[];
};

export type Category = {
  id: number;
  title: string;
  system_name: string;
  link: string;
  isRoot?: boolean;
  children?: Category[];
};
