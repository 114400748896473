import * as React from 'react';
import { Formik, Field } from 'formik';

import { BasketQuickBuyLabel, BasketQuickBuyLabelFields, BasketQuickBuyLabelActions } from './BasketQuickBuy.styled';
import { BasketQuickBuyProps, BasketQuickBuyFormProps } from './BasketQuickBuy.types';
import { Button, FormInput, FormInputPhone, Modal } from '@common/components';
import controllers from '@common/controllers';
import validator from '@common/lib/validator/validator';
import { ButtonTypes, ButtonSizes } from '@common/components/Button/Button.types';
import notificationHelper, { NOTIFICATION_TYPES } from '@common/lib/helpers/notification';

const BasketQuickBuyComponent: React.FC<BasketQuickBuyProps> = ({ isOpen, onClose, formProducts, resetBasket }) => {
  return (
    <Formik<BasketQuickBuyFormProps>
      initialValues={{
        clientFirstName: '',
        clientPhoneNumber: '',
      }}
      validationSchema={() => {
        return validator.object().shape({
          clientFirstName: validator.string(),
          clientPhoneNumber: validator.string().required(),
        });
      }}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, { resetForm }) => {
        const parsedValues = {
          ...values,
          products: formProducts,
        };

        try {
          await controllers.order.makeQuick(parsedValues);
        } catch (e) {
          notificationHelper.pushNotification('Что-то пошло не так, попробуйте еще раз.', NOTIFICATION_TYPES.ERROR);
          return;
        }

        notificationHelper.pushNotification('Спасибо за заказ! Мы скоро Вам перезвоним.');

        resetForm();

        resetBasket();
      }}
    >
      {({ handleSubmit }) => (
        <Modal title='Покупка в 1 клик' isOpen={isOpen} onClose={onClose}>
          <BasketQuickBuyLabel>
            {'Оставьте заявку и наш администратор свяжется с Вами в течение 10 минут'}
          </BasketQuickBuyLabel>
          <BasketQuickBuyLabelFields>
            <Field component={FormInput} name='clientFirstName' placeholder={'Имя'} />
            <Field component={FormInputPhone} name='clientPhoneNumber' placeholder={'Номер телефона*'} />
          </BasketQuickBuyLabelFields>

          <BasketQuickBuyLabelActions>
            <Button buttonType={ButtonTypes.PRIMARY} buttonSize={ButtonSizes.SMALL} onClick={() => handleSubmit()}>
              {'Отправить'}
            </Button>
          </BasketQuickBuyLabelActions>
        </Modal>
      )}
    </Formik>
  );
};

export default BasketQuickBuyComponent;
