import { DatabaseModel } from '@common/store/model';
import { ProductSearchParams } from '@common/store/product/types';
import { AggregatedFilterOption } from '@common/lib/helpers/filters';

export enum FILTER_TYPE {
  RANGE = 'RANGE',
  AGGR = 'AGGR',
  MULTY_AGGR = 'MULTY_AGGR',
}

export type RootFilterState = {
  [key: string]: {
    isOpen: boolean;
  };
};

export type ComponentFilter = {
  type: FILTER_TYPE;
  key: string;
  label: string;
  value: string;
  isOpen: boolean;
  options?: DatabaseModel[];
  range?: {
    max: number;
    min: number;
  };
  aggregations?: {
    key: string;
    doc_count: number;
  }[];
};

export type ComponentFilledFilter = {
  type: FILTER_TYPE;
  key: string;
  label: string;
  value: string;
  options?: AggregatedFilterOption[];
  range?: {
    max: number;
    min: number;
  };
  aggregations?: {
    key: string;
    doc_count: number;
  }[];
};

export type ComponentProps = {
  filters: ComponentFilter[];
  initialSearchParams: ProductSearchParams;
  handleChangeSearchParams: (filters: ProductSearchParams) => void;
  handleResetSearchParams: () => void;
};
