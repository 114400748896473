import styled from '@common/theme/styled-components';

export const ContactsWrapper = styled.div``;

export const ContactsItem = styled.div`
  display: flex;
  align-items: center;

  margin: 15px 0;

  & svg {
    margin-right: 15px;
  }
`;

export const ContactsLabel = styled.div``;

export const ContactsLink = styled.a`
  margin-left: 3px;
`;

export const ContactsSocial = styled.a`
  & svg {
    width: 25px;
    height: 25px;
  }
`;
