import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  InfoWrapper,
  InfoGroup,
  InfoTitle,
  InfoItem,
  InfoItemIcon,
  InfoItemValue,
  InfoItemLabel,
  InfoItemLink,
} from './Info.styled';
import { AddressGroup } from './Info.types';
import { Icon } from '@common/components';
import config from '@common/config';
import stringHelper from '@common/lib/helpers/strings';

export const Info: React.FC = () => {
  const { t } = useTranslation();

  const addressGroups: AddressGroup[] = [
    {
      label: t('contacts:shopAddress'),
      items: [
        {
          iconType: 'location',
          label: config.settings.shopAddress,
        },
        {
          iconType: 'time',
          label: config.settings.shopWorkTime,
        },
        {
          iconType: 'phone',
          iconVariant: 'thin',
          label: t('contacts:phone'),
          link: stringHelper.toPhoneLink(config.settings.shopPhone),
          linkLabel: stringHelper.toPhone(config.settings.shopPhone),
        },
        {
          iconType: 'viber',
          label: t('contacts:viber'),
          link: stringHelper.toViberLink(config.settings.shopPhone),
          linkLabel: stringHelper.toPhone(config.settings.shopPhone),
        },
      ],
    },
    {
      label: t('contacts:socials'),
      items: [
        {
          iconType: 'instagram',
          label: t('contacts:instagram'),
          link: stringHelper.toInstagramLink(config.settings.instagram),
          linkLabel: stringHelper.toInstagram(config.settings.instagram),
        },
        {
          iconType: 'viber',
          label: t('contacts:viber'),
          link: stringHelper.toViberLink(config.settings.phone),
          linkLabel: stringHelper.toPhone(config.settings.phone),
        },
        {
          iconType: 'mail',
          label: t('contacts:email'),
          link: stringHelper.toEmailLink(config.settings.email),
          linkLabel: config.settings.email,
        },
      ],
    },
    {
      label: t('contacts:officeAddress'),
      items: [
        {
          iconType: 'location',
          label: config.settings.officeAddress,
        },
        {
          iconType: 'time',
          label: config.settings.officeWorkTime,
        },
        {
          iconType: 'phone',
          iconVariant: 'thin',
          label: t('contacts:phone'),
          link: stringHelper.toPhoneLink(config.settings.officePhone),
          linkLabel: stringHelper.toPhone(config.settings.officePhone),
        },
        {
          iconType: 'viber',
          label: t('contacts:viber'),
          link: stringHelper.toViberLink(config.settings.officePhone),
          linkLabel: stringHelper.toPhone(config.settings.officePhone),
        },
      ],
    },
  ];

  return (
    <InfoWrapper>
      {addressGroups.map((addressGroup) => (
        <InfoGroup key={addressGroup.label}>
          <InfoTitle>{addressGroup.label}</InfoTitle>
          {addressGroup.items.map((contact, contactIndex) => (
            <InfoItem key={contactIndex}>
              <InfoItemIcon>
                <Icon type={contact.iconType} variant={contact.iconVariant} />
              </InfoItemIcon>
              <InfoItemValue>
                <InfoItemLabel>{contact.label}</InfoItemLabel>
                {contact.link && (
                  <InfoItemLink href={contact.link} target='_blank' title={contact.label}>
                    {contact.linkLabel}
                  </InfoItemLink>
                )}
              </InfoItemValue>
            </InfoItem>
          ))}
        </InfoGroup>
      ))}
    </InfoWrapper>
  );
};
