import { action } from 'typesafe-actions';

import { UiActionTypes } from './types';

const toggleSidebar = () => {
  return action(UiActionTypes.TOGGLE_SIDEBAR);
};

const toggleUserMenu = () => {
  return action(UiActionTypes.TOGGLE_USER_MENU);
};

const toggleListingMenu = () => {
  return action(UiActionTypes.TOGGLE_LISTING_MENU);
};

const toggleMenu = (isOpen: boolean = undefined) => {
  return action(UiActionTypes.TOGGLE_MENU, { isOpen });
};

export const uiActions = {
  toggleSidebar,
  toggleUserMenu,
  toggleListingMenu,
  toggleMenu,
};
