import * as React from 'react';

import { Container, ContainerInner } from './Container.styled';

const ContainerComponent: React.FC = ({ children }) => (
  <Container>
    <ContainerInner>{children}</ContainerInner>
  </Container>
);

export default ContainerComponent;
