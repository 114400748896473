import * as React from 'react';
import { SwiperInstance } from 'react-id-swiper';

import { wrapWord } from '@common/lib/helpers/strings';

import { Slider, Icon } from '../../components';

import {
  ProductSection,
  ProductSectionHeader,
  ProductSectionTitle,
  ProductSectionArrowsBox,
  ProductSectionArrow,
  ProductSectionShowMore,
  ProductSectionBody,
  ProductSectionFooter,
} from './ProductSection.styled';
import { ComponentProps } from './ProductSection.types';

const Component: React.FC<ComponentProps> = ({ title, showMoreLink, isSlider, sliderConfig, children }) => {
  const [slider, setSlider] = React.useState<SwiperInstance>(null);

  const slidePrev = React.useCallback(() => {
    if (slider) {
      slider.slidePrev();
    }
  }, [slider]);

  const slideNext = React.useCallback(() => {
    if (slider) {
      slider.slideNext();
    }
  }, [slider]);

  return (
    <ProductSection>
      <ProductSectionHeader>
        <ProductSectionTitle dangerouslySetInnerHTML={{ __html: wrapWord({ source: title }) }} />
        {isSlider && (
          <ProductSectionArrowsBox>
            <ProductSectionArrow onClick={slidePrev}>
              <Icon type={'arrow'} variant='left' />
            </ProductSectionArrow>
            <ProductSectionArrow onClick={slideNext}>
              <Icon type={'arrow'} variant='right' />
            </ProductSectionArrow>
          </ProductSectionArrowsBox>
        )}
        {showMoreLink && <ProductSectionShowMore to={showMoreLink}>{'Все'}</ProductSectionShowMore>}
      </ProductSectionHeader>
      <ProductSectionBody>
        {isSlider ? (
          <Slider initSwiper={setSlider} config={sliderConfig}>
            {children}
          </Slider>
        ) : (
          children
        )}
      </ProductSectionBody>
      {/* {showMoreLink && (
        <ProductSectionFooter>
          <Button buttonType={ButtonTypes.DEFAULT} buttonSize={ButtonSizes.SMALL} to={showMoreLink}>
            {`Все ${title}`}
          </Button>
        </ProductSectionFooter>
      )} */}
    </ProductSection>
  );
};

export default Component;
